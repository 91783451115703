import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IAction, IDocumentData } from 'models'

type InitialState = {
	documentId: string
	documentData: IDocumentData | null
	actionsList: IAction[]
	loading: boolean
	formLoading: boolean
	visibleProperties: string[]
	editableProperties: string[]
}

const initialState: InitialState = {
	documentId: '',
	documentData: null,
	actionsList: [],
	loading: true,
	formLoading: true,
	visibleProperties: [],
	editableProperties: [],
}

export const documentData = createSlice({
	name: 'documentData',
	initialState,
	reducers: {
		setDocumentId: (state, action: PayloadAction<string>) => {
			state.documentId = action.payload
		},
		setDocumentData: (state, action: PayloadAction<IDocumentData>) => {
			state.documentData = action.payload
		},
		setActionsList: (state, action: PayloadAction<IAction[]>) => {
			state.actionsList = action.payload
		},
		updateDocumentData: (state, action: PayloadAction<{ fieldName: string; value: any }[]>) => {
			action.payload.forEach(({ fieldName, value }) => {
				state.documentData = {
					...state.documentData,
					[fieldName]: value,
				} as IDocumentData
			})
		},
		updateLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		updateFormLoading: (state, action: PayloadAction<boolean>) => {
			state.formLoading = action.payload
		},
		setEditableProperties: (state, action: PayloadAction<string[]>) => {
			state.editableProperties = action.payload
		},
		setVisibleProperties: (state, action: PayloadAction<string[]>) => {
			state.visibleProperties = action.payload
		},
	},
})

export const documentDataActions = documentData.actions

export default documentData.reducer
