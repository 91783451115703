import * as React from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid-premium'

import clsx from 'clsx'

interface ProgressBarProps {
	value: number
}

const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
	const { value } = props
	const limitedValue = Math.min(value, 100)

	return (
		<div
			aria-hidden="true"
			className="relative overflow-hidden w-full h-6 border-[1px] border-solid border-border-divider rounded-lg">
			<div
				aria-hidden="true"
				className="absolute flex items-center justify-center w-full font-bold"
				style={{ lineHeight: '25px' }}>
				{`${value.toLocaleString()} %`}
			</div>
			<div
				aria-hidden="true"
				className={clsx('h-full min-w-0.5', {
					'bg-orange-500': value < 13,
					'bg-orange-300': value >= 13 && value < 26,
					'bg-orange-200': value >= 26 && value < 39,
					'bg-yellow-600': value >= 39 && value < 52,
					'bg-yellow-300': value >= 52 && value < 65,
					'bg-yellow-200': value >= 65 && value < 76,
					'bg-green-300': value >= 76 && value < 96,
					'bg-green-600': value >= 96 && value <= 100,
					'bg-red-500': value > 100,
				})}
				style={{ width: `${limitedValue}%` }}
			/>
		</div>
	)
})
export function RenderProgress(params: GridRenderCellParams<any, any, any>) {
	if (params.value == null) {
		return ''
	}

	return (
		<div className="h-full w-full flex items-center" aria-hidden="true">
			<ProgressBar value={params.value} />
		</div>
	)
}
