import { useTranslation } from 'react-i18next'

import { Box, InputAdornment, MenuItem, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { projectSecurityForm } from 'data/lookup-data-sources'
import { useFieldsPermissions } from 'hooks'
import { IContract } from 'models'
import { FormatNumberInput } from 'utils/FormatNumberInput'
import { formatDateTimeMUI } from 'utils/formatDateTime'

interface Props {
	formData: IContract
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const Deposits = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('contract')

	const theme = useTheme()
	const tabletViewAndUp = useMediaQuery(theme.breakpoints.up('sm'))
	const tabletViewAndDown = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* SecurityDepositInvoiceRetentionValue */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('SecurityDepositInvoiceRetentionValue') && (
						<TextField
							disabled={true}
							value={formData.SecurityDepositInvoiceRetentionValue || ''}
							id="contract-field-SecurityDepositInvoiceRetentionValue"
							name="SecurityDepositInvoiceRetentionValue"
							label={t('Fields.SecurityDepositInvoiceRetentionValue')}
							error={fieldErrorsList.includes('SecurityDepositInvoiceRetentionValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* SecurityDepositInvoiceRetentionPerc */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('SecurityDepositInvoiceRetentionPerc') && (
						<TextField
							disabled={true}
							value={formData.SecurityDepositInvoiceRetentionPerc || ''}
							id="contract-field-SecurityDepositInvoiceRetentionPerc"
							name="SecurityDepositInvoiceRetentionPerc"
							label={t('Fields.SecurityDepositInvoiceRetentionPerc')}
							error={fieldErrorsList.includes('SecurityDepositInvoiceRetentionPerc')}
							onChange={onInputChange}
							placeholder="0"
							type="number"
							InputLabelProps={{ shrink: true }}
							InputProps={{
								inputProps: { min: 0, max: 100 },
								endAdornment: <InputAdornment position="end">%</InputAdornment>,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* DebitNoteValue */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('DebitNoteValue') && (
						<TextField
							disabled={true}
							value={formData.DebitNoteValue || ''}
							id="contract-field-DebitNoteValue"
							name="DebitNoteValue"
							label={t('Fields.DebitNoteValue')}
							error={fieldErrorsList.includes('DebitNoteValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{!tabletViewAndDown && (
					<Grid xs={12} sm={6} md={3} lg={3}>
						{/* Empty Item */}
					</Grid>
				)}
			</Grid>
			<Box
				sx={{
					...sxStyles.row,
					flexDirection: tabletViewAndUp ? 'row' : 'column',
				}}>
				<Box sx={tabletViewAndUp ? sxStyles.itemsContainerLeft : sxStyles.itemsContainerMobile}>
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '12px' }}>
						{/* DepositSecurityFormNWK */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('DepositSecurityFormNWK') && (
								<TextField
									select
									disabled={!isEditable('DepositSecurityFormNWK')}
									value={formData.DepositSecurityFormNWK || ''}
									id="contract-field-DepositSecurityFormNWK"
									name="DepositSecurityFormNWK"
									label={t('Fields.DepositSecurityFormNWK')}
									error={fieldErrorsList.includes('DepositSecurityFormNWK')}
									onChange={onInputChange}
									fullWidth>
									{projectSecurityForm.map((el: any, index: number) => (
										<MenuItem key={index} value={el.value}>
											{t(`Security.SecurityFormType.${el.label}`, {
												ns: 'project',
											})}
										</MenuItem>
									))}
								</TextField>
							)}
						</Grid>
						{/* SecurityDepositAmountPercNWK */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositAmountPercNWK') && (
								<TextField
									disabled={!isEditable('SecurityDepositAmountPercNWK')}
									value={formData.SecurityDepositAmountPercNWK || ''}
									id="contract-field-SecurityDepositAmountPercNWK"
									name="SecurityDepositAmountPercNWK"
									label={t('Fields.SecurityDepositAmountPercNWK')}
									error={fieldErrorsList.includes('SecurityDepositAmountPercNWK')}
									onChange={onInputChange}
									placeholder="0"
									type="number"
									InputLabelProps={{ shrink: true }}
									InputProps={{
										inputProps: { min: 0, max: 100 },
										endAdornment: <InputAdornment position="end">%</InputAdornment>,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* ContractualDepositNWKPaymentDate */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('ContractualDepositNWKPaymentDate') && (
								<TextField
									disabled={true}
									value={formatDateTimeMUI(formData.ContractualDepositNWKPaymentDate) || ''}
									id="contract-field-ContractualDepositNWKPaymentDate"
									name="ContractualDepositNWKPaymentDate"
									label={t('Fields.ContractualDepositNWKPaymentDate')}
									InputLabelProps={{ shrink: true }}
									type="date"
									error={fieldErrorsList.includes('ContractualDepositNWKPaymentDate')}
									onChange={onInputChange}
									fullWidth
								/>
							)}
						</Grid>
						{/* DepositNWKPaidDate */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('DepositNWKPaidDate') && (
								<TextField
									disabled={!isEditable('DepositNWKPaidDate')}
									value={formatDateTimeMUI(formData.DepositNWKPaidDate) || ''}
									id="contract-field-DepositNWKPaidDate"
									name="DepositNWKPaidDate"
									label={t('Fields.DepositNWKPaidDate')}
									InputLabelProps={{ shrink: true }}
									type="date"
									error={fieldErrorsList.includes('DepositNWKPaidDate')}
									onChange={onInputChange}
									fullWidth
								/>
							)}
						</Grid>
						{/* SecurityDepositAmountNWK */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositAmountNWK') && (
								<TextField
									disabled={true}
									value={formData.SecurityDepositAmountNWK || ''}
									id="contract-field-SecurityDepositAmountNWK"
									name="SecurityDepositAmountNWK"
									label={t('Fields.SecurityDepositAmountNWK')}
									error={fieldErrorsList.includes('SecurityDepositAmountNWK')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* DepositNWKValue */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('DepositNWKValue') && (
								<TextField
									disabled={true}
									value={formData.DepositNWKValue || ''}
									id="contract-field-DepositNWKValue"
									name="DepositNWKValue"
									label={t('Fields.DepositNWKValue')}
									error={fieldErrorsList.includes('DepositNWKValue')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* DepositNWKPaid */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('DepositNWKPaid') && (
								<TextField
									disabled={!isEditable('DepositNWKPaid')}
									value={formData.DepositNWKPaid || ''}
									id="contract-field-DepositNWKPaid"
									name="DepositNWKPaid"
									label={t('Fields.DepositNWKPaid')}
									error={fieldErrorsList.includes('DepositNWKPaid')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* DepositNWKRestToPay */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('DepositNWKRestToPay') && (
								<TextField
									disabled={true}
									value={formData.DepositNWKRestToPay || ''}
									id="contract-field-DepositNWKRestToPay"
									name="DepositNWKRestToPay"
									label={t('Fields.DepositNWKRestToPay')}
									error={fieldErrorsList.includes('DepositNWKRestToPay')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* SecurityDepositInvoiceRetentionValueNWK */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositInvoiceRetentionValueNWK') && (
								<TextField
									disabled={!isEditable('SecurityDepositInvoiceRetentionValueNWK')}
									value={formData.SecurityDepositInvoiceRetentionValueNWK || ''}
									id="contract-field-SecurityDepositInvoiceRetentionValueNWK"
									name="SecurityDepositInvoiceRetentionValueNWK"
									label={t('Fields.SecurityDepositInvoiceRetentionValueNWK')}
									error={fieldErrorsList.includes('SecurityDepositInvoiceRetentionValueNWK')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* DepositNWKPaymentComments */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							{isVisible('DepositNWKPaymentComments') && (
								<TextField
									disabled={!isEditable('DepositNWKPaymentComments')}
									value={formData.DepositNWKPaymentComments || ''}
									id="contract-field-DepositNWKPaymentComments"
									name="DepositNWKPaymentComments"
									label={t('Fields.DepositNWKPaymentComments')}
									error={fieldErrorsList.includes('DepositNWKPaymentComments')}
									onChange={onInputChange}
									multiline
									minRows={6}
									fullWidth
								/>
							)}
						</Grid>
					</Grid>
				</Box>
				<Box sx={tabletViewAndUp ? sxStyles.itemsContainerRight : sxStyles.itemsContainerMobile}>
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '12px' }}>
						{/* DepositSecurityFormUWiU */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('DepositSecurityFormUWiU') && (
								<TextField
									select
									disabled={!isEditable('DepositSecurityFormUWiU')}
									value={formData.DepositSecurityFormUWiU || ''}
									id="contract-field-DepositSecurityFormUWiU"
									name="DepositSecurityFormUWiU"
									label={t('Fields.DepositSecurityFormUWiU')}
									error={fieldErrorsList.includes('DepositSecurityFormUWiU')}
									onChange={onInputChange}
									fullWidth>
									{projectSecurityForm.map((el: any, index: number) => (
										<MenuItem key={index} value={el.value}>
											{t(`Security.SecurityFormType.${el.label}`, {
												ns: 'project',
											})}
										</MenuItem>
									))}
								</TextField>
							)}
						</Grid>
						{/* SecurityDepositAmountPercUWiU */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositAmountPercUWiU') && (
								<TextField
									disabled={!isEditable('SecurityDepositAmountPercUWiU')}
									value={formData.SecurityDepositAmountPercUWiU || ''}
									id="contract-field-SecurityDepositAmountPercUWiU"
									name="SecurityDepositAmountPercUWiU"
									label={t('Fields.SecurityDepositAmountPercUWiU')}
									error={fieldErrorsList.includes('SecurityDepositAmountPercUWiU')}
									onChange={onInputChange}
									placeholder="0"
									type="number"
									InputLabelProps={{ shrink: true }}
									InputProps={{
										inputProps: { min: 0, max: 100 },
										endAdornment: <InputAdornment position="end">%</InputAdornment>,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* ContractualDepositUWiUPaymentDate */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('ContractualDepositUWiUPaymentDate') && (
								<TextField
									disabled={!isEditable('ContractualDepositUWiUPaymentDate')}
									value={formatDateTimeMUI(formData.ContractualDepositUWiUPaymentDate) || ''}
									id="contract-field-ContractualDepositUWiUPaymentDate"
									name="ContractualDepositUWiUPaymentDate"
									label={t('Fields.ContractualDepositUWiUPaymentDate')}
									InputLabelProps={{ shrink: true }}
									type="date"
									error={fieldErrorsList.includes('ContractualDepositUWiUPaymentDate')}
									onChange={onInputChange}
									fullWidth
								/>
							)}
						</Grid>
						{/* DepositUWiUPaidDate */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('DepositUWiUPaidDate') && (
								<TextField
									disabled={!isEditable('DepositUWiUPaidDate')}
									value={formatDateTimeMUI(formData.DepositUWiUPaidDate) || ''}
									id="contract-field-DepositUWiUPaidDate"
									name="DepositUWiUPaidDate"
									label={t('Fields.DepositUWiUPaidDate')}
									InputLabelProps={{ shrink: true }}
									type="date"
									error={fieldErrorsList.includes('DepositUWiUPaidDate')}
									onChange={onInputChange}
									fullWidth
								/>
							)}
						</Grid>
						{/* SecurityDepositAmountUWiU */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositAmountUWiU') && (
								<TextField
									disabled={true}
									value={formData.SecurityDepositAmountUWiU || ''}
									id="contract-field-SecurityDepositAmountUWiU"
									name="SecurityDepositAmountUWiU"
									label={t('Fields.SecurityDepositAmountUWiU')}
									error={fieldErrorsList.includes('SecurityDepositAmountUWiU')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* DepositUWiUValue */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('DepositUWiUValue') && (
								<TextField
									disabled={true}
									value={formData.DepositUWiUValue || ''}
									id="contract-field-DepositUWiUValue"
									name="DepositUWiUValue"
									label={t('Fields.DepositUWiUValue')}
									error={fieldErrorsList.includes('DepositUWiUValue')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* DepositUWiUPaid */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('DepositUWiUPaid') && (
								<TextField
									disabled={!isEditable('DepositUWiUPaid')}
									value={formData.DepositUWiUPaid || ''}
									id="contract-field-DepositUWiUPaid"
									name="DepositUWiUPaid"
									label={t('Fields.DepositUWiUPaid')}
									error={fieldErrorsList.includes('DepositUWiUPaid')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* DepositUWiURestToPay */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('DepositUWiURestToPay') && (
								<TextField
									disabled={true}
									value={formData.DepositUWiURestToPay || ''}
									id="contract-field-DepositUWiURestToPay"
									name="DepositUWiURestToPay"
									label={t('Fields.DepositUWiURestToPay')}
									error={fieldErrorsList.includes('DepositUWiURestToPay')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* SecurityDepositInvoiceRetentionValueUWiU */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositInvoiceRetentionValueUWiU') && (
								<TextField
									disabled={!isEditable('SecurityDepositInvoiceRetentionValueUWiU')}
									value={formData.SecurityDepositInvoiceRetentionValueUWiU || ''}
									id="contract-field-SecurityDepositInvoiceRetentionValueUWiU"
									name="SecurityDepositInvoiceRetentionValueUWiU"
									label={t('Fields.SecurityDepositInvoiceRetentionValueUWiU')}
									error={fieldErrorsList.includes('SecurityDepositInvoiceRetentionValueUWiU')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* DepositUWiUPaymentComments */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							{isVisible('DepositUWiUPaymentComments') && (
								<TextField
									disabled={!isEditable('DepositUWiUPaymentComments')}
									value={formData.DepositUWiUPaymentComments || ''}
									id="contract-field-DepositUWiUPaymentComments"
									name="DepositUWiUPaymentComments"
									label={t('Fields.DepositUWiUPaymentComments')}
									error={fieldErrorsList.includes('DepositUWiUPaymentComments')}
									onChange={onInputChange}
									multiline
									minRows={6}
									fullWidth
								/>
							)}
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	)
}

export default Deposits

const sxStyles = {
	row: {
		display: 'flex',
		width: '100%',
		pb: '15px',
	},
	itemsContainerLeft: {
		width: '50%',
		mr: '8px',
	},
	itemsContainerRight: {
		width: '50%',
		ml: '8px',
	},
	itemsContainerMobile: {
		width: '100%',
		mb: '10px',
	},
}
