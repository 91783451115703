import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddLinkIcon from '@mui/icons-material/AddLink'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import { indigo } from '@mui/material/colors'
import {
	DataGridPremium,
	GridCellParams,
	GridEventListener,
	GridRenderCellParams,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { LinkCellRender, LinkEntryDialog, TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { PATHS } from 'data'
import { useAppSelector, useLinkDocumentsActions } from 'features'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IContract, IContractAdditionalCosts } from 'models'
import { enqueueSnackbar } from 'notistack'
import { useContractAdditionalCostsService, useMeasurementUnitsService, useVatRateService } from 'services'
import formatAmountNumberDataGrid from 'utils/formatAmountNumberDataGrid'
import { formatDateTimeMUIGrid } from 'utils/formatDateTime'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'
import prepareTitleString from 'utils/prepareTitleString'

import AdditionalCostsToolbar from './AdditionalCostsToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	contractId: string
	reloadFormData: () => void
	readOnly: boolean
}

const AdditionalCosts = ({ contractId, reloadFormData, readOnly }: Props) => {
	const [additionalCostsData, setAdditionalCostsData] = useState<IContractAdditionalCosts[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [dataLoading, setDataLoading] = useState(true)
	const [vatRate, setVatRate] = useState<any>([])
	const [selectedEntryData, setSelectedEntryData] = useState<IContractAdditionalCosts>()
	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)
	const [selectedEntryId, setSelectedEntryId] = useState('')
	const [selectedEntryName, setSelectedEntryName] = useState('')
	const [measurementUnits, setMeasurementUnits] = useState<{ value: string; label: string }[]>([])

	const { documentData } = useAppSelector(state => state.documentData)
	const { copiedDocumentData, isLinkEntryDialogOpen } = useAppSelector(state => state.linkDocuments)
	const { setIsLinkEntryDialogOpen } = useLinkDocumentsActions()

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['contract', 'translation'])

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { getMeasurementUnitsLookup } = useMeasurementUnitsService()
	const {
		updateContractAdditionalCostsItem,
		deleteContractAdditionalCostsItem,
		addContractAdditionalCostsItem,
		getContractAdditionalCosts,
		linkToBudgetEntry,
		showSuccessInfo,
	} = useContractAdditionalCostsService()
	const { getVatRate } = useVatRateService()

	const handlePrepareLinkToBudgetEntry = (params: any) => {
		const data = documentData as IContract

		if (copiedDocumentData.id && copiedDocumentData.documentType === 'ProjectBudget') {
			setSelectedEntryData(params.row)
			setSelectedEntryId(params.id as string)
			setIsLinkEntryDialogOpen(true)
		} else {
			enqueueSnackbar(
				`${t('LinkDocumentDialog.CopyBudgetEntryDataFirst', { ns: 'translation' })} ${data.ProjectLabel || ''}`,
				{
					variant: 'warning',
					persist: true,
				}
			)
		}
	}

	const handleLinkToBudgetEntry = useCallback(async () => {
		try {
			await linkToBudgetEntry(selectedEntryId, copiedDocumentData.id)
			showSuccessInfo('action')
			getAdditionalCostsData()
		} catch (err) {
			console.error(err)
		}
	}, [linkToBudgetEntry, selectedEntryId, copiedDocumentData])

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id

		if (readOnly) {
			return
		}

		setIsActionButtonDisabled(true)
		setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = additionalCostsData.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setAdditionalCostsData(additionalCostsData.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteContractAdditionalCostsItem(id as string)
			setAdditionalCostsData(additionalCostsData.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
		setSelectedEntryName(`${params.row.Description}`)
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addContractAdditionalCostsItem({
					...newRow,
					ContractId: contractId,
					VatRateId: newRow.VatRateId || null,
				} as IContractAdditionalCosts)
				createdRow = response
				setAdditionalCostsData(additionalCostsData.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
				getAdditionalCostsData()
				if (reloadFormData) {
					reloadFormData()
				}
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateContractAdditionalCostsItem(newRow.Id, newRow as IContractAdditionalCosts)
				setAdditionalCostsData(additionalCostsData.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getAdditionalCostsData()
				if (reloadFormData) {
					reloadFormData()
				}
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'Description',
			headerName: t('AdditionalCosts.Description'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
		},
		{
			field: 'Rate',
			headerName: t('AdditionalCosts.Rate'),
			headerAlign: 'center',
			align: 'right',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: true,
			editable: true,
		},
		{
			field: 'DateFrom',
			headerName: t('AdditionalCosts.DateFrom'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
			type: 'date',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}
				const valueFormatted = formatDateTimeMUIGrid(value)
				return valueFormatted
			},
		},
		{
			field: 'DateTo',
			headerName: t('AdditionalCosts.DateTo'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
			type: 'date',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}
				const valueFormatted = formatDateTimeMUIGrid(value)
				return valueFormatted
			},
		},
		{
			field: 'MeasurementUnitId',
			headerName: t('AdditionalCosts.MeasurementUnitId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.MeasurementUnitId || null
			},
			valueOptions: measurementUnits,
			valueFormatter: ({ value }: any) => {
				const option = measurementUnits.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'Amount',
			headerName: t('AdditionalCosts.Amount'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (!value) {
					return ''
				}

				return formatAmountNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'ValueToBeSettled',
			headerName: t('AdditionalCosts.ValueToBeSettled'),
			headerAlign: 'center',
			align: 'right',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: true,
			editable: false,
		},
		{
			field: 'ValueSettled',
			headerName: t('AdditionalCosts.ValueSettled'),
			headerAlign: 'center',
			align: 'right',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: true,
			editable: false,
		},
		{
			field: 'VatRateId',
			headerName: t('AdditionalCosts.VatRateId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.VatRateId || ''
			},
			valueOptions: vatRate,
			valueFormatter: ({ value }: any) => {
				const option = vatRate.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'BudgetEntryId',
			headerName: t('AdditionalCosts.BudgetEntryId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params: GridRenderCellParams<any>) => (
				<LinkCellRender
					path={PATHS.project}
					id={documentData?.Project as string}
					documentLabel={documentData?.ProjectLabel as string}
					celllabel={params.row.BudgetEntryIdLabel}
					state={{ tab: 1, rowIdToFocus: params.value }}
				/>
			),
		},
		{
			field: 'BudgetEntryDescription',
			headerName: t('RealizationBudgetTab.Columns.Description', {
				ns: 'project',
			}),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
		},
		{
			field: 'MasterArticleId',
			headerName: t('AdditionalCosts.MasterArticleId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				} else {
					return [
						<TooltipButton
							title="general.LinkToBudget"
							onClick={() => handlePrepareLinkToBudgetEntry(params)}
							IconComponent={AddLinkIcon}
							disabled={isActionButtonDisabled}
						/>,
						<TooltipButton
							title="ChangeHistory.ChangeHistory"
							onClick={handleShowHistory(params)}
							IconComponent={HistoryIcon}
							disabled={isActionButtonDisabled}
						/>,
						<TooltipButton
							title="general.Remove"
							onClick={handleDeleteClick(params.id)}
							IconComponent={DeleteIcon}
							disabled={isActionButtonDisabled || readOnly}
						/>,
					]
				}
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getAdditionalCostsData = useCallback(async () => {
		try {
			const response = await getContractAdditionalCosts(contractId)
			setAdditionalCostsData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [contractId])

	const getVatRateData = useCallback(async () => {
		try {
			const response = await getVatRate()
			setVatRate(response.map((el: any) => ({ value: el.Id, label: el.Name })))
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getVatRate])

	const getMeasurementUnitsList = useCallback(async () => {
		try {
			const response = await getMeasurementUnitsLookup()
			setMeasurementUnits(response)
		} catch (err) {
			console.error(err)
		}
	}, [getMeasurementUnitsLookup])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getMeasurementUnitsList()
		getVatRateData()
	}, [])

	useEffect(() => {
		getAdditionalCostsData()
	}, [contractId])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<>
			<DataGridPremium
				autoHeight
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={additionalCostsData}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				onCellDoubleClick={handleCellDoubleClick}
				experimentalFeatures={{ newEditingApi: true }}
				sx={{
					'& .rowFromBudget': {
						color: indigo[300],
					},
				}}
				getRowClassName={params => {
					if (params.row.BudgetEntryNumber) {
						return `rowFromBudget`
					} else {
						return 'simpleRow'
					}
				}}
				components={{
					Toolbar: AdditionalCostsToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						isActionButtonDisabled,
						setIsActionButtonDisabled,
						setAdditionalCostsData,
						setRowModesModel,
						readOnly,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={selectedEntryName}
					translationFile="Contract"
					translationPath="AdditionalCosts"
				/>
			)}
			{isLinkEntryDialogOpen && (
				<LinkEntryDialog
					isOpen={isLinkEntryDialogOpen}
					linkFunction={handleLinkToBudgetEntry}
					itemName={`${t(
						'AdditionalCosts.AdditionalCost'
					)} - ${prepareTitleString(selectedEntryData?.Description as string)}`}
				/>
			)}
		</>
	)
}

export default AdditionalCosts
