import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {},
		orderedFields: [
			'Status',
			'Number',
			'DocumentDate',
			'ValidToDate',
			'DemandLabel',
			'Contractor',
			'ProjectLabel',
			'StageId',
			'WorkflowIdLabel',
			'OwnerIdLabel',
			'CreatorId',
			'ConsumptionDateTimeStart',
			'ConsumptionDateTimeEnd',
			'ContractProgressPerc',
			'ContractType',
			'DepositSecurityFormNWK',
			'DepositSecurityFormUWiU',
			'OfferStatus',
			'Participation',
			'PaymentTimeDays',
			'PaymentTimeDaysFS',
			'SecurityDepositAmountPercNWK',
			'SecurityDepositAmountPercUWiU',
			'SettlementType',
			'ValueToContract',
			'IsCompleted',
			'IsCanceled',
			'actions',
		],
		dimensions: {
			Status: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
				flex: 0,
			},
			Number: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
				flex: 0,
			},
			DocumentDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			ValidToDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			DemandLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			Contractor: {
				maxWidth: -1,
				minWidth: 50,
				width: 350,
			},
			ProjectLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			StageId: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			WorkflowIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			OwnerIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
			},
			CreatorId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ConsumptionDateTimeStart: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			ConsumptionDateTimeEnd: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			ContractProgressPerc: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			ContractType: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			DepositSecurityFormNWK: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			DepositSecurityFormUWiU: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			OfferStatus: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			Participation: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			PaymentTimeDays: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			PaymentTimeDaysFS: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			SecurityDepositAmountPercNWK: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			SecurityDepositAmountPercUWiU: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			SettlementType: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			ValueToContract: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			IsCompleted: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			IsCanceled: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
	aggregation: {
		model: {
			ValueToContract: 'sum',
		},
	},
}

export const CONTEXT_STATE_NAME = 'offersDataGridState'
