import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'

import { enqueueSnackbar } from 'notistack'
import { useInquiriesService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	contractorId: string
	getData: () => void
}

const ImportExcelDialog = ({ isOpen, setIsOpen, contractorId, getData }: Props) => {
	const [buttonLoading, setButtonLoading] = useState(false)
	const [selectedFile, setSelectedFile] = useState<any>('')

	const { t } = useTranslation(['demand', 'translation'])

	const { importDraftOffer, showSuccessInfo } = useInquiriesService()

	const closeDialog = () => {
		setIsOpen(false)
		setSelectedFile('')
	}

	const handleFileSelect = (e: any) => {
		const file = e.target.files[0]
		const fileMaxSizeInBytes = 100 * 1024 * 1024 // 100 MB

		if (file.size > fileMaxSizeInBytes) {
			enqueueSnackbar(t('general.FileSizeError', { ns: 'translation' }), {
				variant: 'error',
				autoHideDuration: 8000,
			})
		} else {
			setSelectedFile(file)
		}
	}

	const handleImportSubmit = useCallback(async () => {
		const formData = new FormData()

		formData.append('file', selectedFile)

		try {
			setButtonLoading(true)
			const response = await importDraftOffer(contractorId, formData)
			if (response.status === 204) {
				getData()
				showSuccessInfo('uploaded')
				closeDialog()
			}
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}, [importDraftOffer, contractorId, selectedFile])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{t('general.ChooseFile', { ns: 'translation' })}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						width: '100%',
						margin: '25px 0 5px 0',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Button
						variant="contained"
						color="primary"
						component="label"
						endIcon={<CloudUploadIcon />}
						sx={{ mb: '8px' }}>
						{t('general.ChooseFile', { ns: 'translation' })}
						<input type="file" accept=".xlsx" name="file" hidden onChange={handleFileSelect} />
					</Button>
					{selectedFile && <span>{selectedFile.name}</span>}
				</Box>
			</DialogContent>
			<DialogActions sx={{ padding: '8px 26px 16px 8px' }}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.Cancel', { ns: 'translation' })}
				</Button>
				<LoadingButton
					variant="contained"
					onClick={handleImportSubmit}
					color="success"
					disabled={!selectedFile}
					loading={buttonLoading}>
					{t('general.Add', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default ImportExcelDialog
