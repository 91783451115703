import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
	Autocomplete,
	Box,
	CircularProgress,
	Divider,
	InputAdornment,
	MenuItem,
	Tab,
	TextField,
	Typography,
	createFilterOptions,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { projectStatus } from 'data/lookup-data-sources'
import { IProjectModel } from 'models'
import { useContractorsService } from 'services'
import { FormatBankAccountInput } from 'utils/FormatBankAccountInput'
import { FormatNumberInput } from 'utils/FormatNumberInput'

import BudgetAssumption from './project-form-tabs/BudgetAssumption'
import ContractorsRepresentatives from './project-form-tabs/contractors-representatives/ContractorsRepresentatives'
import ErpData from './project-form-tabs/erp/ErpData'
import InvestorRepresentatives from './project-form-tabs/investor-representatives/InvestorRepresentatives'
import KeyTerms from './project-form-tabs/key-terms/KeyTerms'
import Security from './project-form-tabs/security/Security'

interface Props {
	projectId: string
	formData?: IProjectModel | null
	onInputChange: (e: any) => void
	onAutocompleteChange: (newValue: any, valueFieldName: string, labelFieldName: string) => void
	fieldErrorsList: string[]
	handleCalculateProjectData?: () => void
	reloadFormData?: () => void
	readOnly: boolean
	reloadWarrantyData?: number
}

const ProjectForm = ({
	projectId,
	formData,
	onInputChange,
	onAutocompleteChange,
	fieldErrorsList,
	handleCalculateProjectData,
	reloadFormData,
	readOnly,
	reloadWarrantyData,
}: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')
	const [contractorsList, setContractorsList] = useState<
		Array<{ value: string; label: string | undefined; SearchItems: string }>
	>([])
	const [selectedContractor, setSelectedContractor] = useState({
		value: '',
		label: '',
		SearchItems: '',
	})
	const [contractorsLoading, setContractorsLoading] = useState(false)

	const { getContractorsLookup } = useContractorsService()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('projectFormSelectedTab', newValue)
	}

	const { t } = useTranslation('project')
	const theme = useTheme()
	const tabletViewAndUp = useMediaQuery(theme.breakpoints.up('sm'))

	const handleContractorChange = (e: any, newValue: any) => {
		if (newValue.value && newValue.label) onAutocompleteChange(newValue, 'ContractorId', 'ContractorIdLabel')
	}

	const tabs = useMemo(
		() => [
			{
				label: t('FormTitles.KeyTerms'),
				renderValue: (
					<KeyTerms
						formData={formData}
						onInputChange={onInputChange}
						fieldErrorsList={fieldErrorsList}
						readOnly={readOnly}
						projectId={projectId}
						reloadFormData={reloadFormData}
						reloadWarrantyData={reloadWarrantyData}
					/>
				),
				isVisible: true,
			},
			{
				label: t('FormTitles.Security'),
				renderValue: <Security projectId={projectId} readOnly={readOnly} reloadFormData={reloadFormData} />,
				isVisible: projectId !== 'draft',
			},
			{
				label: t('FormTitles.ContractorsRepresentatives'),
				renderValue: <ContractorsRepresentatives projectId={projectId} readOnly={readOnly} />,
				isVisible: projectId !== 'draft',
			},
			{
				label: t('FormTitles.InvestorsRepresentatives'),
				renderValue: <InvestorRepresentatives projectId={projectId} readOnly={readOnly} />,
				isVisible: projectId !== 'draft',
			},
			{
				label: t('FormTitles.AssumptionsAndImplementationOfTheBudget'),
				renderValue: (
					<BudgetAssumption
						formData={formData}
						onInputChange={onInputChange}
						fieldErrorsList={fieldErrorsList}
						handleCalculateProjectData={handleCalculateProjectData}
						readOnly={readOnly}
					/>
				),
				isVisible: true,
			},
			{
				label: t('FormTitles.ERP'),
				renderValue: (
					<ErpData
						formData={formData}
						onInputChange={onInputChange}
						onAutocompleteChange={onAutocompleteChange}
						fieldErrorsList={fieldErrorsList}
						readOnly={readOnly}
						projectId={projectId}
					/>
				),
				isVisible: projectId !== 'draft',
			},
		],
		[t, formData, projectId, readOnly]
	)

	const getContractorsData = useCallback(async () => {
		if (contractorsList.length === 0 && !contractorsLoading)
			try {
				setContractorsLoading(true)
				const response = await getContractorsLookup()

				const sortByLabel = response.sort((a: any, b: any) => {
					const labelA = a.label || ''
					const labelB = b.label || ''
					return labelA.localeCompare(labelB)
				})
				setContractorsList(sortByLabel)

				setContractorsLoading(false)
			} catch (err) {
				console.error(err)
				setContractorsLoading(false)
			}
	}, [getContractorsLookup, contractorsList.length, contractorsLoading])

	useEffect(() => {
		const savedTab = localStorage.getItem('projectFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	useEffect(() => {
		if (formData?.ContractorId && formData?.ContractorIdLabel)
			setSelectedContractor({
				label: formData?.ContractorIdLabel,
				value: formData?.ContractorId,
				SearchItems: '',
			})
	}, [formData?.ContractorId])

	return (
		<>
			<Box
				sx={{
					...sxStyles.row,
					flexDirection: tabletViewAndUp ? 'row' : 'column',
				}}>
				{/* Contract Data */}
				<Box sx={tabletViewAndUp ? sxStyles.itemsContainerLeft : sxStyles.itemsContainerMobile}>
					<Typography fontSize={'18px'} sx={sxStyles.title}>
						{t('FormTitles.ContractData')}
					</Typography>
					<Divider />
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
						{/* Project Number  */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={readOnly}
								value={formData?.ProjectNumber || ''}
								id="project-field-ProjectNumber"
								name="ProjectNumber"
								label={t('ProjectFields.ProjectNumber')}
								error={fieldErrorsList.includes('ProjectNumber')}
								onChange={onInputChange}
								fullWidth
							/>
						</Grid>
						{/* Contract Status */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								select
								disabled={readOnly}
								value={formData?.Status || ''}
								id="project-field-Status"
								name="Status"
								label={t('ProjectFields.Status')}
								error={fieldErrorsList.includes('Status')}
								onChange={onInputChange}
								fullWidth>
								{projectStatus.map((el: any, index: number) => (
									<MenuItem key={index} value={el.value}>
										{t(`ProjectStatus.${el.caption}`)}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						{/* Investment Name */}
						<Grid xs={12}>
							<TextField
								disabled={readOnly}
								value={formData?.InvestmentName || ''}
								id="project-field-InvestmentName"
								name="InvestmentName"
								label={t('ProjectFields.InvestmentName')}
								error={fieldErrorsList.includes('InvestmentName')}
								onChange={onInputChange}
								fullWidth
							/>
						</Grid>
						{/* Contractor Id */}
						<Grid xs={12}>
							<Autocomplete
								disablePortal
								disableClearable
								clearOnEscape
								autoHighlight
								value={selectedContractor}
								onOpen={getContractorsData}
								onChange={handleContractorChange}
								options={contractorsList}
								filterOptions={createFilterOptions({
									stringify: option => option.SearchItems,
								})}
								loading={contractorsLoading}
								loadingText={t('general.LoadingText', { ns: 'translation' })}
								noOptionsText={t('general.NoData', { ns: 'translation' })}
								disabled={readOnly}
								renderOption={(props, option) => (
									<li {...props} key={option.value}>
										{option.label}
									</li>
								)}
								renderInput={(params: any) => (
									<TextField
										{...params}
										label={t('ProjectFields.ContractorId')}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{contractorsLoading ? <CircularProgress size={20} sx={{ mr: '5px' }} /> : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>
						</Grid>
						{/* Adress City */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={readOnly}
								value={formData?.Address_City || ''}
								id="project-field-Address_City"
								name="Address_City"
								label={t('ProjectFields.Address_City')}
								error={fieldErrorsList.includes('Address_City')}
								onChange={onInputChange}
								fullWidth
							/>
						</Grid>
						{/* Adress Street */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={readOnly}
								value={formData?.Address_Street || ''}
								id="project-field-Address_Street"
								name="Address_Street"
								label={t('ProjectFields.Address_Street')}
								error={fieldErrorsList.includes('Address_Street')}
								onChange={onInputChange}
								fullWidth
							/>
						</Grid>
						{/* Adress Postal Code */}
						<Grid xs={6} sm={6} md={3} lg={3}>
							<TextField
								value={formData?.Address_PostalCode || ''}
								error={fieldErrorsList.includes('Address_PostalCode')}
								onChange={onInputChange}
								disabled={readOnly}
								id="project-field-Address_PostalCode"
								name="Address_PostalCode"
								inputProps={{ maxLength: 6 }}
								placeholder="00-000"
								label={t('ProjectFields.Address_PostalCode')}
								fullWidth
							/>
						</Grid>
						{/* Adress Post */}
						<Grid xs={6} sm={6} md={3} lg={3}>
							<TextField
								value={formData?.Address_Post || ''}
								error={fieldErrorsList.includes('Address_Post')}
								onChange={onInputChange}
								disabled={readOnly}
								id="project-field-Address_Post"
								name="Address_Post"
								label={t('ProjectFields.Address_Post')}
								fullWidth
							/>
						</Grid>
						{/* Adress Street Number */}
						<Grid xs={6} sm={6} md={3} lg={3}>
							<TextField
								disabled={readOnly}
								value={formData?.Address_StreetNumber || ''}
								id="project-field-Address_StreetNumber"
								name="Address_StreetNumber"
								label={t('ProjectFields.Address_StreetNumber')}
								error={fieldErrorsList.includes('Address_StreetNumber')}
								onChange={onInputChange}
								fullWidth
							/>
						</Grid>
						{/* Adress Flat Number */}
						<Grid xs={6} sm={6} md={3} lg={3}>
							<TextField
								disabled={readOnly}
								value={formData?.Address_FlatNumber || ''}
								id="project-field-Address_FlatNumber"
								name="Address_FlatNumber"
								label={t('ProjectFields.Address_FlatNumber')}
								error={fieldErrorsList.includes('Address_FlatNumber')}
								onChange={onInputChange}
								fullWidth
							/>
						</Grid>
						{/* Description */}
						<Grid xs={12}>
							<TextField
								disabled={readOnly}
								value={formData?.Description || ''}
								id="project-field-Description"
								name="Description"
								label={t('ProjectFields.Description')}
								error={fieldErrorsList.includes('Description')}
								onChange={onInputChange}
								minRows={3}
								multiline
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>
				{/* Trading parameters and settlements of the Contract */}
				<Box sx={tabletViewAndUp ? sxStyles.itemsContainerRight : sxStyles.itemsContainerMobile}>
					<Typography fontSize={'18px'} sx={sxStyles.title}>
						{t('FormTitles.TradingAndBillingParameters')}
					</Typography>
					<Divider />
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
						{/* Work Progress Percent */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={readOnly}
								value={formData?.WorkProgressPerc || ''}
								id="project-field-WorkProgressPerc"
								name="WorkProgressPerc"
								label={t('ProjectFields.WorkProgressPerc')}
								error={fieldErrorsList.includes('WorkProgressPerc')}
								onChange={onInputChange}
								placeholder="0"
								type="number"
								InputLabelProps={{ shrink: true }}
								InputProps={{
									inputProps: { min: 0, max: 100 },
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								fullWidth
							/>
						</Grid>
						{/* Project Value With Annexes */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={true}
								value={formData?.ProjectValueWithAnnexes || ''}
								id="project-field-ProjectValueWithAnnexes"
								name="ProjectValueWithAnnexes"
								label={t('ProjectFields.ProjectValueWithAnnexes')}
								error={fieldErrorsList.includes('ProjectValueWithAnnexes')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						</Grid>
						{/* Annexes Value */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={true}
								value={formData?.AnnexesValue || ''}
								id="project-field-AnnexesValue"
								name="AnnexesValue"
								label={t('ProjectFields.AnnexesValue')}
								error={fieldErrorsList.includes('AnnexesValue')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						</Grid>
						{/* Project Value Done */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={true}
								value={formData?.ProjectValueDone || ''}
								id="project-field-ProjectValueDone"
								name="ProjectValueDone"
								label={t('ProjectFields.ProjectValueDone')}
								error={fieldErrorsList.includes('ProjectValueDone')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						</Grid>
						{/* Last PPI Value */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={true}
								value={formData?.LastPPIValue || ''}
								id="project-field-LastPPIValue"
								name="LastPPIValue"
								label={t('ProjectFields.LastPPIValue')}
								error={fieldErrorsList.includes('LastPPIValue')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						</Grid>
						{/* Sales Invoice Value Sum */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={true}
								value={formData?.SalesInvoiceValueSum || ''}
								id="project-field-SalesInvoiceValueSum"
								name="SalesInvoiceValueSum"
								label={t('ProjectFields.SalesInvoiceValueSum')}
								error={fieldErrorsList.includes('SalesInvoiceValueSum')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						</Grid>
						{/* Last Sales Invoice Value */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={true}
								value={formData?.LastSalesInvoiceValue || ''}
								id="project-field-LastSalesInvoiceValue"
								name="LastSalesInvoiceValue"
								label={t('ProjectFields.LastSalesInvoiceValue')}
								error={fieldErrorsList.includes('LastSalesInvoiceValue')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						</Grid>
						{/* Project Value To Complete */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={true}
								value={formData?.ProjectValueToComplete || ''}
								id="project-field-ProjectValueToComplete"
								name="ProjectValueToComplete"
								label={t('ProjectFields.ProjectValueToComplete')}
								error={fieldErrorsList.includes('ProjectValueToComplete')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						</Grid>
						{/* Project Value To Be Invoced */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={true}
								value={formData?.ProjectValueToBeInvoced || ''}
								id="project-field-ProjectValueToBeInvoced"
								name="ProjectValueToBeInvoced"
								label={t('ProjectFields.ProjectValueToBeInvoced')}
								error={fieldErrorsList.includes('ProjectValueToBeInvoced')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						</Grid>
						{/* Bank Account Number */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={readOnly}
								value={formData?.BankAccountNumber || ''}
								id="project-field-BankAccountNumber"
								name="BankAccountNumber"
								label={t('ProjectFields.BankAccountNumber')}
								placeholder="00 0000 0000 0000 0000 0000 0000"
								error={fieldErrorsList.includes('BankAccountNumber')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatBankAccountInput as any,
								}}
								fullWidth
							/>
						</Grid>
						{/* Project Progress Percent */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={true}
								value={formData?.ProjectProgressPerc || ''}
								id="project-field-ProjectProgressPerc"
								name="ProjectProgressPerc"
								label={t('ProjectFields.ProjectProgressPerc')}
								error={fieldErrorsList.includes('ProjectProgressPerc')}
								onChange={onInputChange}
								placeholder="0"
								type="number"
								InputLabelProps={{ shrink: true }}
								InputProps={{
									inputProps: { min: 0, max: 100 },
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								fullWidth
							/>
						</Grid>
						{/* BankName */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							<TextField
								disabled={readOnly}
								value={formData?.BankName || ''}
								id="project-field-BankName"
								name="BankName"
								label={t('ProjectFields.BankName')}
								error={fieldErrorsList.includes('BankName')}
								onChange={onInputChange}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Box sx={sxStyles.row}>
				<Box sx={{ width: '100%' }}>
					<TabContext value={selectedTab}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
								{tabs.map((el, index) => {
									if (el.isVisible) return <Tab key={el.label} label={el.label} value={index.toString()} />
								})}
							</TabList>
						</Box>
						{tabs.map((el, index) => {
							if (el.isVisible)
								return (
									<TabPanel key={index} value={index.toString()} sx={sxStyles.tabPanel}>
										{el.renderValue}
									</TabPanel>
								)
						})}
					</TabContext>
				</Box>
			</Box>
		</>
	)
}

export default ProjectForm

const sxStyles = {
	row: {
		display: 'flex',
		width: '100%',
		pb: '15px',
	},
	itemsContainerLeft: {
		width: '50%',
		mr: '8px',
	},
	itemsContainerRight: {
		width: '50%',
		ml: '8px',
	},
	itemsContainerMobile: {
		width: '100%',
		mb: '10px',
	},
	title: {
		padding: '5px',
	},
	tabPanel: {
		padding: '10px 0',
	},
}
