import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'

import { useWorkflowService } from 'services'

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	workflowId: string
	updateStages: (selectnewlyAddedStage?: boolean) => void
}

const AddStageDialog = ({ isOpen, setIsOpen, workflowId, updateStages }: Props) => {
	const [stageName, setStageName] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { addStage, showSuccessInfo } = useWorkflowService()

	const handleStageNameChange = (e: any) => {
		setStageName(e.target.value)
	}

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleCreateStage = async () => {
		try {
			const newStageData = {
				workflowId,
				name: stageName,
			}

			setButtonLoading(true)
			await addStage(newStageData)

			showSuccessInfo('action')
			updateStages(true)
			closeDialog()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Workflow.Permissions.AddStageDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					sx={{ mt: '10px' }}
					disabled={false}
					value={stageName}
					name="StageName"
					label={t('Workflow.Permissions.AddStageDialog.StageName')}
					onChange={handleStageNameChange}
					fullWidth
				/>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					color="success"
					disabled={!stageName}
					loading={buttonLoading}
					onClick={handleCreateStage}>
					{t('general.Create', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddStageDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
