import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput, AutocompleteInputOption } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions } from 'hooks'
import { ICostInvoice, ICurrency } from 'models'
import { useCurrencyService } from 'services'
import { FormatNumberInput } from 'utils/FormatNumberInput'

interface Props {
	formData: ICostInvoice
	onInputChange: (e: any) => void
	onAutocompleteChange: (newValue: AutocompleteInputOption, valueFieldName: string, labelFieldName: string) => void
	fieldErrorsList: string[]
}

const Values = ({ formData, onInputChange, onAutocompleteChange, fieldErrorsList }: Props) => {
	const [currencyList, setCurrencyList] = useState<ICurrency[]>([])

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { getCurrency } = useCurrencyService()

	const getCurrencyData = async (instanceId: string) => {
		if (currencyList.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getCurrency()
				setCurrencyList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	return (
		<div>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* Currency  */}
				{isVisible('Currency') && (
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						order={{ md: 2, lg: 2 }}
						initialValue={{
							value: formData.Currency || '',
							label: formData.CurrencyLabel || '',
						}}
						onOpen={() => getCurrencyData('CostInvoice.Fields.Currency')}
						onChange={onAutocompleteChange}
						options={currencyList}
						readOnly={!isEditable('Currency')}
						instanceId="CostInvoice.Fields.Currency"
						inputLabel={t('CostInvoice.Fields.Currency')}
						name="Currency"
						labelFieldName="CurrencyLabel"
					/>
				)}
				{/* ExchangeRate  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 3, lg: 3 }}>
					{isVisible('ExchangeRate') && (
						<TextField
							disabled={!isEditable('ExchangeRate')}
							value={formData.ExchangeRate || ''}
							name="ExchangeRate"
							label={t('CostInvoice.Fields.ExchangeRate')}
							error={fieldErrorsList.includes('ExchangeRate')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueNetto  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 0, lg: 0 }}>
					{isVisible('ValueNetto') && (
						<TextField
							disabled={!isEditable('ValueNetto')}
							value={formData.ValueNetto || ''}
							name="ValueNetto"
							label={t('CostInvoice.Fields.ValueNetto')}
							error={fieldErrorsList.includes('ValueNetto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueVAT  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 1, lg: 1 }}>
					{isVisible('ValueVAT') && (
						<TextField
							disabled={!isEditable('ValueVAT')}
							value={formData.ValueVAT || ''}
							name="ValueVAT"
							label={t('CostInvoice.Fields.ValueVAT')}
							error={fieldErrorsList.includes('ValueVAT')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueBrutto  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 4, lg: 4 }}>
					{isVisible('ValueBrutto') && (
						<TextField
							disabled={!isEditable('ValueBrutto')}
							value={formData.ValueBrutto || ''}
							name="ValueBrutto"
							label={t('CostInvoice.Fields.ValueBrutto')}
							error={fieldErrorsList.includes('ValueBrutto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueBruttoPLN  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 5, lg: 5 }}>
					{isVisible('ValueBruttoPLN') && (
						<TextField
							disabled={!isEditable('ValueBruttoPLN')}
							value={formData.ValueBruttoPLN || ''}
							name="ValueBruttoPLN"
							label={t('CostInvoice.Fields.ValueBruttoPLN')}
							error={fieldErrorsList.includes('ValueBruttoPLN')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueAdvanceToDeduct  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 6, lg: 6 }}>
					{isVisible('ValueAdvanceToDeduct') && (
						<TextField
							disabled={!isEditable('ValueAdvanceToDeduct')}
							value={formData.ValueAdvanceToDeduct || ''}
							name="ValueAdvanceToDeduct"
							label={t('CostInvoice.Fields.ValueAdvanceToDeduct')}
							error={fieldErrorsList.includes('ValueAdvanceToDeduct')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueBruttoToPay  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 7, lg: 7 }}>
					{isVisible('ValueBruttoToPay') && (
						<TextField
							disabled={!isEditable('ValueBruttoToPay')}
							value={formData.ValueBruttoToPay || ''}
							name="ValueBruttoToPay"
							label={t('CostInvoice.Fields.ValueBruttoToPay')}
							error={fieldErrorsList.includes('ValueBruttoToPay')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* SettlementValue  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 8, lg: 8 }}>
					{isVisible('SettlementValue') && (
						<TextField
							disabled={!isEditable('SettlementValue')}
							value={formData.SettlementValue || ''}
							name="SettlementValue"
							label={t('CostInvoice.Fields.SettlementValue')}
							error={fieldErrorsList.includes('SettlementValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueToSettlement  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 9, lg: 9 }}>
					{isVisible('ValueToSettlement') && (
						<TextField
							disabled={!isEditable('ValueToSettlement')}
							value={formData.ValueToSettlement || ''}
							name="ValueToSettlement"
							label={t('CostInvoice.Fields.ValueToSettlement')}
							error={fieldErrorsList.includes('ValueToSettlement')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueBruttoOfSalesInvoice  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 10, lg: 10 }}>
					{isVisible('ValueBruttoOfSalesInvoice') && (
						<TextField
							disabled={!isEditable('ValueBruttoOfSalesInvoice')}
							value={formData.ValueBruttoOfSalesInvoice || ''}
							name="ValueBruttoOfSalesInvoice"
							label={t('CostInvoice.Fields.ValueBruttoOfSalesInvoice')}
							error={fieldErrorsList.includes('ValueBruttoOfSalesInvoice')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* DepositValueNetto  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 11, lg: 11 }}>
					{isVisible('DepositValueNetto') && (
						<TextField
							disabled={!isEditable('DepositValueNetto')}
							value={formData.DepositValueNetto || ''}
							name="DepositValueNetto"
							label={t('CostInvoice.Fields.DepositValueNetto')}
							error={fieldErrorsList.includes('DepositValueNetto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* AccountingNote  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 12, lg: 12 }}>
					{isVisible('AccountingNote') && (
						<TextField
							disabled={!isEditable('AccountingNote')}
							value={formData.AccountingNote || ''}
							name="AccountingNote"
							label={t('CostInvoice.Fields.AccountingNote')}
							error={fieldErrorsList.includes('AccountingNote')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ProcessingProtocolValue  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 13, lg: 13 }}>
					{isVisible('ProcessingProtocolValue') && (
						<TextField
							disabled={!isEditable('ProcessingProtocolValue')}
							value={formData.ProcessingProtocolValue || ''}
							name="ProcessingProtocolValue"
							label={t('CostInvoice.Fields.ProcessingProtocolValue')}
							error={fieldErrorsList.includes('ProcessingProtocolValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueAdvanceDeducted  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 14, lg: 14 }}>
					{isVisible('ValueAdvanceDeducted') && (
						<TextField
							disabled={!isEditable('ValueAdvanceDeducted')}
							value={formData.ValueAdvanceDeducted || ''}
							name="ValueAdvanceDeducted"
							label={t('CostInvoice.Fields.ValueAdvanceDeducted')}
							error={fieldErrorsList.includes('ValueAdvanceDeducted')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* PaidValue  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 15, lg: 15 }}>
					{isVisible('PaidValue') && (
						<TextField
							disabled={!isEditable('PaidValue')}
							value={formData.PaidValue || ''}
							name="PaidValue"
							label={t('CostInvoice.Fields.PaidValue')}
							error={fieldErrorsList.includes('PaidValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* Description */}
				<Grid xs={12} sm={12} md={12} lg={12} order={{ md: 16, lg: 16 }}>
					{isVisible('Description') && (
						<TextField
							disabled={!isEditable('Description')}
							value={formData.Description || ''}
							name="Description"
							label={t('CostInvoice.Fields.Description')}
							error={fieldErrorsList.includes('Description')}
							onChange={onInputChange}
							multiline
							minRows={4}
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Values
