import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useFieldsPermissions } from 'hooks'
import { IProcessingProtocol } from 'models'
import { FormatNumberInput } from 'utils/FormatNumberInput'

interface Props {
	formData: IProcessingProtocol
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const PreviousSettlements = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isVisible } = useFieldsPermissions()

	const { t } = useTranslation('processing-protocol')

	return (
		<div className="w-full pb-4">
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* FinalWorkDoneValue  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('FinalWorkDoneValue') && (
						<TextField
							disabled={true}
							value={formData.FinalWorkDoneValue || ''}
							name="FinalWorkDoneValue"
							label={t('Fields.FinalWorkDoneValue')}
							error={fieldErrorsList.includes('FinalWorkDoneValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* FinalSecurityDepositAmountSaved  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('FinalSecurityDepositAmountSaved') && (
						<TextField
							disabled={true}
							value={formData.FinalSecurityDepositAmountSaved || ''}
							name="FinalSecurityDepositAmountSaved"
							label={t('Fields.FinalSecurityDepositAmountSaved')}
							error={fieldErrorsList.includes('FinalSecurityDepositAmountSaved')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* FinalParticipationAndReInvoice  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('FinalParticipationAndReInvoice') && (
						<TextField
							disabled={true}
							value={formData.FinalParticipationAndReInvoice || ''}
							name="FinalParticipationAndReInvoice"
							label={t('Fields.FinalParticipationAndReInvoice')}
							error={fieldErrorsList.includes('FinalParticipationAndReInvoice')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* FinalDebitNoteValue  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('FinalDebitNoteValue') && (
						<TextField
							disabled={true}
							value={formData.FinalDebitNoteValue || ''}
							name="FinalDebitNoteValue"
							label={t('Fields.FinalDebitNoteValue')}
							error={fieldErrorsList.includes('FinalDebitNoteValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* FinalPaidNettoValue  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('FinalPaidNettoValue') && (
						<TextField
							disabled={true}
							value={formData.FinalPaidNettoValue || ''}
							name="FinalPaidNettoValue"
							label={t('Fields.FinalPaidNettoValue')}
							error={fieldErrorsList.includes('FinalPaidNettoValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default PreviousSettlements
