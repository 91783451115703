import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'

import { useWorkflowService } from 'services'

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	stageId: string
	updateStages: (selectnewlyAddedStage?: boolean, ignoreIndexChange?: boolean) => void
	stageName: string
}

const EditStageDialog = ({ isOpen, setIsOpen, stageId, updateStages, stageName }: Props) => {
	const [newStageName, setNewStageName] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { updateStage, showSuccessInfo } = useWorkflowService()

	const handleStageNameChange = (e: any) => {
		setNewStageName(e.target.value)
	}

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleUpdateStage = async () => {
		try {
			setButtonLoading(true)
			await updateStage(stageId, newStageName)

			showSuccessInfo('saved')
			updateStages(false, true)
			closeDialog()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	useEffect(() => {
		setNewStageName(stageName)
	}, [stageName])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Workflow.Permissions.EditStageDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					sx={{ mt: '10px' }}
					disabled={false}
					value={newStageName}
					name="StageName"
					label={t('Workflow.Permissions.EditStageDialog.StageName')}
					onChange={handleStageNameChange}
					fullWidth
				/>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					color="success"
					disabled={!newStageName}
					loading={buttonLoading}
					onClick={handleUpdateStage}>
					{t('general.Save', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default EditStageDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
