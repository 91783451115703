import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	MenuItem,
	TextField,
} from '@mui/material'

import { Loading } from 'components/shared/loading'
import { useAppSelector, useDocumentActionsStateActions } from 'features'
import { Action, IContractAdditionalCosts, ISalesInvoice } from 'models'
import { useContractAdditionalCostsService } from 'services'
import prepareTitleString from 'utils/prepareTitleString'

type Props = {
	isOpen: boolean
	action: Action
	invokeAction: (value: Action, parameters?: any) => void
}

const AddAdditionalCostDialog = ({ isOpen, action, invokeAction }: Props) => {
	const [additionalCostsData, setAdditionalCostsData] = useState<IContractAdditionalCosts[]>([])
	const [selectedAdditionalCost, setSelectedAdditionalCost] = useState('')
	const [loading, setLoading] = useState(true)

	const { documentData } = useAppSelector(state => state.documentData)
	const { Contract } = documentData as ISalesInvoice

	const { setIsAdditionalCostDialogOpen } = useDocumentActionsStateActions()

	const { getContractAdditionalCosts } = useContractAdditionalCostsService()

	const { t } = useTranslation()

	const closeDialog = () => {
		setIsAdditionalCostDialogOpen(false)
		setAdditionalCostsData([])
		setSelectedAdditionalCost('')
	}

	const handleInputChange = (e: any) => {
		setSelectedAdditionalCost(e.target.value)
	}

	const getAdditionalCostsData = async () => {
		try {
			setLoading(true)
			const response = await getContractAdditionalCosts(Contract)
			setAdditionalCostsData(response)
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}

	const handleActionConfirm = () => {
		const parameters = selectedAdditionalCost
			? {
					additionalCostEntryId: selectedAdditionalCost,
				}
			: null

		invokeAction(action, parameters)
		closeDialog()
	}

	useEffect(() => {
		getAdditionalCostsData()
	}, [Contract])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Actions.AddAdditionalCostDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{loading ? (
					<Loading />
				) : (
					<FormControl fullWidth>
						<TextField
							disabled={false}
							select
							value={selectedAdditionalCost}
							name="ChooseAdditionalCost"
							label={t('Actions.AddAdditionalCostDialog.ChooseAdditionalCost')}
							onChange={handleInputChange}
							fullWidth
							sx={{ mt: '10px' }}>
							{additionalCostsData.map((el: IContractAdditionalCosts, index: number) => (
								<MenuItem key={index} value={el.Id}>
									{prepareTitleString(el.Description, 70)}
								</MenuItem>
							))}
						</TextField>
					</FormControl>
				)}
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="contained" color="success" disabled={!selectedAdditionalCost} onClick={handleActionConfirm}>
					{t('general.Add')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default AddAdditionalCostDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	workflowViewContainer: { display: 'flex', flexDirection: 'column' },
	workflowViewMessage: {
		width: '100%',
		p: '3px',
		mt: '6px',
		textAlign: 'center',
	},
	documentName: {
		pt: '8px',
		color: 'primary.main',
		cursor: 'pointer',
		'&:hover': { textDecoration: 'underline' },
	},
	warningMessage: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		p: '10px',
		pt: '20px',
		color: 'warning.main',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
