import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Checkbox, FormControlLabel, InputAdornment, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ContactPersons, ContractorForm, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IProcessingProtocol } from 'models'
import { PROCESSING_PROTOCOL_ENDPOINT } from 'services'

import FinalSettlements from './form-tabs/FinalSettlements'
import PreviousSettlements from './form-tabs/PreviousSettlements'
import Settlements from './form-tabs/Settlements'

interface Props {
	protocolId: string
	formData: IProcessingProtocol
}

const ProcessingProtocolForm = ({ protocolId, formData }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('processing-protocol')

	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

	const { handleInputChange, fieldsError } = useFormDataChange()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('processingProtocolFormSelectedTab', newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('BasicInformationFormTabs.Settlements'),
				renderValue: (
					<Settlements formData={formData} onInputChange={handleInputChange} fieldErrorsList={fieldsError} />
				),
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.SettlementsOfPreviousPeriods'),
				renderValue: (
					<PreviousSettlements formData={formData} onInputChange={handleInputChange} fieldErrorsList={fieldsError} />
				),
				isVisible: formData.IsFinalProtocol,
			},
			{
				label: t('BasicInformationFormTabs.FinalSettlement'),
				renderValue: (
					<FinalSettlements formData={formData} onInputChange={handleInputChange} fieldErrorsList={fieldsError} />
				),
				isVisible: formData.IsFinalProtocol,
			},
			{
				label: t('BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons
						documentId={protocolId}
						contractorId={formData.Contractor || ''}
						endpoint={PROCESSING_PROTOCOL_ENDPOINT}
					/>
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
		],
		[t, formData]
	)

	useEffect(() => {
		const savedTab = localStorage.getItem('processingProtocolFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('Fields.Number')}
								error={fieldsError.includes('Number')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={!isEditable('DocumentDate')}
								value={formData.DocumentDate || ''}
								name="DocumentDate"
								label={t('Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DocumentDate')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Contract */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Contract') && (
							<TextField
								disabled={true}
								value={formData.ContractLabel || ''}
								name="Contract"
								label={t('Fields.Contract')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.contract}
												documentId={formData.Contract}
												documentLabel={formData.ContractLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{!mobileView && (
						<Grid xs={12} sm={6} md={3} lg={3}>
							{/* Empty Item */}
						</Grid>
					)}
					{/* IsFinalProtocol */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<FormControlLabel
							control={<Checkbox disabled checked={formData?.IsFinalProtocol || false} name="IsFinalProtocol" />}
							label={t('Fields.IsFinalProtocol')}
						/>
					</Grid>
				</Grid>
			</div>
			<ContractorForm
				documentId={protocolId}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
			/>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default ProcessingProtocolForm
