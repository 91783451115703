import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import LinkIcon from '@mui/icons-material/Link'

import { DocumentFormSaveButton, FormSkeletonLoading, TooltipButton } from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import { useAppSelector, useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features'
import { useLinkDocuments } from 'hooks'
import { IOrder } from 'models'
import { useOrderService } from 'services'

import OrderForm from './OrderForm'

interface Props {
	orderId: string
}

const OrderBasicInfo = ({ orderId }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)

	const { setDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { handleCopyDocumentData } = useLinkDocuments()

	const { t } = useTranslation()

	const { getOrder, updateOrder } = useOrderService()

	const handleCopyDataForLinking = () => {
		handleCopyDocumentData({
			id: orderId,
			name: `${t('Order', { ns: 'routes' })} ${documentData?.Number || ''}`,
			documentType: 'Order',
		})
	}

	const refreshDocumentData = async () => {
		try {
			const response = await getOrder(orderId)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [orderId])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={6} tabs={2} />
	}

	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
					/>
				</div>
				<div>
					<TooltipButton
						title="LinkDocumentClipboard.CopyId"
						onClick={handleCopyDataForLinking}
						IconComponent={LinkIcon}
						fontSize="medium"
					/>
					<ChangeHistory
						contextId={orderId}
						translationFile="order"
						title={`${t('Order', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="Fields"
					/>
					<DocumentFormSaveButton
						documentId={orderId}
						data={documentData}
						updateDataFunction={updateOrder}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<OrderForm formData={documentData as IOrder} orderId={orderId} />
			</div>
		</>
	)
}

export default OrderBasicInfo
