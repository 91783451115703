import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput } from 'components/shared'
import { useAppSelector } from 'features'
import { useAutocompleteInputActions } from 'features/actions'
import { IMasterCategory, IMasterRegistry, IProjectModel } from 'models'
import { useMasterCategoryService, useMasterRegistryService } from 'services'

import MasterConfiguration from './MasterConfiguration'

interface Props {
	formData?: IProjectModel | null
	onInputChange: (e: any) => void
	onAutocompleteChange: (newValue: any, valueFieldName: string, labelFieldName: string) => void
	fieldErrorsList: string[]
	readOnly: boolean
	projectId: string
}

const ErpData = ({ formData, onInputChange, onAutocompleteChange, fieldErrorsList, readOnly, projectId }: Props) => {
	const [masterCategories, setMasterCategories] = useState<IMasterCategory[]>([])
	const [masterRegistryList, setMasterRegistryList] = useState<IMasterRegistry[]>([])

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()

	const { getMasterCategoryList } = useMasterCategoryService()
	const { getMasterRegistryList } = useMasterRegistryService()

	const { t } = useTranslation('project')

	const getMasterCategoriesData = async (instanceId: string) => {
		if (masterCategories.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterCategoryList()

				setMasterCategories(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	const getMasterRegistryData = async (instanceId: string) => {
		if (masterRegistryList.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterRegistryList()

				setMasterRegistryList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	return (
		<Box>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* MasterContractSalesInvoiceCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterContractSalesInvoiceCategoryId || '',
						label: formData?.MasterContractSalesInvoiceCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterContractSalesInvoiceCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForContractSalesInvoice)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterContractSalesInvoiceCategoryId'}
					inputLabel={t('ProjectFields.MasterContractSalesInvoiceCategoryId')}
					name="MasterContractSalesInvoiceCategoryId"
					labelFieldName="MasterContractSalesInvoiceCategoryIdLabel"
				/>
				{/* MasterContractSalesInvoiceDocumentSeries */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					<TextField
						disabled={readOnly}
						value={formData?.MasterContractSalesInvoiceDocumentSeries || ''}
						id="project-field-MasterContractSalesInvoiceDocumentSeries"
						name="MasterContractSalesInvoiceDocumentSeries"
						label={t('ProjectFields.MasterContractSalesInvoiceDocumentSeries')}
						error={fieldErrorsList.includes('MasterContractSalesInvoiceDocumentSeries')}
						onChange={onInputChange}
						fullWidth
					/>
				</Grid>
				{/* MasterSalesInvoiceCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterSalesInvoiceCategoryId || '',
						label: formData?.MasterSalesInvoiceCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterSalesInvoiceCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForSalesInvoice)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterSalesInvoiceCategoryId'}
					inputLabel={t('ProjectFields.MasterSalesInvoiceCategoryId')}
					name="MasterSalesInvoiceCategoryId"
					labelFieldName="MasterSalesInvoiceCategoryIdLabel"
				/>
				{/* MasterSalesInvoiceDocumentSeries */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					<TextField
						disabled={readOnly}
						value={formData?.MasterSalesInvoiceDocumentSeries || ''}
						id="project-field-MasterSalesInvoiceDocumentSeries"
						name="MasterSalesInvoiceDocumentSeries"
						label={t('ProjectFields.MasterSalesInvoiceDocumentSeries')}
						error={fieldErrorsList.includes('MasterSalesInvoiceDocumentSeries')}
						onChange={onInputChange}
						fullWidth
					/>
				</Grid>
				{/* MasterCostInvoiceRegistryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCostInvoiceRegistryId || '',
						label: formData?.MasterCostInvoiceRegistryIdLabel || '',
					}}
					onOpen={() => getMasterRegistryData('ProjectFields.MasterCostInvoiceRegistryId')}
					onChange={onAutocompleteChange}
					options={masterRegistryList}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCostInvoiceRegistryId'}
					inputLabel={t('ProjectFields.MasterCostInvoiceRegistryId')}
					name="MasterCostInvoiceRegistryId"
					labelFieldName="MasterCostInvoiceRegistryIdLabel"
				/>
				{/* MasterCostInvoiceCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCostInvoiceCategoryId || '',
						label: formData?.MasterCostInvoiceCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterCostInvoiceCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCostInvoiceCategory1)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCostInvoiceCategoryId'}
					inputLabel={t('ProjectFields.MasterCostInvoiceCategoryId')}
					name="MasterCostInvoiceCategoryId"
					labelFieldName="MasterCostInvoiceCategoryIdLabel"
				/>
				{/* MasterCostInvoiceCategory2Id */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCostInvoiceCategory2Id || '',
						label: formData?.MasterCostInvoiceCategory2IdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterCostInvoiceCategory2Id')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCostInvoiceCategory2)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCostInvoiceCategory2Id'}
					inputLabel={t('ProjectFields.MasterCostInvoiceCategory2Id')}
					name="MasterCostInvoiceCategory2Id"
					labelFieldName="MasterCostInvoiceCategory2IdLabel"
				/>
				<Grid xs={12} sm={6} md={3} lg={3}>
					{/* Empty Item */}
				</Grid>
				{/* MasterMaterialInvoiceRegistryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterMaterialInvoiceRegistryId || '',
						label: formData?.MasterMaterialInvoiceRegistryIdLabel || '',
					}}
					onOpen={() => getMasterRegistryData('ProjectFields.MasterMaterialInvoiceRegistryId')}
					onChange={onAutocompleteChange}
					options={masterRegistryList}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterMaterialInvoiceRegistryId'}
					inputLabel={t('ProjectFields.MasterMaterialInvoiceRegistryId')}
					name="MasterMaterialInvoiceRegistryId"
					labelFieldName="MasterMaterialInvoiceRegistryIdLabel"
				/>
				{/* MasterMaterialInvoiceCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterMaterialInvoiceCategoryId || '',
						label: formData?.MasterMaterialInvoiceCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterMaterialInvoiceCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForMaterialInvoiceCategory1)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterMaterialInvoiceCategoryId'}
					inputLabel={t('ProjectFields.MasterMaterialInvoiceCategoryId')}
					name="MasterMaterialInvoiceCategoryId"
					labelFieldName="MasterMaterialInvoiceCategoryIdLabel"
				/>
				{/* MasterMaterialInvoiceCategory2Id */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterMaterialInvoiceCategory2Id || '',
						label: formData?.MasterMaterialInvoiceCategory2IdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterMaterialInvoiceCategory2Id')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForMaterialInvoiceCategory2)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterMaterialInvoiceCategory2Id'}
					inputLabel={t('ProjectFields.MasterMaterialInvoiceCategory2Id')}
					name="MasterMaterialInvoiceCategory2Id"
					labelFieldName="MasterMaterialInvoiceCategory2IdLabel"
				/>
				<Grid xs={12} sm={6} md={3} lg={3}>
					{/* Empty Item */}
				</Grid>
				{/* MasterCustomCostInvoiceRegistryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCustomCostInvoiceRegistryId || '',
						label: formData?.MasterCustomCostInvoiceRegistryIdLabel || '',
					}}
					onOpen={() => getMasterRegistryData('ProjectFields.MasterCustomCostInvoiceRegistryId')}
					onChange={onAutocompleteChange}
					options={masterRegistryList}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCustomCostInvoiceRegistryId'}
					inputLabel={t('ProjectFields.MasterCustomCostInvoiceRegistryId')}
					name="MasterCustomCostInvoiceRegistryId"
					labelFieldName="MasterCustomCostInvoiceRegistryIdLabel"
				/>
				{/* MasterCustomCostInvoiceCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCustomCostInvoiceCategoryId || '',
						label: formData?.MasterCustomCostInvoiceCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterCustomCostInvoiceCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCustomCostInvoiceCategory1)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCustomCostInvoiceCategoryId'}
					inputLabel={t('ProjectFields.MasterCustomCostInvoiceCategoryId')}
					name="MasterCustomCostInvoiceCategoryId"
					labelFieldName="MasterCustomCostInvoiceCategoryIdLabel"
				/>
				{/* MasterCustomCostInvoiceCategory2Id */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCustomCostInvoiceCategory2Id || '',
						label: formData?.MasterCustomCostInvoiceCategory2IdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterCustomCostInvoiceCategory2Id')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCustomCostInvoiceCategory2)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCustomCostInvoiceCategory2Id'}
					inputLabel={t('ProjectFields.MasterCustomCostInvoiceCategory2Id')}
					name="MasterCustomCostInvoiceCategory2Id"
					labelFieldName="MasterCustomCostInvoiceCategory2IdLabel"
				/>
			</Grid>
			{projectId !== 'draft' && (
				<Box sx={{ mt: '15px' }}>
					<MasterConfiguration projectId={projectId} readOnly={readOnly} />
				</Box>
			)}
		</Box>
	)
}

export default ErpData
