import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import {
	DataGridPremium,
	GridCellParams,
	GridEventListener,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IMeasurementUnit } from 'models'
import { useMeasurementUnitsService } from 'services'

import MeasurementUnitsToolbar from './MeasurementUnitsToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

const MeasurementUnits = () => {
	const [measurementUnitsData, setMeasurementUnitsData] = useState<IMeasurementUnit[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [dataLoading, setDataLoading] = useState(true)

	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)
	const [selectedEntryId, setSelectedEntryId] = useState('')
	const [selectedEntryName, setSelectedEntryName] = useState('')

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['dictionaries', 'translation'])

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { deleteMeasurementUnit, updateMeasurementUnit, getMeasurementUnits, addMeasurementUnit, showSuccessInfo } =
		useMeasurementUnitsService()

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id

		setIsActionButtonDisabled(true)
		setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = measurementUnitsData.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setMeasurementUnitsData(measurementUnitsData.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteMeasurementUnit(id as string)
			setMeasurementUnitsData(measurementUnitsData.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
		setSelectedEntryName(`${params.row.Name}`)
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addMeasurementUnit(newRow as IMeasurementUnit)
				createdRow = response
				setMeasurementUnitsData(measurementUnitsData.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
				getMeasurementUnitsData()
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateMeasurementUnit(newRow.Id, newRow as IMeasurementUnit)
				setMeasurementUnitsData(measurementUnitsData.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getMeasurementUnitsData()
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('MeasurementUnits.Name'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
		{
			field: 'Symbol',
			headerName: t('MeasurementUnits.Symbol'),
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			editable: true,
		},
		{
			field: 'MasterId',
			headerName: t('MeasurementUnits.MasterId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				}

				return [
					<TooltipButton
						title="ChangeHistory.ChangeHistory"
						onClick={handleShowHistory(params)}
						IconComponent={HistoryIcon}
						disabled={isActionButtonDisabled}
					/>,
					<TooltipButton
						title="general.Remove"
						onClick={handleDeleteClick(params.id)}
						IconComponent={DeleteIcon}
						disabled={isActionButtonDisabled}
					/>,
				]
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getMeasurementUnitsData = useCallback(async () => {
		try {
			const response = await getMeasurementUnits()
			setMeasurementUnitsData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getMeasurementUnits])

	useEffect(() => {
		getMeasurementUnitsData()
		getDataGridState(CONTEXT_STATE_NAME)
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={measurementUnitsData}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				onCellDoubleClick={handleCellDoubleClick}
				experimentalFeatures={{ newEditingApi: true }}
				className="shadow-datagrid"
				sx={{ height: 'calc(100vh - 150px)' }}
				components={{
					Toolbar: MeasurementUnitsToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						setIsActionButtonDisabled,
						isActionButtonDisabled,
						setMeasurementUnitsData,
						setRowModesModel,
						apiRef,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={selectedEntryName}
					translationFile="dictionaries"
					translationPath="MeasurementUnits"
				/>
			)}
		</>
	)
}

export default MeasurementUnits
