import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IRoleGeneralPermission, IStageConfiguration, IStageFieldsPermission, IWorkflow } from 'models'

interface CreateWorkflowStage {
	workflowId: string
	name: string
}

export interface ReorderedStages {
	stageId: string
	index: number | string
}

const useWorkflowService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/workflow'
	const stagePath = '/api/workflowStage'

	const getWorkflowList = useCallback(
		async (dataType?: string) => {
			const response = await request(dataType ? `${path}/?filter=["DataType","=","${dataType}"]` : `${path}`)

			return response
		},
		[request]
	)

	const getWorkflowLookupList = useCallback(
		async (dataType?: string) => {
			const response = await request(dataType ? `${path}/lookup/?dataType=${dataType}` : `${path}/lookup`)

			return response
		},
		[request]
	)

	const getWorkflow = useCallback(
		async (workflowId: string) => {
			const response = await request(`${path}/${workflowId}`)

			return response
		},
		[request]
	)

	const updateWorkflow = useCallback(
		async (workflowId: string, data: IWorkflow) => {
			const response = await request(`${path}/${workflowId}`, 'PATCH', data)

			return response
		},
		[request]
	)

	const updateNumerator = useCallback(
		async (workflowId: string, numeratorId: string) => {
			const response = await request(`${path}/numerator/${workflowId}`, 'PATCH', { numeratorId })

			return response
		},
		[request]
	)

	const createWorkflow = useCallback(
		async (data: IWorkflow) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const deleteWorkflow = useCallback(
		async (workflowId: string) => {
			await request(`${path}/${workflowId}`, 'DELETE')
		},
		[request]
	)

	// Roles
	const getAssignedWorkflowRoles = useCallback(
		async (workflowId: string) => {
			const response = await request(`${path}/assignedRoles/${workflowId}`)

			return response
		},
		[request]
	)

	const assignRole = useCallback(
		async (workflowId: string, roleId: string) => {
			const response = await request(`${path}/assign`, 'POST', {
				workflowId,
				roleId,
			})
			return response
		},
		[request]
	)

	const detachRole = useCallback(
		async (workflowId: string, roleId: string) => {
			await request(`${path}/detach?workflowId=${workflowId}&roleId=${roleId}`, 'DELETE')
		},
		[request]
	)

	// Permissions
	const getWorkflowPermissions = useCallback(
		async (workflowId: string, roleId: string) => {
			const response = await request(`${path}/permissions?workflowId=${workflowId}&roleId=${roleId}`)

			return response
		},
		[request]
	)

	const updateWorkflowPermissions = useCallback(
		async (workflowId: string, roleId: string, permissions: IRoleGeneralPermission[]) => {
			const response = await request(`${path}/permissions`, 'PUT', {
				workflowId,
				roleId,
				permissions,
			})
			return response
		},
		[request]
	)

	// Stages
	const getAllWorkflowStagesList = useCallback(async () => {
		const response = await request(`/api/workflowStage/all`)

		return response
	}, [request])

	const getWorkflowStages = useCallback(
		async (workflowId: string) => {
			const response = await request(`/api/workflowStage?workflowId=${workflowId}`)

			return response
		},
		[request]
	)

	const addStage = useCallback(
		async (data: CreateWorkflowStage) => {
			const response = await request(`${stagePath}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateStage = useCallback(
		async (stageId: string, name: string) => {
			const response = await request(`${stagePath}/${stageId}`, 'PATCH', {
				name,
			})
			return response
		},
		[request]
	)

	const reorderStages = useCallback(
		async (workflowId: string, data: ReorderedStages[]) => {
			const response = await request(`${stagePath}/reorder/${workflowId}`, 'PATCH', data)
			return response
		},
		[request]
	)

	const deleteStage = useCallback(
		async (stageId: string) => {
			await request(`${stagePath}/${stageId}`, 'DELETE')
		},
		[request]
	)

	// Actions permissions
	const getStageActionsPermissions = useCallback(
		async (stageId: string, roleId: string) => {
			const response = await request(`${stagePath}/actionPermissions?stageId=${stageId}&roleId=${roleId}`)

			return response
		},
		[request]
	)

	const updateStageActionsPermissions = useCallback(
		async (workflowStageId: string, roleId: string, permissions: IRoleGeneralPermission[]) => {
			const response = await request(`${stagePath}/actionPermissions`, 'PUT', {
				workflowStageId,
				roleId,
				permissions,
			})
			return response
		},
		[request]
	)

	// Stage configuration permissions
	const getStageConfigurationPermissions = useCallback(
		async (stageId: string) => {
			const response = await request(`${stagePath}/configuration/${stageId}`)

			return response
		},
		[request]
	)

	const updateStageConfigurationPermissions = useCallback(
		async (stageId: string, data: IStageConfiguration[]) => {
			const response = await request(`${stagePath}/configuration/${stageId}`, 'PUT', data)
			return response
		},
		[request]
	)

	// Stage fields permissions
	const getStageFieldsPermissions = useCallback(
		async (stageId: string, roleId: string) => {
			const response = await request(`${stagePath}/propertyPermissions?stageId=${stageId}&roleId=${roleId}`)

			return response
		},
		[request]
	)

	const updateFieldsPermissions = useCallback(
		async (workflowStageId: string, roleId: string, permissions: IStageFieldsPermission[]) => {
			const response = await request(`${stagePath}/propertyPermissions`, 'PUT', {
				workflowStageId,
				roleId,
				permissions,
			})
			return response
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getWorkflowList,
		getWorkflowLookupList,
		getAllWorkflowStagesList,
		getWorkflowStages,
		getWorkflow,
		deleteWorkflow,
		createWorkflow,
		updateWorkflow,
		updateNumerator,
		getAssignedWorkflowRoles,
		assignRole,
		getWorkflowPermissions,
		getStageActionsPermissions,
		updateWorkflowPermissions,
		updateStageActionsPermissions,
		getStageConfigurationPermissions,
		updateStageConfigurationPermissions,
		getStageFieldsPermissions,
		updateFieldsPermissions,
		detachRole,
		deleteStage,
		addStage,
		updateStage,
		reorderStages,
	}
}

export default useWorkflowService
