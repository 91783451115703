import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IInquiriesContractor } from 'models'

const useInquiriesService = () => {
	const { request, uploadFileRequest, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/quotationRequestContractor'

	const getQuotationContractors = useCallback(
		async (demandId: string) => {
			const response = await request(`${path}/${demandId}`)
			return response
		},
		[request]
	)

	const addQuotationContractor = useCallback(
		async (data: IInquiriesContractor) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateQuotationContractor = useCallback(
		async (contractorId: string, data: IInquiriesContractor) => {
			const response = await request(`${path}/${contractorId}`, 'PATCH', data)
			return response
		},
		[request]
	)

	const deleteQuotationContractor = useCallback(
		async (contractorId: string) => {
			await request(`${path}/${contractorId}`, 'DELETE')
		},
		[request]
	)

	const importDraftOffer = useCallback(
		async (contractorId: string, formData: any) => {
			const response = await uploadFileRequest(`${path}/importDraftOffer/${contractorId}`, 'PATCH', formData)
			return response
		},
		[uploadFileRequest]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		updateQuotationContractor,
		deleteQuotationContractor,
		getQuotationContractors,
		addQuotationContractor,
		importDraftOffer,
	}
}

export default useInquiriesService
