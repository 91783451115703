import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { ICommonTask } from 'models'

interface Props {
	formData: ICommonTask
}

const Notes = ({ formData }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('common-task')
	const { fieldsError, handleInputChange } = useFormDataChange()

	return (
		<Grid className="mt-1" container rowSpacing={3} columnSpacing={2}>
			{/* Description */}
			<Grid xs={12} sm={12} md={12} lg={12}>
				{isVisible('Description') && (
					<TextField
						disabled={!isEditable('Description')}
						value={formData.Description || ''}
						name="Description"
						label={t('Fields.Description')}
						error={fieldsError.includes('Description')}
						onChange={handleInputChange}
						multiline
						minRows={7}
						fullWidth
					/>
				)}
			</Grid>
		</Grid>
	)
}

export default Notes
