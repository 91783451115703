import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { AutocompleteInput } from 'components/shared'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/pl'
import 'dayjs/locale/uk'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { ICostInvoice, IMasterCategory, IMasterRegistry } from 'models'
import { useMasterCategoryService, useMasterRegistryService } from 'services'
import { formatDateMuiDatePicker, formatDateTimeMUI } from 'utils/formatDateTime'

interface Props {
	formData: ICostInvoice
	onInputChange: (e: any) => void
	onAutocompleteChange: (newValue: any, valueFieldName: string, labelFieldName: string) => void
	fieldErrorsList: string[]
}

const ErpData = ({ formData, onInputChange, onAutocompleteChange, fieldErrorsList }: Props) => {
	const [masterCategories, setMasterCategories] = useState<IMasterCategory[]>([])
	const [masterRegistryList, setMasterRegistryList] = useState<IMasterRegistry[]>([])

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { getMasterCategoryList } = useMasterCategoryService()
	const { getMasterRegistryList } = useMasterRegistryService()
	const { datePickerLanguage } = useLanguage()

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')

	const handleDatePickerChange = (newValue: any) => {
		const e = {
			target: {
				value: formatDateMuiDatePicker(newValue.$d),
				name: 'TaxReturnYearMonth',
			},
		}

		onInputChange(e)
	}

	const getMasterCategoriesData = async (instanceId: string) => {
		if (masterCategories.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterCategoryList()

				setMasterCategories(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	const getMasterRegistryData = async (instanceId: string) => {
		if (masterRegistryList.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterRegistryList()

				setMasterRegistryList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	return (
		<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
			{/* MasterRegistry */}
			{isVisible('MasterRegistry') && (
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData.MasterRegistry || '',
						label: formData.MasterRegistryLabel || '',
					}}
					onOpen={() => getMasterRegistryData('CostInvoice.Fields.MasterRegistry')}
					onChange={onAutocompleteChange}
					options={masterRegistryList}
					readOnly={!isEditable('MasterRegistry')}
					instanceId="CostInvoice.Fields.MasterRegistry"
					inputLabel={t('CostInvoice.Fields.MasterRegistry')}
					name="MasterRegistry"
					labelFieldName="MasterRegistryLabel"
				/>
			)}
			{/* MasterEntryDefaultCategory */}
			{isVisible('MasterEntryDefaultCategory') && (
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData.MasterEntryDefaultCategory || '',
						label: formData.MasterEntryDefaultCategoryLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('CostInvoice.Fields.MasterEntryDefaultCategory')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCostInvoiceCategory1)}
					readOnly={!isEditable('MasterEntryDefaultCategory')}
					instanceId="CostInvoice.Fields.MasterEntryDefaultCategory"
					inputLabel={t('CostInvoice.Fields.MasterEntryDefaultCategory')}
					name="MasterEntryDefaultCategory"
					labelFieldName="MasterEntryDefaultCategoryLabel"
				/>
			)}
			{/* MasterEntryDefaultCategory2 */}
			{isVisible('MasterEntryDefaultCategory2') && (
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData.MasterEntryDefaultCategory2 || '',
						label: formData.MasterEntryDefaultCategory2Label || '',
					}}
					onOpen={() => getMasterCategoriesData('CostInvoice.Fields.MasterEntryDefaultCategory2')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCostInvoiceCategory2)}
					readOnly={!isEditable('MasterEntryDefaultCategory2')}
					instanceId="CostInvoice.Fields.MasterEntryDefaultCategory2"
					inputLabel={t('CostInvoice.Fields.MasterEntryDefaultCategory2')}
					name="MasterEntryDefaultCategory2"
					labelFieldName="MasterEntryDefaultCategory2Label"
				/>
			)}
			{/* TaxLiabilityDate */}
			<Grid xs={12} sm={6} md={3} lg={3}>
				{isVisible('TaxLiabilityDate') && (
					<TextField
						disabled={!isEditable('TaxLiabilityDate')}
						value={formatDateTimeMUI(formData.TaxLiabilityDate) || ''}
						name="TaxLiabilityDate"
						label={t('CostInvoice.Fields.TaxLiabilityDate')}
						InputLabelProps={{ shrink: true }}
						type="date"
						error={fieldErrorsList.includes('TaxLiabilityDate')}
						onChange={onInputChange}
						fullWidth
					/>
				)}
			</Grid>
			{/* TaxReturnYearMonth */}
			<Grid xs={12} sm={6} md={3} lg={3}>
				{isVisible('TaxReturnYearMonth') && (
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={datePickerLanguage}>
						<DatePicker
							disabled={!isEditable('TaxReturnYearMonth')}
							value={dayjs(formData.TaxReturnYearMonth || '')}
							label={t('CostInvoice.Fields.TaxReturnYearMonth')}
							onChange={handleDatePickerChange}
							views={['month', 'year']}
							slotProps={{
								textField: {
									helperText: null,
									fullWidth: true,
									error: false,
								},
							}}
						/>
					</LocalizationProvider>
				)}
			</Grid>
		</Grid>
	)
}

export default ErpData
