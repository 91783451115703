import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material'

import { IWorkflowStage } from 'models'
import { useWorkflowService } from 'services'

import Loading from '../loading/Loading'

interface Props {
	workflowId: string
	activeStage: string
}

const StageDiagram = ({ workflowId, activeStage }: Props) => {
	const [stageList, setStageList] = useState<IWorkflowStage[]>([])
	const [loading, setLoading] = useState(true)

	const { t } = useTranslation()

	const activeStageIndex = stageList.findIndex(el => el.Id === activeStage)

	const { getWorkflowStages } = useWorkflowService()

	const getStageList = useCallback(async () => {
		try {
			const response = await getWorkflowStages(workflowId)
			setStageList(response.sort((a: any, b: any) => a.Index - b.Index))
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}, [workflowId, getWorkflowStages])

	useEffect(() => {
		if (workflowId) {
			getStageList()
		}
	}, [workflowId])

	if (loading) {
		return <Loading />
	}
	return (
		<Box>
			<Typography
				sx={{
					fontWeight: 500,
					fontSize: '20px',
					mb: '10px',
					pl: '40px',
				}}>
				{t('DocumentWorkflowPage.StageDiagram.Title')}
			</Typography>
			<Stepper activeStep={activeStageIndex} orientation="vertical" sx={{ pl: '10px' }}>
				{stageList.map(step => (
					<Step key={step.Id}>
						<StepLabel>{step.Name}</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
	)
}

export default StageDiagram
