import { GridInitialState } from '@mui/x-data-grid-premium'

export const INVESTOR_BUDGET_INITIALSTATE: GridInitialState = {
	aggregation: {
		model: {
			Budget: 'sum',
			Incomes: 'sum',
			CostsSum: 'sum',
			SubcontractorsContractsValue: 'sum',
			SubcontractorsExecutionValue: 'sum',
			SubcontractorsCostsValue: 'sum',
			EquipmentContractsValue: 'sum',
			EquipmentCostsValue: 'sum',
			MaterialContractsValue: 'sum',
			MaterialCostsValue: 'sum',
			GeneralCostsValue: 'sum',
			Plan: 'sum',
			ExecutionPlan: 'sum',
			Execution: 'sum',
		},
	},

	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: ['__check__', '__tree_data_group__', 'Description'],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			__check__: true,
			__tree_data_group__: true,
			Description: true,
			MeasurementUnitId: true,
			Amount: true,
			Price: true,
			VatRateId: true,
			Budget: true,
			Execution: true,
			ExecutionStatusPerc: true,
			actions: true,
		},
		orderedFields: [
			'__check__',
			'__tree_data_group__',
			'Description',
			'MeasurementUnitId',
			'Amount',
			'Price',
			'VatRateId',
			'Budget',
			'Execution',
			'ExecutionStatusPerc',
			'actions',
		],
		dimensions: {
			Description: {
				maxWidth: -1,
				minWidth: 50,
				width: 400,
				flex: 0,
			},
			MeasurementUnitId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			Price: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			VatRateId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Budget: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Execution: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ExecutionStatusPerc: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 80,
			},
		},
	},

	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [
			{
				field: '__tree_data_group__',
				sort: 'asc',
			},
		],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const INVESTOR_BUDGET_CONTEXT_STATE_NAME = 'investorBudgetDataGridState'
