import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentFormSaveButton, FormSkeletonLoading } from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import { useAppSelector, useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features'
import { useFormDataChange } from 'hooks'
import { IMaterialInvoice } from 'models'
import { useMaterialInvoiceService } from 'services'

import MaterialInvoiceForm from './MaterialInvoiceForm'

interface Props {
	invoiceId: string
}

const MaterialInvoiceBasicInfo = ({ invoiceId }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)

	const { setDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { handleInputChange, handleCheckboxChange, handleAutocompleteInputChange, fieldsError } = useFormDataChange()
	const { getMaterialInvoice, updateMaterialInvoice } = useMaterialInvoiceService()

	const { t } = useTranslation()

	const refreshDocumentData = async () => {
		try {
			const response = await getMaterialInvoice(invoiceId)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [invoiceId])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={14} />
	}

	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
						documentId={invoiceId}
					/>
				</div>
				<div>
					<ChangeHistory
						contextId={invoiceId}
						translationFile="invoice"
						title={`${t('MaterialInvoice', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="MaterialInvoice.Fields"
					/>
					<DocumentFormSaveButton
						documentId={invoiceId}
						data={documentData}
						updateDataFunction={updateMaterialInvoice}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<MaterialInvoiceForm
					formData={documentData as IMaterialInvoice}
					invoiceId={invoiceId}
					onInputChange={handleInputChange}
					onCheckboxChange={handleCheckboxChange}
					onAutocompleteChange={handleAutocompleteInputChange}
					fieldErrorsList={fieldsError}
				/>
			</div>
		</>
	)
}

export default MaterialInvoiceBasicInfo
