import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useOfferEntriesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/OfferEntry'

	const getOfferEntries = useCallback(
		async (offerId: string, treeData: boolean) => {
			const response = await request(`${path}?getTree=${treeData}&OfferId=${offerId}`)
			return response
		},
		[request]
	)

	const updateOfferEntry = useCallback(
		async (entryId: string, data: any) => {
			await request(`${path}/${entryId}`, 'PATCH', data)
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getOfferEntries,
		updateOfferEntry,
	}
}

export default useOfferEntriesService
