import { ChangeEventHandler, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Checkbox, FormControlLabel, InputAdornment, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import {
	AutocompleteInput,
	AutocompleteInputOption,
	ContactPersons,
	ContractorForm,
	InputLinkButton,
} from 'components/shared'
import { PATHS } from 'data'
import { useAppSelector, useAutocompleteInputActions, useDocumentDataActions } from 'features'
import { useFieldsPermissions } from 'hooks'
import { IAdvanceInvoice, IPaymentMethod } from 'models'
import { ADVANCE_INVOICE_ENDPOINT, usePaymentMethodService } from 'services'
import { formatDateTimeMUI } from 'utils/formatDateTime'

import { ErpData, Notes, Values } from './form-tabs'

interface Props {
	invoiceId: string
	formData: IAdvanceInvoice
	onInputChange: (e: any) => void
	onAutocompleteChange: (newValue: AutocompleteInputOption, valueFieldName: string, labelFieldName: string) => void
	onCheckboxChange: (e: any) => void
	fieldErrorsList: string[]
}

const AdvanceInvoiceForm = ({
	invoiceId,
	formData,
	onInputChange,
	onAutocompleteChange,
	onCheckboxChange,
	fieldErrorsList,
}: Props) => {
	const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([])
	const [selectedTab, setSelectedTab] = useState('0')

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { updateDocumentData } = useDocumentDataActions()
	const { getPaymentMethods } = usePaymentMethodService()

	const handleDoubleFieldsDataChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
		onInputChange(e)

		const target = e.target as HTMLInputElement

		const value = target.value
		const fieldName = target.name === 'SaleDate' ? 'TaxLiabilityDate' : 'TaxReturnYearMonth'

		updateDocumentData([{ fieldName, value }])
	}

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('advanceInvoiceFormSelectedTab', newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('AdvanceInvoice.BasicInformationFormTabs.Value'),
				renderValue: <Values formData={formData} onInputChange={onInputChange} fieldErrorsList={fieldErrorsList} />,
				isVisible: true,
			},
			{
				label: t('AdvanceInvoice.BasicInformationFormTabs.Notes'),
				renderValue: <Notes formData={formData} onInputChange={onInputChange} fieldErrorsList={fieldErrorsList} />,
				isVisible: true,
			},
			{
				label: t('AdvanceInvoice.BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons
						documentId={invoiceId}
						contractorId={formData.Contractor || ''}
						endpoint={ADVANCE_INVOICE_ENDPOINT}
					/>
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
			{
				label: t('AdvanceInvoice.BasicInformationFormTabs.ERP'),
				renderValue: (
					<ErpData
						formData={formData}
						onInputChange={onInputChange}
						onAutocompleteChange={onAutocompleteChange}
						fieldErrorsList={fieldErrorsList}
					/>
				),
				isVisible: true,
			},
		],
		[t, formData]
	)

	const getPaymentMethodsData = async (instanceId: string) => {
		if (paymentMethods.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getPaymentMethods()

				setPaymentMethods(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	useEffect(() => {
		const savedTab = localStorage.getItem('advanceInvoiceFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('AdvanceInvoice.Fields.Number')}
								error={fieldErrorsList.includes('Number')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={!isEditable('DocumentDate')}
								value={formData.DocumentDate || ''}
								name="DocumentDate"
								label={t('AdvanceInvoice.Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('DocumentDate')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('AdvanceInvoice.Fields.StageId')}
							fullWidth
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('AdvanceInvoice.Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('AdvanceInvoice.Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Contract */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Contract') && (
							<TextField
								disabled={true}
								value={formData.ContractLabel || ''}
								name="Contract"
								label={t('AdvanceInvoice.Fields.Contract')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.contract}
												documentId={formData.Contract}
												documentLabel={formData.ContractLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ContractProformaInvoice */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ContractProformaInvoice') && (
							<TextField
								disabled={true}
								value={formData.ContractProformaInvoiceLabel || ''}
								name="ContractProformaInvoice"
								label={t('AdvanceInvoice.Fields.ContractProformaInvoice')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.contractProformaInvoice}
												documentId={formData.ContractProformaInvoice}
												documentLabel={formData.ContractProformaInvoiceLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Order */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Order') && (
							<TextField
								disabled={true}
								value={formData.OrderLabel || ''}
								name="Order"
								label={t('AdvanceInvoice.Fields.Order')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.order}
												documentId={formData.Order}
												documentLabel={formData.OrderLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ForeignNumber  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ForeignNumber') && (
							<TextField
								disabled={!isEditable('ForeignNumber')}
								value={formData.ForeignNumber || ''}
								name="ForeignNumber"
								label={t('AdvanceInvoice.Fields.ForeignNumber')}
								error={fieldErrorsList.includes('ForeignNumber')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* MasterNumber  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('MasterNumber') && (
							<TextField
								disabled={!isEditable('MasterNumber')}
								value={formData.MasterNumber || ''}
								name="MasterNumber"
								label={t('AdvanceInvoice.Fields.MasterNumber')}
								error={fieldErrorsList.includes('MasterNumber')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* ReceiptDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ReceiptDate') && (
							<TextField
								disabled={!isEditable('ReceiptDate')}
								value={formatDateTimeMUI(formData.ReceiptDate) || ''}
								name="ReceiptDate"
								label={t('AdvanceInvoice.Fields.ReceiptDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('ReceiptDate')}
								onChange={handleDoubleFieldsDataChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* IssueDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('IssueDate') && (
							<TextField
								disabled={!isEditable('IssueDate')}
								value={formatDateTimeMUI(formData.IssueDate) || ''}
								name="IssueDate"
								label={t('AdvanceInvoice.Fields.IssueDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('IssueDate')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* SaleDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('SaleDate') && (
							<TextField
								disabled={!isEditable('SaleDate')}
								value={formatDateTimeMUI(formData.SaleDate) || ''}
								name="SaleDate"
								label={t('AdvanceInvoice.Fields.SaleDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('SaleDate')}
								onChange={handleDoubleFieldsDataChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* PaymentDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('PaymentDate') && (
							<TextField
								disabled={!isEditable('PaymentDate')}
								value={formatDateTimeMUI(formData.PaymentDate) || ''}
								name="PaymentDate"
								label={t('AdvanceInvoice.Fields.PaymentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('PaymentDate')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* PaymentMethod */}
					{isVisible('PaymentMethod') && (
						<AutocompleteInput
							dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
							initialValue={{
								value: formData.PaymentMethod || '',
								label: formData.PaymentMethodLabel || '',
							}}
							onOpen={() => getPaymentMethodsData('AdvanceInvoice.Fields.PaymentMethod')}
							onChange={onAutocompleteChange}
							options={paymentMethods}
							readOnly={!isEditable('PaymentMethod')}
							instanceId="AdvanceInvoice.Fields.PaymentMethod"
							inputLabel={t('AdvanceInvoice.Fields.PaymentMethod')}
							name="PaymentMethod"
							labelFieldName="PaymentMethodLabel"
						/>
					)}
					{/* SplitPayment */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<FormControlLabel
							control={
								<Checkbox
									disabled={!isEditable('SplitPayment')}
									checked={formData?.SplitPayment || false}
									onChange={onCheckboxChange}
									name="SplitPayment"
								/>
							}
							label={t('AdvanceInvoice.Fields.SplitPayment')}
						/>
					</Grid>
				</Grid>
			</div>
			<ContractorForm
				documentId={invoiceId}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
				showContractorBankAccount
				contractorBankAccountProps={{
					readOnly: !isEditable('ContractorBankAccount'),
					label: formData.ContractorBankAccountLabel,
					value: formData.ContractorBankAccount,
				}}
			/>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel className="py-2.5 px-0" key={index} value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default AdvanceInvoiceForm
