import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { ICurrency, ISalesInvoice } from 'models'
import { useCurrencyService } from 'services'
import { FormatNumberInput } from 'utils/FormatNumberInput'

interface Props {
	formData: ISalesInvoice
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const Values = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const [currencyList, setCurrencyList] = useState<ICurrency[]>([])

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')

	const { handleAutocompleteInputChange } = useFormDataChange()
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { getCurrency } = useCurrencyService()

	const getCurrencyData = async (instanceId: string) => {
		if (currencyList.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getCurrency()
				setCurrencyList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	return (
		<div className="w-full pb-4">
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* Currency  */}
				{isVisible('Currency') && (
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						order={{ md: 2, lg: 2 }}
						initialValue={{
							value: formData.Currency || '',
							label: formData.CurrencyLabel || '',
						}}
						onOpen={() => getCurrencyData('SalesInvoice.Fields.Currency')}
						onChange={handleAutocompleteInputChange}
						options={currencyList}
						readOnly={!isEditable('Currency')}
						instanceId="SalesInvoice.Fields.Currency"
						inputLabel={t('SalesInvoice.Fields.Currency')}
						name="Currency"
						labelFieldName="CurrencyLabel"
					/>
				)}
				{/* ExchangeRate  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 3, lg: 3 }}>
					{isVisible('ExchangeRate') && (
						<TextField
							disabled={!isEditable('ExchangeRate')}
							value={formData.ExchangeRate || ''}
							name="ExchangeRate"
							label={t('SalesInvoice.Fields.ExchangeRate')}
							error={fieldErrorsList.includes('ExchangeRate')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueNetto  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 0, lg: 0 }}>
					{isVisible('ValueNetto') && (
						<TextField
							disabled={!isEditable('ValueNetto')}
							value={formData.ValueNetto || ''}
							name="ValueNetto"
							label={t('SalesInvoice.Fields.ValueNetto')}
							error={fieldErrorsList.includes('ValueNetto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueVAT  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 1, lg: 1 }}>
					{isVisible('ValueVAT') && (
						<TextField
							disabled={!isEditable('ValueVAT')}
							value={formData.ValueVAT || ''}
							name="ValueVAT"
							label={t('SalesInvoice.Fields.ValueVAT')}
							error={fieldErrorsList.includes('ValueVAT')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueBrutto  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 4, lg: 4 }}>
					{isVisible('ValueBrutto') && (
						<TextField
							disabled={!isEditable('ValueBrutto')}
							value={formData.ValueBrutto || ''}
							name="ValueBrutto"
							label={t('SalesInvoice.Fields.ValueBrutto')}
							error={fieldErrorsList.includes('ValueBrutto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueBruttoPLN  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 5, lg: 5 }}>
					{isVisible('ValueBruttoPLN') && (
						<TextField
							disabled={!isEditable('ValueBruttoPLN')}
							value={formData.ValueBruttoPLN || ''}
							name="ValueBruttoPLN"
							label={t('SalesInvoice.Fields.ValueBruttoPLN')}
							error={fieldErrorsList.includes('ValueBruttoPLN')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ProcessingProtocolValue  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 5, lg: 5 }}>
					{isVisible('ProcessingProtocolValue') && (
						<TextField
							disabled={!isEditable('ProcessingProtocolValue')}
							value={formData.ProcessingProtocolValue || ''}
							name="ProcessingProtocolValue"
							label={t('SalesInvoice.Fields.ProcessingProtocolValue')}
							error={fieldErrorsList.includes('ProcessingProtocolValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* Description */}
				<Grid xs={12} sm={12} md={12} lg={12} order={{ md: 15, lg: 15 }}>
					{isVisible('Description') && (
						<TextField
							disabled={!isEditable('Description')}
							value={formData.Description || ''}
							name="Description"
							label={t('SalesInvoice.Fields.Description')}
							error={fieldErrorsList.includes('Description')}
							onChange={onInputChange}
							multiline
							minRows={4}
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Values
