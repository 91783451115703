import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { ContactPersons, ContractorForm, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { useFieldsPermissions } from 'hooks'
import { IProformaInvoice } from 'models'
import { PROFORMA_INVOICE_ENDPOINT } from 'services'
import { formatDateTimeMUI } from 'utils/formatDateTime'

import Notes from './form-tabs/Notes'
import Values from './form-tabs/Values'

interface Props {
	invoiceId: string
	formData: IProformaInvoice
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const ProformaInvoiceForm = ({ invoiceId, formData, onInputChange, fieldErrorsList }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('proformaInvoiceFormSelectedTab', newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('ProformaInvoice.BasicInformationFormTabs.Value'),
				renderValue: <Values formData={formData} onInputChange={onInputChange} fieldErrorsList={fieldErrorsList} />,
				isVisible: true,
			},
			{
				label: t('ProformaInvoice.BasicInformationFormTabs.Notes'),
				renderValue: <Notes formData={formData} onInputChange={onInputChange} fieldErrorsList={fieldErrorsList} />,
				isVisible: true,
			},
			{
				label: t('ProformaInvoice.BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons
						documentId={invoiceId}
						contractorId={formData.Contractor || ''}
						endpoint={PROFORMA_INVOICE_ENDPOINT}
					/>
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
		],
		[t, formData]
	)

	useEffect(() => {
		const savedTab = localStorage.getItem('proformaInvoiceFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('ProformaInvoice.Fields.Number')}
								error={fieldErrorsList.includes('Number')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={!isEditable('DocumentDate')}
								value={formData.DocumentDate || ''}
								name="DocumentDate"
								label={t('ProformaInvoice.Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('DocumentDate')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('ProformaInvoice.Fields.StageId')}
							fullWidth
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('ProformaInvoice.Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('ProformaInvoice.Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Contract */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Contract') && (
							<TextField
								disabled={true}
								value={formData.ContractLabel || ''}
								name="Contract"
								label={t('ProformaInvoice.Fields.Contract')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.contract}
												documentId={formData.Contract}
												documentLabel={formData.ContractLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ContractAdvanceInvoice */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ContractAdvanceInvoice') && (
							<TextField
								disabled={true}
								value={formData.ContractAdvanceInvoiceLabel || ''}
								name="ContractAdvanceInvoice"
								label={t('ProformaInvoice.Fields.ContractAdvanceInvoice')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.contractAdvanceInvoice}
												documentId={formData.ContractAdvanceInvoice}
												documentLabel={formData.ContractAdvanceInvoiceLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Order */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Order') && (
							<TextField
								disabled={true}
								value={formData.OrderLabel || ''}
								name="Order"
								label={t('ProformaInvoice.Fields.Order')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.order}
												documentId={formData.Order}
												documentLabel={formData.OrderLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ForeignNumber  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ForeignNumber') && (
							<TextField
								disabled={!isEditable('ForeignNumber')}
								value={formData.ForeignNumber || ''}
								name="ForeignNumber"
								label={t('ProformaInvoice.Fields.ForeignNumber')}
								error={fieldErrorsList.includes('ForeignNumber')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* ReceiptDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ReceiptDate') && (
							<TextField
								disabled={!isEditable('ReceiptDate')}
								value={formatDateTimeMUI(formData.ReceiptDate) || ''}
								name="ReceiptDate"
								label={t('ProformaInvoice.Fields.ReceiptDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('ReceiptDate')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* IssueDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('IssueDate') && (
							<TextField
								disabled={!isEditable('IssueDate')}
								value={formatDateTimeMUI(formData.IssueDate) || ''}
								name="IssueDate"
								label={t('ProformaInvoice.Fields.IssueDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('IssueDate')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* SaleDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('SaleDate') && (
							<TextField
								disabled={!isEditable('SaleDate')}
								value={formatDateTimeMUI(formData.SaleDate) || ''}
								name="SaleDate"
								label={t('ProformaInvoice.Fields.SaleDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('SaleDate')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
				</Grid>
			</div>
			<ContractorForm
				documentId={invoiceId}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
			/>
			<div className="w-full">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel className="py-2.5 px-0" key={index} value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default ProformaInvoiceForm
