import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IIncomingLetter } from 'models'

interface Props {
	formData: IIncomingLetter
}

const Notes = ({ formData }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('incoming-letter')
	const { fieldsError, handleInputChange } = useFormDataChange()

	return (
		<div>
			<Grid className="mt-1" container rowSpacing={3} columnSpacing={2}>
				{/* Description */}
				<Grid xs={12} sm={12} md={6} lg={6}>
					{isVisible('Description') && (
						<TextField
							disabled={!isEditable('Description')}
							value={formData.Description || ''}
							name="Description"
							label={t('Fields.Description')}
							error={fieldsError.includes('Description')}
							onChange={handleInputChange}
							multiline
							minRows={3}
							fullWidth
						/>
					)}
				</Grid>
				{/* Comments */}
				<Grid xs={12} sm={12} md={6} lg={6}>
					{isVisible('Comments') && (
						<TextField
							disabled={!isEditable('Comments')}
							value={formData.Comments || ''}
							name="Comments"
							label={t('Fields.Comments')}
							error={fieldsError.includes('Comments')}
							onChange={handleInputChange}
							multiline
							minRows={3}
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Notes
