import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import LinkIcon from '@mui/icons-material/Link'
import { IconButton, Tooltip } from '@mui/material'

import { DocumentFormSaveButton, FormSkeletonLoading } from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import { useAppSelector, useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features'
import { useFormDataChange, useLinkDocuments } from 'hooks'
import { IProformaInvoice } from 'models'
import { useProformaInvoiceService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import ProformaInvoiceForm from './ProformaInvoiceForm'

interface Props {
	invoiceId: string
}

const ProformaInvoiceBasicInfo = ({ invoiceId }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)

	const { handleCopyDocumentData } = useLinkDocuments()
	const { setDocumentData, updateLoading } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { handleInputChange, fieldsError } = useFormDataChange()
	const { getProformaInvoice, updateProformaInvoice } = useProformaInvoiceService()

	const { t } = useTranslation()

	const handleCopyDataForLinking = () => {
		handleCopyDocumentData({
			id: documentData?.Id as string,
			name: `${t('ContractProformaInvoice', { ns: 'routes' })} ${documentData?.Number}`,
			documentType: 'ProformaInvoice',
			otherData: documentData,
		})
	}

	const refreshDocumentData = async () => {
		try {
			const response = await getProformaInvoice(invoiceId)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
		updateLoading(false)
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [invoiceId])

	if (formLoading) {
		return <FormSkeletonLoading />
	}

	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
					/>
				</div>
				<div>
					<Tooltip title={<TooltipTextWrapper title={t('LinkDocumentClipboard.CopyId')} />} placement="top" arrow>
						<IconButton onClick={() => handleCopyDataForLinking()}>
							<LinkIcon color="primary" />
						</IconButton>
					</Tooltip>
					<ChangeHistory
						contextId={invoiceId}
						translationFile="invoice"
						title={`${t('ContractProformaInvoice', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="ProformaInvoice.Fields"
					/>
					<DocumentFormSaveButton
						documentId={invoiceId}
						data={documentData}
						updateDataFunction={updateProformaInvoice}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<ProformaInvoiceForm
					formData={documentData as IProformaInvoice}
					invoiceId={invoiceId}
					onInputChange={handleInputChange}
					fieldErrorsList={fieldsError}
				/>
			</div>
		</>
	)
}

export default ProformaInvoiceBasicInfo
