export const PATHS = {
	project: '/project',
	workflow: '/workflow',
	costInvoice: '/costInvoice',
	materialInvoice: '/materialInvoice',
	customCostInvoice: '/customCostInvoice',
	contractAdvanceInvoice: '/contractAdvanceInvoices',
	contractProformaInvoice: '/contractProformaInvoice',
	contractSalesInvoice: '/contractSalesInvoice',
	salesInvoice: '/salesInvoice',
	debitNote: '/debitNote',
	offer: '/offer',
	incomingLetter: '/incomingLetter',
	investorProcessingProtocol: '/investorProcessingProtocol',
	processingProtocol: '/processingProtocol',
	goodsReceivedNote: '/goodsReceivedNote',
	contract: '/contract',
	order: '/order',
	demand: '/demand',
	correctionInvoice: '/correctionInvoice',
}
