import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentFormSaveButton, FormSkeletonLoading } from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import { useAppSelector, useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features'
import { useFormDataChange } from 'hooks'
import { IContract } from 'models'
import { useContractService } from 'services'

import ContractForm from './ContractForm'

interface Props {
	contractId: string
}

const ContractBasicInfo = ({ contractId }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)

	const { setDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { handleInputChange, fieldsError } = useFormDataChange()
	const { getContract, updateContract } = useContractService()

	const { t } = useTranslation()

	const refreshDocumentData = async () => {
		try {
			const response = await getContract(contractId)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [contractId])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={8} tabs={7} />
	}

	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
					/>
				</div>
				<div>
					<ChangeHistory
						contextId={contractId}
						translationFile="contract"
						title={`${t('Contract', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="Fields"
					/>
					<DocumentFormSaveButton
						documentId={contractId}
						data={documentData}
						updateDataFunction={updateContract}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<ContractForm
					formData={documentData as IContract}
					contractId={contractId}
					onInputChange={handleInputChange}
					reloadFormData={refreshDocumentData}
					fieldErrorsList={fieldsError}
				/>
			</div>
		</>
	)
}

export default ContractBasicInfo
