import { useTranslation } from 'react-i18next'

import CalculateIcon from '@mui/icons-material/Calculate'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import RecommendIcon from '@mui/icons-material/Recommend'
import { Box, Stack, Tooltip } from '@mui/material'

import TooltipTextWrapper from 'utils/TooltipTextWrapper'

interface Props {
	start: boolean | undefined
	completeEnabled: boolean | undefined
	markCalculation: boolean | undefined
}

const StageStatus = ({ start, completeEnabled, markCalculation }: Props) => {
	const { t } = useTranslation('administration')

	return (
		<Box sx={sxStyles.container}>
			{start && (
				<Tooltip title={<TooltipTextWrapper title={t('Workflow.StageStatus.Start')} />} placement="top" arrow>
					<Stack sx={{ mr: '8px' }}>
						<PlayArrowIcon fontSize="medium" color="warning" />
					</Stack>
				</Tooltip>
			)}
			{markCalculation && (
				<Tooltip title={<TooltipTextWrapper title={t('Workflow.StageStatus.MarkCalculation')} />} placement="top" arrow>
					<Stack sx={{ mr: '8px' }}>
						<CalculateIcon fontSize="medium" color="info" />
					</Stack>
				</Tooltip>
			)}
			{completeEnabled && (
				<Tooltip title={<TooltipTextWrapper title={t('Workflow.StageStatus.CompleteEnabled')} />} placement="top" arrow>
					<Stack sx={{ mr: '8px' }}>
						<RecommendIcon fontSize="medium" color="success" />
					</Stack>
				</Tooltip>
			)}
		</Box>
	)
}

export default StageStatus

const sxStyles = {
	container: {
		display: 'flex',
		justifyContent: 'flex',
		alignItems: 'center',
	},
}
