import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import {
	DataGridPremium,
	GridCellParams,
	GridEventListener,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IProformaInvoiceEntry } from 'models'
import {
	useCurrencyService,
	useMeasurementUnitsService,
	useProformaInvoiceEntriesService,
	useVatRateService,
} from 'services'
import formatAmountNumberDataGrid from 'utils/formatAmountNumberDataGrid'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import ProformaInvoiceEntriesToolbar from './ProformaInvoiceEntriesToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	invoiceId: string
	readOnly: boolean
}

const ProformaInvoiceEntries = ({ invoiceId, readOnly }: Props) => {
	const [entriesData, setEntriesData] = useState<IProformaInvoiceEntry[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [measurementUnits, setMeasurementUnits] = useState<any[]>([])
	const [currencyList, setCurrencyList] = useState<any[]>([])
	const [vatRateList, setVatRateList] = useState<any[]>([])
	const [dataLoading, setDataLoading] = useState(true)

	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)
	const [changeHistoryDialogData, setChangeHistoryDialogData] = useState<IProformaInvoiceEntry>()
	const [selectedEntryId, setSelectedEntryId] = useState('')

	const { t } = useTranslation(['invoice', 'translation'])

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()
	const { getMeasurementUnitsLookup } = useMeasurementUnitsService()
	const { getCurrency } = useCurrencyService()
	const { getVatRate } = useVatRateService()

	const { dataGridLanguage } = useLanguage()
	const { getEntries, updateEntry, addEntry, deleteEntry, showSuccessInfo } = useProformaInvoiceEntriesService()

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id
		const isEditable = !readOnly

		if (isEditable) setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = entriesData.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setEntriesData(entriesData.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteEntry(id as string)
			setEntriesData(entriesData.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
		setChangeHistoryDialogData(params.row)
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addEntry({
					...newRow,
					MeasurementUnitId: newRow.MeasurementUnitId || null,
					VatRateId: newRow.VatRateId || null,
				} as IProformaInvoiceEntry)
				createdRow = response
				setEntriesData(entriesData.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
				getData()
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateEntry(newRow.Id, newRow as IProformaInvoiceEntry)
				setEntriesData(entriesData.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getData()
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('ProformaInvoice.Entries.Name'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
		},
		{
			field: 'MeasurementUnitId',
			headerName: t('ProformaInvoice.Entries.MeasurementUnitId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.MeasurementUnitId || ''
			},
			valueOptions: measurementUnits,
			valueFormatter: ({ value }: any) => {
				const option = measurementUnits.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'CurrencyId',
			headerName: t('ProformaInvoice.Entries.CurrencyId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.CurrencyId || ''
			},
			valueOptions: currencyList,
			valueFormatter: ({ value }: any) => {
				const option = currencyList.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'Amount',
			headerName: t('ProformaInvoice.Entries.Amount'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatAmountNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'PriceNetto',
			headerName: t('ProformaInvoice.Entries.PriceNetto'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'PriceNettoPLN',
			headerName: t('ProformaInvoice.Entries.PriceNettoPLN'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'VatRateId',
			headerName: t('ProformaInvoice.Entries.VatRateId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.VatRateId || ''
			},
			valueOptions: vatRateList,
			valueFormatter: ({ value }: any) => {
				const option = vatRateList.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'ValueNetto',
			headerName: t('ProformaInvoice.Entries.ValueNetto'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'ValueNettoPLN',
			headerName: t('ProformaInvoice.Entries.ValueNettoPLN'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'ValueVat',
			headerName: t('ProformaInvoice.Entries.ValueVat'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'ValueVatPLN',
			headerName: t('ProformaInvoice.Entries.ValueVatPLN'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'ValueBrutto',
			headerName: t('ProformaInvoice.Entries.ValueBrutto'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'ValueBruttoPLN',
			headerName: t('ProformaInvoice.Entries.ValueBruttoPLN'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				} else {
					return [
						<TooltipButton
							title="general.Remove"
							onClick={handleDeleteClick(params.id)}
							IconComponent={DeleteIcon}
							disabled={isActionButtonDisabled || readOnly}
						/>,
						<TooltipButton
							title="ChangeHistory.ChangeHistory"
							onClick={handleShowHistory(params)}
							IconComponent={HistoryIcon}
							disabled={isActionButtonDisabled}
						/>,
					]
				}
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = useCallback(async () => {
		try {
			const response = await getEntries(invoiceId)
			setEntriesData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getEntries, invoiceId])

	const getMeasurementUnitsList = useCallback(async () => {
		try {
			const response = await getMeasurementUnitsLookup()
			setMeasurementUnits(response)
		} catch (err) {
			console.error(err)
		}
	}, [getMeasurementUnitsLookup])

	const getVatRateList = useCallback(async () => {
		try {
			const response = await getVatRate()
			setVatRateList(response.map((el: any) => ({ value: el.Id, label: el.Name })))
		} catch (err) {
			console.error(err)
		}
	}, [getVatRate])

	const getCurrencyList = useCallback(async () => {
		try {
			const response = await getCurrency()
			setCurrencyList(response.map((el: any) => ({ value: el.Id, label: el.Name })))
		} catch (err) {
			console.error(err)
		}
	}, [getCurrency])

	const apiRef = useGridApiRef()

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getMeasurementUnitsList()
		getCurrencyList()
		getVatRateList()
	}, [])

	useEffect(() => {
		getData()
	}, [invoiceId])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState, apiRef])

	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={entriesData}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				className="shadow-datagrid"
				sx={{ minHeight: '500px', height: 'calc(100vh - 250px)', border: 'none' }}
				onCellDoubleClick={handleCellDoubleClick}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				experimentalFeatures={{ newEditingApi: true }}
				components={{
					Toolbar: ProformaInvoiceEntriesToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						isActionButtonDisabled,
						setIsActionButtonDisabled,
						setEntriesData,
						setRowModesModel,
						readOnly,
						invoiceId,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={changeHistoryDialogData?.Name as string}
					translationFile="invoice"
					translationPath="ProformaInvoice.Entries"
				/>
			)}
		</>
	)
}

export default ProformaInvoiceEntries
