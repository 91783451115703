import { Box, CircularProgress } from '@mui/material'

interface Props {
	fullScreen?: boolean
}

const Loading = ({ fullScreen }: Props) => {
	return (
		<Box sx={{ ...sxStyles.container, height: fullScreen ? '100vh' : '100%' }}>
			<CircularProgress />
		</Box>
	)
}

export default Loading

const sxStyles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '30px 0',
	},
}
