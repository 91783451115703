import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, MenuItem, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { ContactPersons, ContractorForm, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { contractContractType, contractSettlementType } from 'data/lookup-data-sources'
import { useFieldsPermissions } from 'hooks'
import { IContract } from 'models'
import { CONTRACT_ENDPOINT } from 'services'
import { formatDateTimeMUI } from 'utils/formatDateTime'

import {
	AdditionalCosts,
	Deposits,
	Details,
	ErpData,
	GeneralTermsModification,
	SettlementsAndParticipations,
	TradingParameters,
	Warranty,
} from './form-tabs'

interface Props {
	contractId: string
	formData: IContract
	onInputChange: (e: any) => void
	reloadFormData: () => void
	fieldErrorsList: string[]
}

const ContractForm = ({ contractId, formData, onInputChange, reloadFormData, fieldErrorsList }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('contract')

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('contractFormSelectedTab', newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('BasicInformationFormTabs.TradingParameters'),
				renderValue: (
					<TradingParameters formData={formData} onInputChange={onInputChange} fieldErrorsList={fieldErrorsList} />
				),
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.ContractDetails'),
				renderValue: <Details formData={formData} onInputChange={onInputChange} fieldErrorsList={fieldErrorsList} />,
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.GeneralTermsModification'),
				renderValue: (
					<GeneralTermsModification
						formData={formData}
						onInputChange={onInputChange}
						fieldErrorsList={fieldErrorsList}
					/>
				),
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.SettlementsAndParticipations'),
				renderValue: (
					<SettlementsAndParticipations
						formData={formData}
						onInputChange={onInputChange}
						fieldErrorsList={fieldErrorsList}
					/>
				),
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.Deposits'),
				renderValue: <Deposits formData={formData} onInputChange={onInputChange} fieldErrorsList={fieldErrorsList} />,
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.AdditionalCosts'),
				renderValue: (
					<AdditionalCosts
						contractId={contractId}
						reloadFormData={reloadFormData}
						readOnly={!isEditable('AdditionalCosts')}
					/>
				),
				isVisible: isVisible('AdditionalCosts'),
			},
			{
				label: t('BasicInformationFormTabs.Guarantees'),
				renderValue: (
					<Warranty contractId={contractId} reloadFormData={reloadFormData} readOnly={!isEditable('Waranties')} />
				),
				isVisible: isVisible('Waranties'),
			},
			{
				label: t('BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons documentId={contractId} contractorId={formData.Contractor} endpoint={CONTRACT_ENDPOINT} />
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
			{
				label: t('BasicInformationFormTabs.ERP'),
				renderValue: <ErpData formData={formData} onInputChange={onInputChange} fieldErrorsList={fieldErrorsList} />,
				isVisible: true,
			},
		],
		[t, formData]
	)

	useEffect(() => {
		const savedTab = localStorage.getItem('contractFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								id="contract-field-Number"
								name="Number"
								label={t('Fields.Number')}
								error={fieldErrorsList.includes('Number')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* DocumentCreatedDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentCreatedDate') && (
							<TextField
								disabled={!isEditable('DocumentCreatedDate')}
								value={formatDateTimeMUI(formData.DocumentCreatedDate) || ''}
								id="contract-field-DocumentCreatedDate"
								name="DocumentCreatedDate"
								label={t('Fields.DocumentCreatedDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('DocumentCreatedDate')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* ConclusionDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ConclusionDate') && (
							<TextField
								disabled={!isEditable('ConclusionDate')}
								value={formatDateTimeMUI(formData.ConclusionDate) || ''}
								id="contract-field-ConclusionDate"
								name="ConclusionDate"
								label={t('Fields.ConclusionDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('ConclusionDate')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* Stage */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Offer */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Offer') && (
							<TextField
								disabled={true}
								value={formData.OfferLabel || ''}
								name="Offer"
								label={t('Fields.Offer')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.offer}
												documentId={formData.Offer}
												documentLabel={formData.OfferLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* SettlementType */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('SettlementType') && (
							<TextField
								select
								disabled={!isEditable('SettlementType')}
								value={formData.SettlementType || ''}
								id="contract-field-SettlementType"
								name="SettlementType"
								label={t('Fields.SettlementType')}
								error={fieldErrorsList.includes('SettlementType')}
								onChange={onInputChange}
								fullWidth>
								{contractSettlementType.map((el: any, index: number) => (
									<MenuItem key={index} value={el.value}>
										{t(`Fields.SettlementTypeEnums.${el.label}`)}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
					{/* ContractType */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ContractType') && (
							<TextField
								select
								disabled={!isEditable('ContractType')}
								value={formData.ContractType || ''}
								id="contract-field-ContractType"
								name="ContractType"
								label={t('Fields.ContractType')}
								error={fieldErrorsList.includes('ContractType')}
								onChange={onInputChange}
								fullWidth>
								{contractContractType.map((el: any, index: number) => (
									<MenuItem key={index} value={el.value}>
										{t(`Fields.ContractTypeEnums.${el.label}`)}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
				</Grid>
			</div>
			<ContractorForm
				documentId={contractId}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
				showContractorBankAccount
				contractorBankAccountProps={{
					readOnly: !isEditable('ContractorBankAccount'),
					label: formData.ContractorBankAccountLabel,
					value: formData.ContractorBankAccount,
				}}
			/>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={el.label} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel className="py-2.5 px-0" key={index} value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default ContractForm
