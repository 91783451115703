import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

interface Folder {
	directoryId: string | undefined
	directoryName: string
}

const useAttachmentsService = () => {
	const {
		request,
		uploadFileRequest,
		downloadFileRequest,
		showSuccessInfo,
		error,
		fieldsError,
		clearError,
		getFileURL,
	} = useHttp()

	const getAttachments = useCallback(async (contextId: string, showDeleted: boolean) => {
		const response = await request(`/api/attachment/${contextId}?showDeleted=${showDeleted}`)
		return response
	}, [])

	const deleteFile = useCallback(async (fileId: string) => {
		await request(`/api/attachment/?attachmentId=${fileId}`, 'DELETE')
	}, [])

	const undeleteFile = useCallback(async (fileId: string) => {
		await request(`/api/attachment/undelete/?attachmentId=${fileId}`, 'PATCH')
	}, [])

	const downloadFile = useCallback(async (name: string, fileId: string) => {
		const response = await downloadFileRequest(
			`/api/attachment/download/?attachmentId=${fileId}`,
			'GET',
			{
				'Content-Type': 'application/octet-stream',
			},
			name
		)
		return response
	}, [])

	const getURL = useCallback(async (fileId: string, name?: string) => {
		const response = await getFileURL(
			`/api/attachment/download/?attachmentId=${fileId}`,
			'GET',
			{
				'Content-Type': 'application/octet-stream',
			},
			name
		)
		return response
	}, [])

	const getWordExcelPreviewPdf = useCallback(async (fileId: string) => {
		const response = await getFileURL(`/api/attachment/downloadWordExcelPreviewPdf/?attachmentId=${fileId}`, 'GET', {
			'Content-Type': 'application/octet-stream',
		})
		return response
	}, [])

	const attachFileToInquiries = useCallback(async (demandId: string, attachmentId: string) => {
		await request(`/api/demand/quotationRequestAttachements/${demandId}?attachmentId=${attachmentId}`, 'PUT')
	}, [])

	const deleteFolder = useCallback(async (folderId: string) => {
		await request(`/api/attachment/directory/?directoryId=${folderId}`, 'DELETE')
	}, [])

	const addFile = useCallback(async (contextId: string, formData: any) => {
		const response = await uploadFileRequest(`/api/attachment/upload/${contextId}`, 'POST', formData)
		return response
	}, [])

	const addFolder = useCallback(async (contextId: string, folder: Folder) => {
		let endpoint = folder.directoryId
			? `/api/attachment/directory/${contextId}?directoryId=${folder.directoryId}&directoryName=${folder.directoryName}`
			: `/api/attachment/directory/${contextId}?directoryName=${folder.directoryName}`

		const response = await request(endpoint, 'POST')
		return response
	}, [])

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getAttachments,
		deleteFile,
		undeleteFile,
		downloadFile,
		attachFileToInquiries,
		deleteFolder,
		addFile,
		addFolder,
		getURL,
		getWordExcelPreviewPdf,
	}
}

export default useAttachmentsService
