import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentFormSaveButton, FormSkeletonLoading } from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import { useAppSelector, useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features'
import { IInvestorSalesInvoice } from 'models'
import { useInvestorSalesInvoiceService } from 'services'

import InvestorSalesInvoiceForm from './InvestorSalesInvoiceForm'

interface Props {
	invoiceId: string
}

const InvestorSalesInvoiceBasicInfo = ({ invoiceId }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)

	const { t } = useTranslation()

	const { setDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { getSalesInvoice, updateSalesInvoice } = useInvestorSalesInvoiceService()

	const refreshDocumentData = async () => {
		try {
			const response = await getSalesInvoice(invoiceId)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [invoiceId])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={17} tabs={3} />
	}

	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
						documentId={invoiceId}
					/>
				</div>
				<div>
					<ChangeHistory
						contextId={invoiceId}
						translationFile="invoice"
						title={`${t('SalesInvoice', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="InvestorSalesInvoice.Fields"
					/>
					<DocumentFormSaveButton
						documentId={invoiceId}
						data={documentData}
						updateDataFunction={updateSalesInvoice}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<InvestorSalesInvoiceForm formData={documentData as IInvestorSalesInvoice} invoiceId={invoiceId} />
			</div>
		</>
	)
}

export default InvestorSalesInvoiceBasicInfo
