import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { Divider, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFormDataChange } from 'hooks'
import { IUser, IWorkflowDocumentHistory } from 'models'
import { useDocumentOwnerService } from 'services'

import { TooltipButton } from '../buttons'
import ConfirmDeleteOwnerDialog from './ConfirmDeleteOwnerDialog'
import StageDiagram from './StageDiagram'
import WorkflowDocumentHistory from './workflow-document-history/WorkflowDocumentHistory'

interface Props {
	workflowId: string
	documentId: string
	refreshData: () => void
}

const DocumentWorkflowTab = ({ workflowId, documentId, refreshData }: Props) => {
	const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [documentOwnersList, setDocumentOwnersList] = useState([])
	const [isConfirmDeletingDialogOpen, setIsConfirmDeletingDialogOpen] = useState(false)
	const [userCanAssingOwner, setUserCanAssignOwner] = useState(false)
	const [documentHistory, setDocumentHistory] = useState<IWorkflowDocumentHistory[]>()

	const { documentData } = useAppSelector(state => state.documentData)
	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { t } = useTranslation()

	const { handleAutocompleteInputChange } = useFormDataChange()
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const {
		removeCurrentOwner,
		setUserAsOwner,
		getPossibleOwners,
		getUserPrivilige,
		getWorkflowDocumentHistory,
		showSuccessInfo,
	} = useDocumentOwnerService()

	const handleInputChange = (newValue: any, valueFieldName: string, labelFieldName: string) => {
		!isSaveButtonEnabled && setSaveButtonEnabled(true)
		handleAutocompleteInputChange(newValue, valueFieldName, labelFieldName)
	}

	const handleDeleteOwner = useCallback(async () => {
		try {
			await removeCurrentOwner(workflowId, documentId)
			refreshData()
			getDocumentHistory()
			showSuccessInfo('removedDocumentOwner')
		} catch (err) {
			console.error(err)
		}
	}, [removeCurrentOwner, workflowId, documentId])

	const handleChangesSave = async () => {
		if (documentData?.OwnerId)
			try {
				setButtonLoading(true)
				await setUserAsOwner(workflowId, documentId, documentData.OwnerId)
				setSaveButtonEnabled(false)
				refreshData()
				getDocumentHistory()
				showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			} finally {
				setButtonLoading(false)
			}
	}

	const getPossibleDocumentOwners = async (instanceId: string) => {
		if (documentOwnersList.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getPossibleOwners(workflowId, documentId, 'Current')

				setDocumentOwnersList(
					response.map((el: IUser) => ({
						Id: el.Id,
						Name: `${el.FirstName || ''} ${el.LastName || ''} ${!el.FirstName && !el.LastName ? el.Login : ''}`,
					}))
				)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	const getUserPriviligeData = useCallback(async () => {
		try {
			const response = await getUserPrivilige(workflowId, documentId)
			setUserCanAssignOwner(response)
		} catch (err) {
			console.error(err)
		}
	}, [documentId, workflowId, getUserPrivilige])

	const getDocumentHistory = useCallback(async () => {
		try {
			const response = await getWorkflowDocumentHistory(workflowId, documentId)
			setDocumentHistory(response)
		} catch (err) {
			console.error(err)
		}
	}, [documentId, workflowId, getWorkflowDocumentHistory])

	useEffect(() => {
		getUserPriviligeData()
		getDocumentHistory()
	}, [workflowId, documentId])

	return (
		<div className="p-4">
			<div className="flex items-center justify-between">
				<span className="text-xl p-1 pb-2">{t('DocumentWorkflowPage.Title')}</span>
				<LoadingButton
					variant="text"
					disabled={!isSaveButtonEnabled}
					onClick={handleChangesSave}
					loading={buttonLoading}>
					{t('general.Save')}
				</LoadingButton>
			</div>
			<Divider />
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '10px' }}>
				<Grid xs={12} sm={12} md={3} lg={3}>
					<StageDiagram workflowId={workflowId} activeStage={documentData?.StageId as string} />
				</Grid>
				<Grid xs={12} sm={12} md={9} lg={9} sx={{ display: 'flex', flexDirection: 'column' }}>
					<div className="flex w-full">
						<Grid lg={6} md={6} sm={12} xs={12}>
							<TextField
								disabled={true}
								value={documentData?.CreatorIdLabel || ''}
								id="DocumentCreator"
								name="DocumentCreator"
								label={t('DocumentWorkflowPage.CreatedBy')}
								fullWidth
							/>
						</Grid>
						<AutocompleteInput
							dimensions={{ xs: 11, sm: 11, md: 5, lg: 5 }}
							initialValue={{
								value: documentData?.OwnerId || '',
								label: documentData?.OwnerIdLabel || '',
							}}
							onOpen={() => getPossibleDocumentOwners('DocumentWorkflowPage.DocumentOwner')}
							onChange={handleInputChange}
							options={documentOwnersList}
							readOnly={!userCanAssingOwner}
							instanceId="DocumentWorkflowPage.DocumentOwner"
							inputLabel={t('DocumentWorkflowPage.DocumentOwner')}
							name="OwnerId"
							labelFieldName="OwnerIdLabel"
						/>
						<Grid
							lg={1}
							md={1}
							sm={1}
							xs={1}
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}>
							{documentData?.OwnerId && !isSaveButtonEnabled && (
								<TooltipButton
									className="p-2"
									title="DocumentWorkflowPage.DeleteIconTooltip"
									disabled={!userCanAssingOwner}
									onClick={() => setIsConfirmDeletingDialogOpen(true)}
									IconComponent={DeleteIcon}
								/>
							)}
						</Grid>
					</div>
					<Grid lg={12} md={12} sm={12} xs={12}>
						<WorkflowDocumentHistory documentHistoryData={documentHistory} />
					</Grid>
				</Grid>
			</Grid>
			<ConfirmDeleteOwnerDialog
				isOpen={isConfirmDeletingDialogOpen}
				setIsOpen={setIsConfirmDeletingDialogOpen}
				handleDeleteOwner={handleDeleteOwner}
			/>
		</div>
	)
}

export default DocumentWorkflowTab
