import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useFieldsPermissions } from 'hooks'
import { IInvestorProcessingProtocol } from 'models'
import { FormatNumberInput } from 'utils/FormatNumberInput'
import { formatDateTimeMUI } from 'utils/formatDateTime'

interface Props {
	formData: IInvestorProcessingProtocol
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const Settlements = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('investor-processing-protocol')

	return (
		<div className="w-full pb-4">
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* CurrentPeriodValueNetto  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('CurrentPeriodValueNetto') && (
						<TextField
							disabled={true}
							value={formData.CurrentPeriodValueNetto || ''}
							name="CurrentPeriodValueNetto"
							label={t('Fields.CurrentPeriodValueNetto')}
							error={fieldErrorsList.includes('CurrentPeriodValueNetto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* SecurityDepositAmountToSave  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('SecurityDepositAmountToSave') && (
						<TextField
							disabled={true}
							value={formData.SecurityDepositAmountToSave || ''}
							name="SecurityDepositAmountToSave"
							label={t('Fields.SecurityDepositAmountToSave')}
							error={fieldErrorsList.includes('SecurityDepositAmountToSave')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ConsumptionDateTimeStart */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ConsumptionDateTimeStart') && (
						<TextField
							disabled={!isEditable('ConsumptionDateTimeStart')}
							value={formatDateTimeMUI(formData.ConsumptionDateTimeStart) || ''}
							name="ConsumptionDateTimeStart"
							label={t('Fields.ConsumptionDateTimeStart')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldErrorsList.includes('ConsumptionDateTimeStart')}
							onChange={onInputChange}
							fullWidth
						/>
					)}
				</Grid>
				{/* ConsumptionDateTimeEnd */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ConsumptionDateTimeEnd') && (
						<TextField
							disabled={!isEditable('ConsumptionDateTimeEnd')}
							value={formatDateTimeMUI(formData.ConsumptionDateTimeEnd) || ''}
							name="ConsumptionDateTimeEnd"
							label={t('Fields.ConsumptionDateTimeEnd')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldErrorsList.includes('ConsumptionDateTimeEnd')}
							onChange={onInputChange}
							fullWidth
						/>
					)}
				</Grid>
				{/* Comments */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					{isVisible('Comments') && (
						<TextField
							disabled={!isEditable('Comments')}
							value={formData.Comments || ''}
							name="Comments"
							label={t('Fields.Comments')}
							error={fieldErrorsList.includes('Comments')}
							onChange={onInputChange}
							multiline
							minRows={4}
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Settlements
