import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { ICustomCostInvoice } from 'models'

interface Props {
	formData: ICustomCostInvoice
}

const Notes = ({ formData }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')
	const { fieldsError, handleInputChange } = useFormDataChange()

	return (
		<div>
			<Grid className="mt-1" container rowSpacing={3} columnSpacing={2}>
				{/* SettlementCommentsFromContractor */}
				<Grid xs={12} sm={12} md={6} lg={6}>
					{isVisible('SettlementCommentsFromContractor') && (
						<TextField
							disabled={!isEditable('SettlementCommentsFromContractor')}
							value={formData.SettlementCommentsFromContractor || ''}
							name="SettlementCommentsFromContractor"
							label={t('CustomCostInvoice.Fields.SettlementCommentsFromContractor')}
							error={fieldsError.includes('SettlementCommentsFromContractor')}
							onChange={handleInputChange}
							multiline
							minRows={3}
							fullWidth
						/>
					)}
				</Grid>
				{/* Comments */}
				<Grid xs={12} sm={12} md={6} lg={6}>
					{isVisible('Comments') && (
						<TextField
							disabled={!isEditable('Comments')}
							value={formData.Comments || ''}
							name="Comments"
							label={t('CustomCostInvoice.Fields.Comments')}
							error={fieldsError.includes('Comments')}
							onChange={handleInputChange}
							multiline
							minRows={3}
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Notes
