import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import SaveIcon from '@mui/icons-material/Save'
import { CircularProgress } from '@mui/material'
import {
	DataGridPremium,
	GridCellParams,
	GridEventListener,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import NoData from 'components/shared/no-data-message/NoData'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IPrintTemplate } from 'models/IPrintTemplate'
import { usePrintTemplatesService } from 'services'

import AddTemplateDialog from './AddTemplateDialog'
import MarkupsDialog from './MarkupsDialog'
import PrintTemplatesToolbar from './PrintTemplatesToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

const templateTypes = [
	'Order',
	'DebitNote',
	'ProcessingProtocol',
	'InvestorProcessingProtocol',
	'ContractSalesInvoice',
	'SalesInvoice',
	'ProcessingProtocolFinal',
	'Contract',
]

const PrintTemplates = () => {
	const [data, setData] = useState<IPrintTemplate[]>([])

	const [dataLoading, setDataLoading] = useState(true)
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [isAddFileDialogOpen, setIsAddFileDialogOpen] = useState(false)
	const [isMarkupDialogOpen, setIsMarkupDialogOpen] = useState(false)
	const [documentType, setDocumentType] = useState('')
	const [filePreparing, setFilePreparing] = useState('')
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [selectedTemplateId, setSelectedTemplateId] = useState('')

	const { t } = useTranslation(['administration', 'translation'])

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()
	const { getPrintTemplates, addTemplate, updateTemplate, deleteTemplate, downloadFile, showSuccessInfo } =
		usePrintTemplatesService()
	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const handleAddTemplateClick = (params: IPrintTemplate) => {
		setSelectedTemplateId(params.Id)
		setDocumentType(params.Type)
		setIsAddFileDialogOpen(true)
	}

	const handleShowMarkupsClick = (params: IPrintTemplate) => {
		setDocumentType(params.Type)
		setIsMarkupDialogOpen(true)
	}

	const handleDownload = async (params: IPrintTemplate) => {
		const { Id, FileName } = params

		try {
			setFilePreparing(Id)
			await downloadFile(Id, FileName)
		} catch (err) {
			console.error(err)
		}
		setFilePreparing('')
	}

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id

		setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit, fieldToFocus: 'Name' } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = data.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setData(data.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteTemplate(id as string)
			setData(data.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addTemplate(newRow.Name, newRow.Type)
				createdRow = response
				setData(data.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
				getData()
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateTemplate(newRow.Id, newRow.Name)
				setData(data.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getData()
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('PrintTemplates.Fields.Name'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: true,
		},
		{
			field: 'Type',
			headerName: t('PrintTemplates.Fields.Type'),
			headerAlign: 'center',
			align: 'left',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.Type || 'Undefined'
			},
			valueOptions: () =>
				templateTypes.map((el: any) => ({
					value: el,
					label: t(`PrintTemplates.Fields.TypeEnums.${el}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = templateTypes.find((opt: any) => opt === value)
				if (option) {
					return t(`PrintTemplates.Fields.TypeEnums.${option}`)
				} else {
					return ''
				}
			},
			sortable: true,
			editable: true,
		},
		{
			field: 'FileName',
			headerName: t('PrintTemplates.Fields.FileName'),
			headerAlign: 'center',
			align: 'left',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return t('PrintTemplates.NoTemplate')
				}

				return value
			},
			sortable: true,
			editable: false,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			align: 'right',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				}

				const actions = [
					<TooltipButton
						title="general.Add"
						onClick={() => handleAddTemplateClick(params.row)}
						IconComponent={AddIcon}
						disabled={isActionButtonDisabled}
					/>,
					<TooltipButton
						title="general.ShowMarkups"
						onClick={() => handleShowMarkupsClick(params.row)}
						IconComponent={AlternateEmailIcon}
						disabled={isActionButtonDisabled}
					/>,
					<TooltipButton
						title="general.Remove"
						onClick={handleDeleteClick(params.id)}
						IconComponent={DeleteIcon}
						disabled={isActionButtonDisabled}
					/>,
				]

				if (params.row.FileName) {
					actions.push(
						filePreparing === params.row.Id ? (
							<CircularProgress size={25} sx={{ padding: '5px' }} />
						) : (
							<TooltipButton
								title="general.DownloadAttachment"
								onClick={() => handleDownload(params.row)}
								IconComponent={DownloadIcon}
								disabled={isActionButtonDisabled}
							/>
						)
					)
				}

				return actions
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const isCellEditable = (params: GridCellParams) => {
		if (!params.row.isNew && params.field === 'Type') {
			return false
		} else return params.colDef.editable || false
	}

	const getData = async () => {
		try {
			const response = await getPrintTemplates()

			setData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getData()
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState, apiRef])

	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={data}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				components={{
					Toolbar: PrintTemplatesToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						setIsActionButtonDisabled,
						isActionButtonDisabled,
						setData,
						setRowModesModel,
					},
				}}
				onCellDoubleClick={handleCellDoubleClick}
				isCellEditable={isCellEditable}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				experimentalFeatures={{ newEditingApi: true }}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
				sx={{
					minHeight: '500px',
					height: 'calc(100vh - 230px)',
					border: 'none',
				}}
			/>
			{isAddFileDialogOpen && (
				<AddTemplateDialog
					templateId={selectedTemplateId}
					isOpen={isAddFileDialogOpen}
					setIsOpen={setIsAddFileDialogOpen}
					getData={getData}
				/>
			)}
			{isMarkupDialogOpen && (
				<MarkupsDialog documentType={documentType} isOpen={isMarkupDialogOpen} setIsOpen={setIsMarkupDialogOpen} />
			)}
		</>
	)
}

export default PrintTemplates
