import { useTranslation } from 'react-i18next'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useFieldsPermissions } from 'hooks'
import { IContract } from 'models'
import { FormatNumberInput } from 'utils/FormatNumberInput'
import { formatDateTimeMUI } from 'utils/formatDateTime'

interface Props {
	formData: IContract
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const TradingParameters = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('contract')

	return (
		<>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* Value */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('Value') && (
						<TextField
							disabled={true}
							value={formData.Value || ''}
							id="contract-field-Value"
							name="Value"
							label={t('Fields.Value')}
							error={fieldErrorsList.includes('Value')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueWithAnnexes */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ValueWithAnnexes') && (
						<TextField
							disabled={true}
							value={formData.ValueWithAnnexes || ''}
							id="contract-field-ValueWithAnnexes"
							name="ValueWithAnnexes"
							label={t('Fields.ValueWithAnnexes')}
							error={fieldErrorsList.includes('ValueWithAnnexes')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ConsumptionDateTimeStart */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ConsumptionDateTimeStart') && (
						<TextField
							disabled={!isEditable('ConsumptionDateTimeStart')}
							value={formatDateTimeMUI(formData.ConsumptionDateTimeStart) || ''}
							id="contract-field-ConsumptionDateTimeStart"
							name="ConsumptionDateTimeStart"
							label={t('Fields.ConsumptionDateTimeStart')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldErrorsList.includes('ConsumptionDateTimeStart')}
							onChange={onInputChange}
							fullWidth
						/>
					)}
				</Grid>
				{/* ConsumptionDateTimeEnd */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ConsumptionDateTimeEnd') && (
						<TextField
							disabled={!isEditable('ConsumptionDateTimeEnd')}
							value={formatDateTimeMUI(formData.ConsumptionDateTimeEnd) || ''}
							id="contract-field-ConsumptionDateTimeEnd"
							name="ConsumptionDateTimeEnd"
							label={t('Fields.ConsumptionDateTimeEnd')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldErrorsList.includes('ConsumptionDateTimeEnd')}
							onChange={onInputChange}
							fullWidth
						/>
					)}
				</Grid>
				{/* ContractProgressPerc */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ContractProgressPerc') && (
						<TextField
							disabled={!isEditable('ContractProgressPerc')}
							value={formData.ContractProgressPerc || ''}
							id="contract-field-ContractProgressPerc"
							name="ContractProgressPerc"
							label={t('Fields.ContractProgressPerc')}
							error={fieldErrorsList.includes('ContractProgressPerc')}
							onChange={onInputChange}
							placeholder="0"
							type="number"
							InputLabelProps={{ shrink: true }}
							InputProps={{
								inputProps: { min: 0, max: 100 },
								endAdornment: <InputAdornment position="end">%</InputAdornment>,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* AnnexesValue */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('AnnexesValue') && (
						<TextField
							disabled={true}
							value={formData.AnnexesValue || ''}
							id="contract-field-AnnexesValue"
							name="AnnexesValue"
							label={t('Fields.AnnexesValue')}
							error={fieldErrorsList.includes('AnnexesValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* WorkFrontTransferDate */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('WorkFrontTransferDate') && (
						<TextField
							disabled={!isEditable('WorkFrontTransferDate')}
							value={formatDateTimeMUI(formData.WorkFrontTransferDate) || ''}
							id="contract-field-WorkFrontTransferDate"
							name="WorkFrontTransferDate"
							label={t('Fields.WorkFrontTransferDate')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldErrorsList.includes('WorkFrontTransferDate')}
							onChange={onInputChange}
							fullWidth
						/>
					)}
				</Grid>
				{/* FinalProtocolDate */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('FinalProtocolDate') && (
						<TextField
							disabled={true}
							value={formatDateTimeMUI(formData.FinalProtocolDate) || ''}
							id="contract-field-FinalProtocolDate"
							name="FinalProtocolDate"
							label={t('Fields.FinalProtocolDate')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldErrorsList.includes('FinalProtocolDate')}
							onChange={onInputChange}
							fullWidth
						/>
					)}
				</Grid>
				{/* AdvancePaid */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('AdvancePaid') && (
						<TextField
							disabled={!isEditable('AdvancePaid')}
							value={formData.AdvancePaid || ''}
							id="contract-field-AdvancePaid"
							name="AdvancePaid"
							label={t('Fields.AdvancePaid')}
							error={fieldErrorsList.includes('AdvancePaid')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* AdvanceRemainingValue */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('AdvanceRemainingValue') && (
						<TextField
							disabled={true}
							value={formData.AdvanceRemainingValue || ''}
							id="contract-field-AdvanceRemainingValue"
							name="AdvanceRemainingValue"
							label={t('Fields.AdvanceRemainingValue')}
							error={fieldErrorsList.includes('AdvanceRemainingValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* PaymentTimeDays */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('PaymentTimeDays') && (
						<TextField
							disabled={!isEditable('PaymentTimeDays')}
							value={formData.PaymentTimeDays || ''}
							id="contract-field-PaymentTimeDays"
							name="PaymentTimeDays"
							label={t('Fields.PaymentTimeDays')}
							error={fieldErrorsList.includes('PaymentTimeDays')}
							onChange={onInputChange}
							type="number"
							fullWidth
						/>
					)}
				</Grid>
				{/* PaymentTimeDaysFS */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('PaymentTimeDaysFS') && (
						<TextField
							disabled={!isEditable('PaymentTimeDaysFS')}
							value={formData.PaymentTimeDaysFS || ''}
							id="contract-field-PaymentTimeDaysFS"
							name="PaymentTimeDaysFS"
							label={t('Fields.PaymentTimeDaysFS')}
							error={fieldErrorsList.includes('PaymentTimeDaysFS')}
							onChange={onInputChange}
							type="number"
							fullWidth
						/>
					)}
				</Grid>
				{/* Description */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					{isVisible('Description') && (
						<TextField
							disabled={!isEditable('Description')}
							value={formData.Description || ''}
							id="contract-field-Description"
							name="Description"
							label={t('Fields.Description')}
							error={fieldErrorsList.includes('Description')}
							onChange={onInputChange}
							multiline
							minRows={3}
							fullWidth
						/>
					)}
				</Grid>
				{/* SettlementComments */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					{isVisible('SettlementComments') && (
						<TextField
							disabled={!isEditable('SettlementComments')}
							value={formData.SettlementComments || ''}
							id="contract-field-SettlementComments"
							name="SettlementComments"
							label={t('Fields.SettlementComments')}
							error={fieldErrorsList.includes('SettlementComments')}
							onChange={onInputChange}
							multiline
							minRows={6}
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
		</>
	)
}

export default TradingParameters
