import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useFieldsPermissions } from 'hooks'
import { IContract } from 'models'

interface Props {
	formData: IContract
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const GeneralTermsModification = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('contract')

	return (
		<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
			{/* GeneralTermsModification */}
			<Grid xs={12} sm={12} md={12} lg={12}>
				{isVisible('GeneralTermsModification') && (
					<TextField
						disabled={!isEditable('GeneralTermsModification')}
						value={formData.GeneralTermsModification || ''}
						id="contract-field-GeneralTermsModification"
						name="GeneralTermsModification"
						label={t('Fields.GeneralTermsModification')}
						error={fieldErrorsList.includes('GeneralTermsModification')}
						onChange={onInputChange}
						multiline
						minRows={7}
						fullWidth
					/>
				)}
			</Grid>
		</Grid>
	)
}

export default GeneralTermsModification
