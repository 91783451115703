import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AddAddressDialog, AutocompleteInput, ContactPersons, InputLinkButton, TooltipButton } from 'components/shared'
import { PATHS } from 'data'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IDemand } from 'models'
import { DEMAND_ENDPOINT, useAddressService, useWorkflowService } from 'services'
import FormatAddress from 'utils/FormatAdress'
import { formatDateTimeMUI } from 'utils/formatDateTime'

interface Props {
	demandId: string
	formData: IDemand
}

const DemandForm = ({ demandId, formData }: Props) => {
	const [listOfAddress, setListOfAddress] = useState([])
	const [offerWorkflowOptions, setOfferWorkflowOptions] = useState([])
	const [selectedTab, setSelectedTab] = useState('0')
	const [isAddAddressDialogOpen, setIsAddAddressDialogOpen] = useState(false)

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { handleInputChange, handleAutocompleteInputChange, fieldsError } = useFormDataChange()
	const { getWorkflowList } = useWorkflowService()
	const { getAddresses } = useAddressService()

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('demand')

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('demandFormSelectedTab', newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('BasicInformationFormTabs.Contact'),
				renderValue: <ContactPersons documentId={demandId} contractorId="" endpoint={DEMAND_ENDPOINT} />,
				isVisible: isVisible('ContactPersons'),
			},
		],
		[t, formData]
	)

	const getOfferWorkflowData = async (instanceId: string) => {
		if (offerWorkflowOptions.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getWorkflowList('Offer')

				setOfferWorkflowOptions(response.data)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	const getAddressesData = async (instanceId: string) => {
		if (listOfAddress.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getAddresses(formData.Project)

				setListOfAddress(
					response
						.filter((item: any) => item.ProjectId === formData.Project)
						.map((el: any) => {
							return {
								Id: el.Id,
								Name: FormatAddress(el),
							}
						})
				)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	useEffect(() => {
		const savedTab = localStorage.getItem('demandFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('Fields.Number')}
								error={fieldsError.includes('Number')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* CreatedDateTime  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('CreatedDateTime') && (
							<TextField
								disabled={!isEditable('CreatedDateTime')}
								value={formatDateTimeMUI(formData.CreatedDateTime) || ''}
								name="CreatedDateTime"
								label={t('Fields.CreatedDateTime')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('CreatedDateTime')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* DemandCostType */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DemandCostType') && (
							<TextField
								disabled={true}
								value={formData.DemandCostType || ''}
								id="DemandCostType"
								name="DemandCostType"
								label={t('Fields.DemandCostType')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* DeliveryAddress */}
					{isVisible('DeliveryAddress') && (
						<AutocompleteInput
							dimensions={{ xs: 10.5, sm: 11, md: 5, lg: 5.5 }}
							initialValue={{
								value: formData?.DeliveryAddress || '',
								label: formData?.DeliveryAddressLabel || '',
							}}
							onOpen={() => getAddressesData('Fields.DeliveryAddress')}
							onChange={handleAutocompleteInputChange}
							options={listOfAddress}
							readOnly={!isEditable('DeliveryAddress')}
							instanceId={'Fields.DeliveryAddress'}
							inputLabel={t('Fields.DeliveryAddress')}
							name="DeliveryAddress"
							labelFieldName="DeliveryAddressLabel"
						/>
					)}
					{/* AddDeliveryAddress */}
					<Grid className="flex justify-start items-center" xs={1.5} sm={1} md={1} lg={0.5}>
						{isVisible('DeliveryAddress') && (
							<>
								<TooltipButton
									title="general.Add"
									className="p-2"
									onClick={() => setIsAddAddressDialogOpen(true)}
									IconComponent={AddIcon}
									disabled={!isEditable('DeliveryAddress')}
									fontSize="medium"
								/>
								{isAddAddressDialogOpen && (
									<AddAddressDialog
										isOpen={isAddAddressDialogOpen}
										setIsOpen={setIsAddAddressDialogOpen}
										updateList={getAddressesData}
										projectId={formData.Project}
										addressFieldName="DeliveryAddress"
										addressLabelFieldName="DeliveryAddressLabel"
									/>
								)}
							</>
						)}
					</Grid>
					{/* DeliveryDateTime  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DeliveryDateTime') && (
							<TextField
								disabled={!isEditable('DeliveryDateTime')}
								value={formatDateTimeMUI(formData.DeliveryDateTime) || ''}
								name="DeliveryDateTime"
								label={t('Fields.DeliveryDateTime')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DeliveryDateTime')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* ConsumptionDateTime  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ConsumptionDateTime') && (
							<TextField
								disabled={!isEditable('ConsumptionDateTime')}
								value={formatDateTimeMUI(formData.ConsumptionDateTime) || ''}
								name="ConsumptionDateTime"
								label={t('Fields.ConsumptionDateTime')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('ConsumptionDateTime')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* ConsumptionDateTimeEnd  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ConsumptionDateTimeEnd') && (
							<TextField
								disabled={!isEditable('ConsumptionDateTimeEnd')}
								value={formatDateTimeMUI(formData.ConsumptionDateTimeEnd) || ''}
								name="ConsumptionDateTimeEnd"
								label={t('Fields.ConsumptionDateTimeEnd')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('ConsumptionDateTimeEnd')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* NewOfferWorkflow */}
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						initialValue={{
							value: formData?.NewOfferWorkflow || '',
							label: formData?.NewOfferWorkflowLabel || '',
						}}
						onOpen={() => getOfferWorkflowData('Fields.NewOfferWorkflow')}
						onChange={handleAutocompleteInputChange}
						options={offerWorkflowOptions}
						readOnly={!isEditable('NewOfferWorkflow')}
						instanceId={'Fields.NewOfferWorkflow'}
						inputLabel={t('Fields.NewOfferWorkflow')}
						name="NewOfferWorkflow"
						labelFieldName="NewOfferWorkflowLabel"
						endAdorment={
							<InputAdornment position="end">
								<InputLinkButton
									path={PATHS.workflow}
									documentId={formData.NewOfferWorkflow}
									documentLabel={formData.NewOfferWorkflowLabel}
								/>
							</InputAdornment>
						}
					/>
					{/* Notes  */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('Notes') && (
							<TextField
								disabled={!isEditable('Notes')}
								value={formData.Notes || ''}
								name="Notes"
								label={t('Fields.Notes')}
								error={fieldsError.includes('Notes')}
								onChange={handleInputChange}
								fullWidth
								multiline
								minRows={3}
							/>
						)}
					</Grid>
					{/* CopySource */}
					{isVisible('CopySource') && formData.CopySource && (
						<Grid xs={12} sm={6} md={3} lg={3}>
							<TextField
								disabled={true}
								value={formData.CopySourceLabel || ''}
								name="CopySource"
								label={t('Fields.CopySource')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.demand}
												documentId={formData.CopySource}
												documentLabel={formData.CopySourceLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					)}
					{/* CopyReplacing */}
					{isVisible('CopyReplacing') && formData.CopyReplacing && (
						<Grid xs={12} sm={6} md={3} lg={3}>
							<TextField
								disabled={true}
								value={formData.CopyReplacingLabel || ''}
								name="CopyReplacing"
								label={t('Fields.CopyReplacing')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.demand}
												documentId={formData.CopyReplacing}
												documentLabel={formData.CopyReplacingLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					)}
				</Grid>
			</div>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default DemandForm
