import { useTranslation } from 'react-i18next'

import { Box, InputAdornment, MenuItem, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { OfferContractType } from 'data/lookup-data-sources/OfferContractType'
import { OfferSettlementType } from 'data/lookup-data-sources/OfferSettlementType'
import { projectSecurityForm } from 'data/lookup-data-sources/projectSecurityForm'
import { useFieldsPermissions } from 'hooks'
import { IOffer } from 'models'
import { FormatNumberInput } from 'utils/FormatNumberInput'
import { formatDateTimeMUI } from 'utils/formatDateTime'

interface Props {
	formData: IOffer
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const OfferParameters = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('offer')

	const theme = useTheme()
	const tabletViewAndUp = useMediaQuery(theme.breakpoints.up('sm'))

	return (
		<Box
			sx={{
				...sxStyles.row,
				flexDirection: tabletViewAndUp ? 'row' : 'column',
			}}>
			<Box sx={tabletViewAndUp ? sxStyles.itemsContainerLeft : sxStyles.itemsContainerMobile}>
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* ValueToContract  */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ValueToContract') && (
							<TextField
								disabled={!isEditable('ValueToContract')}
								value={formData.ValueToContract || ''}
								id="offer-field-ValueToContract"
								name="ValueToContract"
								label={t('Fields.ValueToContract')}
								error={fieldErrorsList.includes('ValueToContract')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* ValidToDate */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ValidToDate') && (
							<TextField
								disabled={!isEditable('ValidToDate')}
								value={formatDateTimeMUI(formData.ValidToDate) || ''}
								id="offer-field-ValidToDate"
								name="ValidToDate"
								label={t('Fields.ValidToDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('ValidToDate')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* ConsumptionDateTimeStart */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ConsumptionDateTimeStart') && (
							<TextField
								disabled={!isEditable('ConsumptionDateTimeStart')}
								value={formatDateTimeMUI(formData.ConsumptionDateTimeStart) || ''}
								id="offer-field-ConsumptionDateTimeStart"
								name="ConsumptionDateTimeStart"
								label={t('Fields.ConsumptionDateTimeStart')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('ConsumptionDateTimeStart')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* ConsumptionDateTimeEnd */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ConsumptionDateTimeEnd') && (
							<TextField
								disabled={!isEditable('ConsumptionDateTimeEnd')}
								value={formatDateTimeMUI(formData.ConsumptionDateTimeEnd) || ''}
								id="offer-field-ConsumptionDateTimeEnd"
								name="ConsumptionDateTimeEnd"
								label={t('Fields.ConsumptionDateTimeEnd')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('ConsumptionDateTimeEnd')}
								onChange={onInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* PaymentTimeDays */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('PaymentTimeDays') && (
							<TextField
								disabled={!isEditable('PaymentTimeDays')}
								value={formData.PaymentTimeDays || ''}
								id="offer-field-PaymentTimeDays"
								name="PaymentTimeDays"
								label={t('Fields.PaymentTimeDays')}
								error={fieldErrorsList.includes('PaymentTimeDays')}
								onChange={onInputChange}
								type="number"
								fullWidth
							/>
						)}
					</Grid>
					{/* PaymentTimeDaysFS */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('PaymentTimeDaysFS') && (
							<TextField
								disabled={!isEditable('PaymentTimeDaysFS')}
								value={formData.PaymentTimeDaysFS || ''}
								id="offer-field-PaymentTimeDaysFS"
								name="PaymentTimeDaysFS"
								label={t('Fields.PaymentTimeDaysFS')}
								error={fieldErrorsList.includes('PaymentTimeDaysFS')}
								onChange={onInputChange}
								type="number"
								fullWidth
							/>
						)}
					</Grid>
					{/* Comment */}
					<Grid xs={12} sm={12} md={12} lg={12}>
						{isVisible('Comment') && (
							<TextField
								disabled={true}
								value={formData.Comment || ''}
								name="Comment"
								label={t('Fields.Comment')}
								error={fieldErrorsList.includes('Comment')}
								onChange={onInputChange}
								multiline
								minRows={3}
								fullWidth
							/>
						)}
					</Grid>
				</Grid>
			</Box>
			<Box sx={tabletViewAndUp ? sxStyles.itemsContainerRight : sxStyles.itemsContainerMobile}>
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* SettlementType */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('SettlementType') && (
							<TextField
								select
								disabled={!isEditable('SettlementType')}
								value={formData.SettlementType || ''}
								id="offer-field-SettlementType"
								name="SettlementType"
								label={t('Fields.SettlementType')}
								error={fieldErrorsList.includes('SettlementType')}
								onChange={onInputChange}
								fullWidth>
								{OfferSettlementType.map((el: any, index: number) => (
									<MenuItem key={index} value={el.value}>
										{t(`Fields.SettlementTypeEnums.${el.label}`)}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
					{/* ContractType */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ContractType') && (
							<TextField
								select
								disabled={!isEditable('ContractType')}
								value={formData.ContractType || ''}
								id="offer-field-ContractType"
								name="ContractType"
								label={t('Fields.ContractType')}
								error={fieldErrorsList.includes('ContractType')}
								onChange={onInputChange}
								fullWidth>
								{OfferContractType.map((el: any, index: number) => (
									<MenuItem key={index} value={el.value}>
										{t(`Fields.ContractTypeEnums.${el.label}`)}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
					{/* Participation */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('Participation') && (
							<TextField
								disabled={!isEditable('Participation')}
								value={formData.Participation || ''}
								id="offer-field-Participation"
								name="Participation"
								label={t('Fields.Participation')}
								error={fieldErrorsList.includes('Participation')}
								onChange={onInputChange}
								placeholder="0"
								type="number"
								InputLabelProps={{ shrink: true }}
								InputProps={{
									inputProps: { min: 0, max: 100 },
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* ContractProgressPerc */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ContractProgressPerc') && (
							<TextField
								disabled={!isEditable('ContractProgressPerc')}
								value={formData.ContractProgressPerc || ''}
								id="offer-field-ContractProgressPerc"
								name="ContractProgressPerc"
								label={t('Fields.ContractProgressPerc')}
								error={fieldErrorsList.includes('ContractProgressPerc')}
								onChange={onInputChange}
								placeholder="0"
								type="number"
								InputLabelProps={{ shrink: true }}
								InputProps={{
									inputProps: { min: 0, max: 100 },
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* DepositSecurityFormNWK */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('DepositSecurityFormNWK') && (
							<TextField
								disabled={!isEditable('DepositSecurityFormNWK')}
								value={formData?.DepositSecurityFormNWK || ''}
								select
								id="offer-field-DepositSecurityFormNWK"
								name="DepositSecurityFormNWK"
								label={t('Fields.DepositSecurityFormNWK')}
								onChange={onInputChange}
								fullWidth>
								{projectSecurityForm.map(option => {
									return (
										<MenuItem key={option.value} value={option.value}>
											{t(`Fields.DepositSecurityFormEnums.${option.label}`)}
										</MenuItem>
									)
								})}
							</TextField>
						)}
					</Grid>
					{/* DepositSecurityFormUWiU */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('DepositSecurityFormUWiU') && (
							<TextField
								disabled={!isEditable('DepositSecurityFormUWiU')}
								value={formData?.DepositSecurityFormUWiU || ''}
								select
								id="offer-field-DepositSecurityFormUWiU"
								name="DepositSecurityFormUWiU"
								label={t('Fields.DepositSecurityFormUWiU')}
								onChange={onInputChange}
								fullWidth>
								{projectSecurityForm.map(option => {
									return (
										<MenuItem key={option.value} value={option.value}>
											{t(`Fields.DepositSecurityFormEnums.${option.label}`)}
										</MenuItem>
									)
								})}
							</TextField>
						)}
					</Grid>
					{/* SecurityDepositAmountPercNWK */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('SecurityDepositAmountPercNWK') && (
							<TextField
								disabled={!isEditable('SecurityDepositAmountPercNWK')}
								value={formData.SecurityDepositAmountPercNWK || ''}
								id="offer-field-SecurityDepositAmountPercNWK"
								name="SecurityDepositAmountPercNWK"
								label={t('Fields.SecurityDepositAmountPercNWK')}
								error={fieldErrorsList.includes('SecurityDepositAmountPercNWK')}
								onChange={onInputChange}
								placeholder="0"
								type="number"
								InputLabelProps={{ shrink: true }}
								InputProps={{
									inputProps: { min: 0, max: 100 },
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* SecurityDepositAmountPercUWiU */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('SecurityDepositAmountPercUWiU') && (
							<TextField
								disabled={!isEditable('SecurityDepositAmountPercUWiU')}
								value={formData.SecurityDepositAmountPercUWiU || ''}
								id="offer-field-SecurityDepositAmountPercUWiU"
								name="SecurityDepositAmountPercUWiU"
								label={t('Fields.SecurityDepositAmountPercUWiU')}
								error={fieldErrorsList.includes('SecurityDepositAmountPercUWiU')}
								onChange={onInputChange}
								placeholder="0"
								type="number"
								InputLabelProps={{ shrink: true }}
								InputProps={{
									inputProps: { min: 0, max: 100 },
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								fullWidth
							/>
						)}
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default OfferParameters

const sxStyles = {
	row: {
		display: 'flex',
		width: '100%',
		pb: '15px',
	},
	itemsContainerLeft: {
		width: '50%',
		mr: '8px',
	},
	itemsContainerRight: {
		width: '50%',
		ml: '8px',
	},
	itemsContainerMobile: {
		width: '100%',
		mb: '10px',
	},
}
