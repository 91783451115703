import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import { Box, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { AddAddressDialog, AutocompleteInput, TooltipButton } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IOrder, IPaymentMethod } from 'models'
import { useAddressService, usePaymentMethodService } from 'services'
import FormatAddress from 'utils/FormatAdress'
import { FormatNumberInput } from 'utils/FormatNumberInput'
import { formatDateTimeMUI } from 'utils/formatDateTime'

interface Props {
	formData: IOrder
}

const OrderParameters = ({ formData }: Props) => {
	const [listOfAddress, setListOfAddress] = useState([])
	const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([])
	const [isAddAddressDialogOpen, setIsAddAddressDialogOpen] = useState(false)

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { handleInputChange, handleAutocompleteInputChange, fieldsError } = useFormDataChange()
	const { getPaymentMethods } = usePaymentMethodService()
	const { getAddresses } = useAddressService()

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('order')

	const theme = useTheme()
	const tabletViewAndUp = useMediaQuery(theme.breakpoints.up('sm'))
	const tabletViewAndDown = useMediaQuery(theme.breakpoints.down('md'))

	const getAddressesData = async (instanceId: string) => {
		if (listOfAddress.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getAddresses(formData.Project)

				setListOfAddress(
					response
						.filter((item: any) => item.ProjectId === formData.Project)
						.map((el: any) => {
							return {
								Id: el.Id,
								Name: FormatAddress(el),
							}
						})
				)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	const getPaymentMethodsData = async (instanceId: string) => {
		if (paymentMethods.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getPaymentMethods()

				setPaymentMethods(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	return (
		<Box
			sx={{
				...sxStyles.row,
				flexDirection: tabletViewAndUp ? 'row' : 'column',
			}}>
			<Box sx={tabletViewAndUp ? sxStyles.itemsContainerLeft : sxStyles.itemsContainerMobile}>
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Value  */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('Value') && (
							<TextField
								disabled={true}
								value={formData.Value || ''}
								id="order-field-Value"
								name="Value"
								label={t('Fields.Value')}
								error={fieldsError.includes('Value')}
								onChange={handleInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* ValueWithAnnexes  */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ValueWithAnnexes') && (
							<TextField
								disabled={true}
								value={formData.ValueWithAnnexes || ''}
								name="ValueWithAnnexes"
								label={t('Fields.ValueWithAnnexes')}
								error={fieldsError.includes('ValueWithAnnexes')}
								onChange={handleInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* AnnexesValue  */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('AnnexesValue') && (
							<TextField
								disabled={true}
								value={formData.AnnexesValue || ''}
								name="AnnexesValue"
								label={t('Fields.AnnexesValue')}
								error={fieldsError.includes('AnnexesValue')}
								onChange={handleInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{!tabletViewAndDown && (
						<Grid xs={12} sm={6} md={6} lg={6}>
							{/* Empty Item */}
						</Grid>
					)}
					{/* AdvancePaid */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						{isVisible('AdvancePaid') && (
							<TextField
								disabled={!isEditable('AdvancePaid')}
								value={formData.AdvancePaid || ''}
								name="AdvancePaid"
								label={t('Fields.AdvancePaid')}
								error={fieldsError.includes('AdvancePaid')}
								onChange={handleInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* AdvanceRemainingValue */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						{isVisible('AdvanceRemainingValue') && (
							<TextField
								disabled={!isEditable('AdvanceRemainingValue')}
								value={formData.AdvanceRemainingValue || ''}
								name="AdvanceRemainingValue"
								label={t('Fields.AdvanceRemainingValue')}
								error={fieldsError.includes('AdvanceRemainingValue')}
								onChange={handleInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* PaymentTimeDays */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('PaymentTimeDays') && (
							<TextField
								disabled={!isEditable('PaymentTimeDays')}
								value={formData.PaymentTimeDays || ''}
								id="order-field-PaymentTimeDays"
								name="PaymentTimeDays"
								label={t('Fields.PaymentTimeDays')}
								error={fieldsError.includes('PaymentTimeDays')}
								onChange={handleInputChange}
								type="number"
								fullWidth
							/>
						)}
					</Grid>
					{/* DelivereDate */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('DelivereDate') && (
							<TextField
								disabled={!isEditable('DelivereDate')}
								value={formatDateTimeMUI(formData.DelivereDate) || ''}
								id="order-field-DelivereDate"
								name="DelivereDate"
								label={t('Fields.DelivereDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DelivereDate')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* PaymentMethod */}
					{isVisible('PaymentMethod') && (
						<AutocompleteInput
							dimensions={{ xs: 12, sm: 12, md: 6, lg: 6 }}
							initialValue={{
								value: formData.PaymentMethod || '',
								label: formData.PaymentMethodLabel || '',
							}}
							onOpen={() => getPaymentMethodsData('Fields.PaymentMethod')}
							onChange={handleAutocompleteInputChange}
							options={paymentMethods}
							readOnly={!isEditable('PaymentMethod')}
							instanceId="Fields.PaymentMethod"
							inputLabel={t('Fields.PaymentMethod')}
							name="PaymentMethod"
							labelFieldName="PaymentMethodLabel"
						/>
					)}
				</Grid>
			</Box>
			<Box sx={tabletViewAndUp ? sxStyles.itemsContainerRight : sxStyles.itemsContainerMobile}>
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{isVisible('Address') && (
						<AutocompleteInput
							dimensions={{ xs: 10, sm: 10, md: 10.5, lg: 11 }}
							initialValue={{
								value: formData?.Address || '',
								label: formData?.AddressLabel || '',
							}}
							onOpen={() => getAddressesData('Fields.Address')}
							onChange={handleAutocompleteInputChange}
							options={listOfAddress}
							readOnly={!isEditable('Address')}
							instanceId={'Fields.Address'}
							inputLabel={t('Fields.Address')}
							name="Address"
							labelFieldName="AddressLabel"
						/>
					)}
					<Grid className="flex justify-start items-center" xs={2} sm={2} md={1.5} lg={1}>
						{isVisible('Address') && (
							<>
								<TooltipButton
									title="general.Add"
									className="p-2"
									onClick={() => setIsAddAddressDialogOpen(true)}
									IconComponent={AddIcon}
									disabled={!isEditable('Address')}
									fontSize="medium"
								/>
							</>
						)}
						{isAddAddressDialogOpen && (
							<AddAddressDialog
								isOpen={isAddAddressDialogOpen}
								setIsOpen={setIsAddAddressDialogOpen}
								updateList={getAddressesData}
								projectId={formData.Project}
								addressFieldName="Address"
								addressLabelFieldName="AddressLabel"
							/>
						)}
					</Grid>
					{/* Comments */}
					<Grid xs={12} sm={12} md={12} lg={12}>
						{isVisible('Comments') && (
							<TextField
								disabled={!isEditable('Comments')}
								value={formData.Comments || ''}
								id="order-field-Comments"
								name="Comments"
								label={t('Fields.Comments')}
								error={fieldsError.includes('Comments')}
								onChange={handleInputChange}
								multiline
								minRows={3}
								fullWidth
							/>
						)}
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default OrderParameters

const sxStyles = {
	row: {
		display: 'flex',
		width: '100%',
		pb: '15px',
	},
	itemsContainerLeft: {
		width: '50%',
		mr: '8px',
	},
	itemsContainerRight: {
		width: '50%',
		ml: '8px',
	},
	itemsContainerMobile: {
		width: '100%',
		mb: '10px',
	},
	addressInputContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}
