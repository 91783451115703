import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import { DataGridPremium, GridRowParams, useGridApiRef } from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import NoData from 'components/shared/no-data-message/NoData'
import { workflowDocumentType } from 'data/lookup-data-sources/workflowDocumentType'
import { useDataGridState, useHandleRowClick } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IWorkflow } from 'models'
import { useWorkflowService } from 'services'

import DeleteWorkflowDialog from './DeleteWorkflowDialog'
import WorkflowTabToolbar from './WorkflowTabToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

const WorkflowTab = () => {
	const [data, setData] = useState<IWorkflow[]>([])
	const [dataToRender, setDataToRender] = useState<IWorkflow[]>([])

	const [dataLoading, setDataLoading] = useState(true)
	const [searchValue, setSearchValue] = useState('')

	const [selectedWorkflowData, setSelectedWorkflowData] = useState<IWorkflow>()
	const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()
	const { getWorkflowList } = useWorkflowService()
	const { dataGridLanguage } = useLanguage()

	const { t } = useTranslation(['administration', 'translation'])

	const apiRef = useGridApiRef()

	const { handleRowClick } = useHandleRowClick()

	const handleSearchChange = (e: any) => {
		const searchedValue = e.target.value
		setSearchValue(searchedValue)

		// eslint-disable-next-line array-callback-return
		const filteredItems = data.filter((item: IWorkflow) => {
			const nameLowerCase = item.Name?.toLowerCase()

			if (nameLowerCase?.includes(searchedValue.toLowerCase())) return item
		})
		setDataToRender(filteredItems)
	}

	const handleClearSearchText = () => {
		setSearchValue('')
		setDataToRender(data)
	}

	const handleDeleteClick = (params: GridRowParams) => {
		setSelectedWorkflowData(params.row)
		setIsDeleteDialogVisible(true)
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('Workflow.Fields.Name'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'DataType',
			headerName: t('Workflow.Fields.DataType'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: false,
			type: 'singleSelect',
			valueOptions: () =>
				workflowDocumentType.map(el => {
					return {
						value: el.value,
						label: t(`Workflow.Fields.DataTypeEnums.${el.label}`),
					}
				}),
			valueFormatter: ({ value }: any) => {
				const option = workflowDocumentType.find((opt: any) => opt.value === value)
				if (option) {
					return t(`Workflow.Fields.DataTypeEnums.${option.label}`)
				} else {
					return ''
				}
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				return [
					<TooltipButton title="general.Remove" onClick={() => handleDeleteClick(params)} IconComponent={DeleteIcon} />,
				]
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = useCallback(async () => {
		try {
			setDataLoading(true)
			const response = await getWorkflowList()

			const sortByName = response.data.sort((a: any, b: any) => a.Name.localeCompare(b.Name))

			setData(sortByName)
			setDataToRender(sortByName)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getWorkflowList])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getData()
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState, apiRef])

	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				columns={orderedColumns}
				rows={dataToRender}
				getRowId={row => row.Id}
				onRowClick={params => handleRowClick(params, '/workflow', 'Name', 25)}
				loading={dataLoading || dataGridStateLoading}
				className="shadow-datagrid"
				sx={{ minHeight: '500px', height: 'calc(100vh - 230px)', border: 'none' }}
				components={{
					Toolbar: WorkflowTabToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						getData,
						apiRef,
						setDataLoading,
						handleSearchChange,
						handleClearSearchText,
						searchValue,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isDeleteDialogVisible && (
				<DeleteWorkflowDialog
					isOpen={isDeleteDialogVisible}
					setIsOpen={setIsDeleteDialogVisible}
					workflowData={selectedWorkflowData as IWorkflow}
					getData={getData}
				/>
			)}
		</>
	)
}

export default WorkflowTab
