import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import AccountCircle from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import SettingsIcon from '@mui/icons-material/Settings'
import { Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material'

import { useAuth, usePermissions } from 'hooks'

import SideSwitcher from '../../attachments-preview/settings/SideSwitcher'
import WindowWidth from '../../attachments-preview/settings/WindowWidth'
import LanguageSwitcher from '../../language-switcher/LanguageSwitcher'
import ThemeSwitcher from '../../theme-switcher/ThemeSwitcher'

const sxStyles = {
	menuItemIcon: {
		mr: '5px',
		fontSize: '20px',
	},
	staticMenuItem: {
		cursor: 'default',
		justifyContent: 'center',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
}

interface Props {
	setMuiTheme: (value: string) => void
}

const UserMenu = ({ setMuiTheme }: Props) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const { isGranted } = usePermissions()

	const { t } = useTranslation('navigation')
	const navigate = useNavigate()
	const { logout, user } = useAuth()

	const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const menuOptions = [
		{
			icon: <ManageAccountsIcon sx={sxStyles.menuItemIcon} />,
			handleOnClick: () => navigate('/mysettings'),
			text: t('user-menu.MyAccount'),
			visible: true,
		},
		{
			icon: <SettingsIcon sx={sxStyles.menuItemIcon} />,
			handleOnClick: () => navigate('/administration'),
			text: t('user-menu.Administration'),
			visible: isGranted('Administrator'),
		},
		{
			icon: <LogoutIcon sx={sxStyles.menuItemIcon} />,
			handleOnClick: () => logout(),
			text: t('user-menu.LogOut'),
			visible: true,
		},
	]

	return (
		<>
			<IconButton className="p-2" onClick={handleOpenMenu} color="inherit">
				<AccountCircle />
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={handleCloseMenu}>
				<MenuItem sx={sxStyles.staticMenuItem} disableRipple>
					{user?.FirstName} {user?.LastName}
				</MenuItem>
				<Divider />
				<MenuItem sx={sxStyles.staticMenuItem} disableRipple>
					<ThemeSwitcher setMuiTheme={setMuiTheme} />
				</MenuItem>
				<Divider />
				<MenuItem sx={sxStyles.staticMenuItem} disableRipple>
					<LanguageSwitcher variant="toggle" />
				</MenuItem>
				<Divider />
				<MenuItem sx={{ ...sxStyles.staticMenuItem, flexDirection: 'column' }} disableRipple>
					<Typography
						component="span"
						fontSize={'12px'}
						fontWeight={'500'}
						sx={{
							pb: '5px',
							textTransform: 'uppercase',
							textAlign: 'center',
						}}>
						{t('user-menu.AttachmentsPreview')}
					</Typography>
					<SideSwitcher />
				</MenuItem>
				<MenuItem sx={{ ...sxStyles.staticMenuItem, flexDirection: 'column' }} disableRipple>
					<WindowWidth />
				</MenuItem>
				<Divider />
				{menuOptions.map((el, index) => {
					return el.visible ? (
						<MenuItem
							key={index}
							onClick={() => {
								handleCloseMenu()
								el.handleOnClick()
							}}>
							{el.icon} {el.text}
						</MenuItem>
					) : null
				})}
			</Menu>
		</>
	)
}

export default UserMenu
