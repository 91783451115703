import { useCallback, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { Box, Button, Divider, IconButton, Switch, TextField, Tooltip, Typography } from '@mui/material'

import { IAttachment } from 'models/IAttachment'
import { useAttachmentsService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import Loading from '../loading/Loading'
import AttachmentList from './AttachmentsList'
import styles from './Styles.module.css'
import AddAttachment from './add-attachments/AddAttachment'

interface Props {
	contextId: string
	isForInquiries?: boolean
	workflowId?: string
}

const Attachments = ({ contextId, isForInquiries, workflowId }: Props) => {
	const [attachments, setAttachments] = useState<IAttachment[]>([])
	const [searchedAttachments, setSearchedAttachments] = useState<IAttachment[]>([])
	const [showSearchResult, setShowSearchResult] = useState(false)
	const [showDeletedFiles, setShowDeletedFiles] = useState(false)
	const [searchValue, setSearchValue] = useState('')
	const [loading, setLoading] = useState(true)

	const [update, updateAttachments] = useReducer(x => x + 1, 0)

	const { getAttachments } = useAttachmentsService()

	const { t } = useTranslation(['attachments', 'translation'])

	const handleCheckBoxChange = () => {
		setShowDeletedFiles(!showDeletedFiles)
	}

	const handleSearchValueChange = (e: any) => {
		setSearchValue(e.target.value)
	}

	const handleSearch = () => {
		const searchItems = searchValue
			.toLowerCase()
			.split(' ')
			.filter(el => el)

		const result: IAttachment[] = []
		attachments.map(el => {
			result.push(...searchChildElements(el, searchItems))
		})

		setSearchedAttachments(result)
		setShowSearchResult(true)
	}

	const searchChildElements = (item: any, searchItems: string[]) => {
		const result = []
		if (
			!item.IsDirectory &&
			searchItems.some(el => {
				return item.Name.toLowerCase().includes(el)
			})
		) {
			result.push(item)
		}

		if (item.SubItems) {
			item.SubItems.forEach((el: any) => {
				result.push(...searchChildElements(el, searchItems))
			})
		}

		return result
	}

	const handleClearSearchResult = () => {
		setShowSearchResult(false)
		setSearchedAttachments([])
		setSearchValue('')
	}

	const getData = useCallback(async () => {
		try {
			const response = await getAttachments(contextId, showDeletedFiles)
			setAttachments(response)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}, [contextId, showDeletedFiles])

	useEffect(() => {
		getData()
	}, [contextId, update, showDeletedFiles])

	if (loading) {
		return <Loading />
	}

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<p className={styles.attachmentsTitle}>{t('Title')}</p>
				<div className={styles.attachmentsControlPanel}>
					<TextField
						sx={{ mr: '10px' }}
						value={searchValue}
						id="search"
						name="searchField"
						placeholder={t('SearchInputPlaceholder') as string}
						onChange={handleSearchValueChange}
						onKeyPress={e => {
							if (e.key === 'Enter') {
								handleSearch()
							}
						}}
						size="small"
						InputProps={{
							style: {
								paddingRight: '0',
							},
							endAdornment: (
								<>
									<Tooltip title={<TooltipTextWrapper title={t('SearchInputClearButton')} />} placement="top" arrow>
										<IconButton
											onClick={() => setSearchValue('')}
											sx={{
												visibility: searchValue ? 'visible' : 'hidden',
											}}>
											<ClearIcon />
										</IconButton>
									</Tooltip>
									<Divider orientation="vertical" variant="middle" flexItem />
									<Tooltip title={<TooltipTextWrapper title={t('SearchInputSearchButton')} />} placement="top" arrow>
										<IconButton onClick={handleSearch}>
											<SearchIcon />
										</IconButton>
									</Tooltip>
								</>
							),
						}}
					/>
					<div className={styles.checkBoxContainer}>
						<Tooltip title={<TooltipTextWrapper title={t('ShowDeletedItemsSwitch')} />} placement="top" arrow>
							<Switch checked={showDeletedFiles} onChange={handleCheckBoxChange} size="small" />
						</Tooltip>
					</div>
					<AddAttachment contextId={contextId} updateAttachments={updateAttachments} />
				</div>
			</div>
			{attachments.length === 0 || (showSearchResult && searchedAttachments.length === 0) ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}>
					{showSearchResult ? (
						<>
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>
								<Button
									size="small"
									sx={{
										mr: '8px',
									}}
									onClick={handleClearSearchResult}>
									{t('SearchResultBackButton')}
								</Button>
							</Box>
							<Typography fontSize={'16px'} sx={{ margin: '20px 0' }}>
								{t('NoSearchResultsInfo')}
							</Typography>
						</>
					) : (
						<Typography fontSize={'16px'} sx={{ margin: '20px 0' }}>
							{t('NoAttachmentsInfo')}
						</Typography>
					)}
				</Box>
			) : (
				<>
					{showSearchResult ? (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								size="small"
								sx={{
									mr: '8px',
								}}
								onClick={handleClearSearchResult}>
								{t('ClearSearchResultsButton')}
							</Button>
						</Box>
					) : null}
					<AttachmentList
						array={showSearchResult ? searchedAttachments : attachments}
						contextId={contextId}
						updateAttachments={updateAttachments}
						isForInquiries={isForInquiries}
						workflowId={workflowId}
					/>
				</>
			)}
		</div>
	)
}

export default Attachments
