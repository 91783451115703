import { useTranslation } from 'react-i18next'

import { Box, InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useFieldsPermissions } from 'hooks'
import { IContract } from 'models'
import { FormatNumberInput } from 'utils/FormatNumberInput'

interface Props {
	formData: IContract
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const SettlementsAndParticipations = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('contract')

	const theme = useTheme()
	const tabletViewAndUp = useMediaQuery(theme.breakpoints.up('sm'))

	return (
		<Box
			sx={{
				...sxStyles.row,
				flexDirection: tabletViewAndUp ? 'row' : 'column',
			}}>
			<Box sx={tabletViewAndUp ? sxStyles.itemsContainerLeft : sxStyles.itemsContainerMobile}>
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* ParticipationPerc */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ParticipationPerc') && (
							<TextField
								disabled={!isEditable('ParticipationPerc')}
								value={formData.ParticipationPerc || ''}
								id="contract-field-ParticipationPerc"
								name="ParticipationPerc"
								label={t('Fields.ParticipationPerc')}
								error={fieldErrorsList.includes('ParticipationPerc')}
								onChange={onInputChange}
								placeholder="0"
								type="number"
								InputLabelProps={{ shrink: true }}
								InputProps={{
									inputProps: { min: 0, max: 100 },
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* ParticipationValue */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ParticipationValue') && (
							<TextField
								disabled={true}
								value={formData.ParticipationValue || ''}
								id="contract-field-ParticipationValue"
								name="ParticipationValue"
								label={t('Fields.ParticipationValue')}
								error={fieldErrorsList.includes('ParticipationValue')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* ParticipationIPPerc */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ParticipationIPPerc') && (
							<TextField
								disabled={true}
								value={formData.ParticipationIPPerc || ''}
								id="contract-field-ParticipationIPPerc"
								name="ParticipationIPPerc"
								label={t('Fields.ParticipationIPPerc')}
								error={fieldErrorsList.includes('ParticipationIPPerc')}
								onChange={onInputChange}
								placeholder="0"
								type="number"
								InputLabelProps={{ shrink: true }}
								InputProps={{
									inputProps: { min: 0, max: 100 },
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* ParticipationIPValue */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ParticipationIPValue') && (
							<TextField
								disabled={true}
								value={formData.ParticipationIPValue || ''}
								id="contract-field-ParticipationIPValue"
								name="ParticipationIPValue"
								label={t('Fields.ParticipationIPValue')}
								error={fieldErrorsList.includes('ParticipationIPValue')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* DebitNoteValue */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('DebitNoteValue') && (
							<TextField
								disabled={true}
								value={formData.DebitNoteValue || ''}
								id="contract-field-DebitNoteValue"
								name="DebitNoteValue"
								label={t('Fields.DebitNoteValue')}
								error={fieldErrorsList.includes('DebitNoteValue')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* RetentionsValuePeriod */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('RetentionsValuePeriod') && (
							<TextField
								disabled={true}
								value={formData.RetentionsValuePeriod || ''}
								id="contract-field-RetentionsValuePeriod"
								name="RetentionsValuePeriod"
								label={t('Fields.RetentionsValuePeriod')}
								error={fieldErrorsList.includes('RetentionsValuePeriod')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
				</Grid>
			</Box>
			<Box sx={tabletViewAndUp ? sxStyles.itemsContainerRight : sxStyles.itemsContainerMobile}>
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* AdditionalCostsValue */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('AdditionalCostsValue') && (
							<TextField
								disabled={true}
								value={formData.AdditionalCostsValue || ''}
								id="contract-field-AdditionalCostsValue"
								name="AdditionalCostsValue"
								label={t('Fields.AdditionalCostsValue')}
								error={fieldErrorsList.includes('AdditionalCostsValue')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* LastProtocolValue */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('LastProtocolValue') && (
							<TextField
								disabled={true}
								value={formData.LastProtocolValue || ''}
								id="contract-field-LastProtocolValue"
								name="LastProtocolValue"
								label={t('Fields.LastProtocolValue')}
								error={fieldErrorsList.includes('LastProtocolValue')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* CostReInvoice */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('CostReInvoice') && (
							<TextField
								disabled={true}
								value={formData.CostReInvoice || ''}
								id="contract-field-CostReInvoice"
								name="CostReInvoice"
								label={t('Fields.CostReInvoice')}
								error={fieldErrorsList.includes('CostReInvoice')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* ProtocolsValue */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('ProtocolsValue') && (
							<TextField
								disabled={true}
								value={formData.ProtocolsValue || ''}
								id="contract-field-ProtocolsValue"
								name="ProtocolsValue"
								label={t('Fields.ProtocolsValue')}
								error={fieldErrorsList.includes('ProtocolsValue')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* RetentionsValue */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('RetentionsValue') && (
							<TextField
								disabled={true}
								value={formData.RetentionsValue || ''}
								id="contract-field-RetentionsValue"
								name="RetentionsValue"
								label={t('Fields.RetentionsValue')}
								error={fieldErrorsList.includes('RetentionsValue')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* RemainingValueToDone */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						{isVisible('RemainingValueToDone') && (
							<TextField
								disabled={true}
								value={formData.RemainingValueToDone || ''}
								id="contract-field-RemainingValueToDone"
								name="RemainingValueToDone"
								label={t('Fields.RemainingValueToDone')}
								error={fieldErrorsList.includes('RemainingValueToDone')}
								onChange={onInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default SettlementsAndParticipations

const sxStyles = {
	row: {
		display: 'flex',
		width: '100%',
		pb: '15px',
	},
	itemsContainerLeft: {
		width: '50%',
		mr: '8px',
	},
	itemsContainerRight: {
		width: '50%',
		ml: '8px',
	},
	itemsContainerMobile: {
		width: '100%',
		mb: '10px',
	},
}
