import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
	Autocomplete,
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	InputAdornment,
	Tab,
	TextField,
	createFilterOptions,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput, ContactPersons, ContractorForm, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IPaymentMethod, ISalesInvoice } from 'models'
import { SALES_INVOICE_ENDPOINT, useContractorsService, usePaymentMethodService } from 'services'
import { formatDateTimeMUI } from 'utils/formatDateTime'

import ErpData from './form-tabs/ErpData'
import Values from './form-tabs/Values'

interface Props {
	invoiceId: string
	formData: ISalesInvoice
}

const SalesInvoiceForm = ({ invoiceId, formData }: Props) => {
	const [contractorsList, setContractorsList] = useState<
		Array<{ value: string; label: string | undefined; SearchItems: string }>
	>([])
	const [autocompleteContractor, setAutocompleteContractor] = useState({
		value: '',
		label: '',
		SearchItems: '',
	})
	const [contractorsLoading, setContractorsLoading] = useState(false)
	const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([])
	const [selectedTab, setSelectedTab] = useState('0')

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')

	const { handleInputChange, handleCheckboxChange, handleAutocompleteInputChange, fieldsError } = useFormDataChange()
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { getContractor, getContractorsLookup } = useContractorsService()
	const { getPaymentMethods } = usePaymentMethodService()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('contractSalesInvoiceFormSelectedTab', newValue)
	}

	const handleContractorRecipientChange = (e: any, newValue: any) => {
		const data = {
			target: {
				value: newValue.value,
				name: 'ContractorRecipient',
			},
		}
		handleInputChange(data as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('SalesInvoice.BasicInformationFormTabs.Value'),
				renderValue: <Values formData={formData} onInputChange={handleInputChange} fieldErrorsList={fieldsError} />,
				isVisible: true,
			},
			{
				label: t('SalesInvoice.BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons
						documentId={invoiceId}
						contractorId={formData.Contractor || ''}
						endpoint={SALES_INVOICE_ENDPOINT}
					/>
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
			{
				label: t('SalesInvoice.BasicInformationFormTabs.ERP'),
				renderValue: <ErpData formData={formData} onInputChange={handleInputChange} fieldErrorsList={fieldsError} />,
				isVisible: true,
			},
		],
		[t, formData]
	)

	const getContractorRecipientData = useCallback(async () => {
		if (formData.ContractorRecipient) {
			try {
				const response = await getContractor(formData.ContractorRecipient)

				setAutocompleteContractor({
					label: response.Name,
					value: response.Id,
					SearchItems: '',
				})
			} catch (err) {
				console.error(err)
			}
		}
	}, [getContractor, formData.ContractorRecipient])

	const getContractorsData = useCallback(async () => {
		if (contractorsList.length === 0 && !contractorsLoading)
			try {
				setContractorsLoading(true)
				const response = await getContractorsLookup()

				const sortByLabel = response.sort((a: any, b: any) => {
					const labelA = a.label || ''
					const labelB = b.label || ''
					return labelA.localeCompare(labelB)
				})
				setContractorsList(sortByLabel)

				setContractorsLoading(false)
			} catch (err) {
				console.error(err)
				setContractorsLoading(false)
			}
	}, [getContractorsLookup, contractorsList.length, contractorsLoading])

	const getPaymentMethodsData = async (instanceId: string) => {
		if (paymentMethods.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getPaymentMethods()

				setPaymentMethods(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	useEffect(() => {
		const savedTab = localStorage.getItem('contractSalesInvoiceFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	useEffect(() => {
		getContractorRecipientData()
	}, [formData.ContractorRecipient])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('SalesInvoice.Fields.Number')}
								error={fieldsError.includes('Number')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={!isEditable('DocumentDate')}
								value={formData.DocumentDate || ''}
								name="DocumentDate"
								label={t('SalesInvoice.Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DocumentDate')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('SalesInvoice.Fields.StageId')}
							fullWidth
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('SalesInvoice.Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('SalesInvoice.Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ProcessingProtocol */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ProcessingProtocol') && (
							<TextField
								disabled={true}
								value={formData.ProcessingProtocolLabel || ''}
								name="ProcessingProtocol"
								label={t('SalesInvoice.Fields.ProcessingProtocol')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.processingProtocol}
												documentId={formData.ProcessingProtocol}
												documentLabel={formData.ProcessingProtocolLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Contract */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Contract') && (
							<TextField
								disabled={true}
								value={formData.ContractLabel || ''}
								name="Contract"
								label={t('SalesInvoice.Fields.Contract')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.contract}
												documentId={formData.Contract}
												documentLabel={formData.ContractLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* MasterNumber  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('MasterNumber') && (
							<TextField
								disabled={true}
								value={formData.MasterNumber || ''}
								name="MasterNumber"
								label={t('SalesInvoice.Fields.MasterNumber')}
								error={fieldsError.includes('MasterNumber')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* IssueDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('IssueDate') && (
							<TextField
								disabled={!isEditable('IssueDate')}
								value={formatDateTimeMUI(formData.IssueDate) || ''}
								name="IssueDate"
								label={t('SalesInvoice.Fields.IssueDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('IssueDate')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* SubmissionDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('SubmissionDate') && (
							<TextField
								disabled={!isEditable('SubmissionDate')}
								value={formatDateTimeMUI(formData.SubmissionDate) || ''}
								name="SubmissionDate"
								label={t('SalesInvoice.Fields.SubmissionDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('SubmissionDate')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* SendDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('SendDate') && (
							<TextField
								disabled={!isEditable('SendDate')}
								value={formatDateTimeMUI(formData.SendDate) || ''}
								name="SendDate"
								label={t('SalesInvoice.Fields.SendDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('SendDate')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* CompleteDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('CompleteDate') && (
							<TextField
								disabled={!isEditable('CompleteDate')}
								value={formatDateTimeMUI(formData.CompleteDate) || ''}
								name="CompleteDate"
								label={t('SalesInvoice.Fields.CompleteDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('CompleteDate')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* PaymentDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('PaymentDate') && (
							<TextField
								disabled={!isEditable('PaymentDate')}
								value={formatDateTimeMUI(formData.PaymentDate) || ''}
								name="PaymentDate"
								label={t('SalesInvoice.Fields.PaymentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('PaymentDate')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* PaymentMethod */}
					{isVisible('PaymentMethod') && (
						<AutocompleteInput
							dimensions={{ xs: 12, sm: 12, md: 3, lg: 3 }}
							initialValue={{
								value: formData.PaymentMethod || '',
								label: formData.PaymentMethodLabel || '',
							}}
							onOpen={() => getPaymentMethodsData('SalesInvoice.Fields.PaymentMethod')}
							onChange={handleAutocompleteInputChange}
							options={paymentMethods}
							readOnly={!isEditable('PaymentMethod')}
							instanceId="SalesInvoice.Fields.PaymentMethod"
							inputLabel={t('SalesInvoice.Fields.PaymentMethod')}
							name="PaymentMethod"
							labelFieldName="PaymentMethodLabel"
						/>
					)}
					{/* SplitPayment */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<FormControlLabel
							control={
								<Checkbox
									disabled={!isEditable('SplitPayment')}
									checked={formData?.SplitPayment || false}
									onChange={handleCheckboxChange}
									name="SplitPayment"
								/>
							}
							label={t('SalesInvoice.Fields.SplitPayment')}
						/>
					</Grid>
				</Grid>
			</div>
			<ContractorForm
				documentId={invoiceId}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
			/>
			<Grid sx={{ my: '1px' }} container rowSpacing={3} columnSpacing={2}>
				{/* ContractorRecipient */}
				<Grid xs={12} sm={6} md={6} lg={6}>
					{isVisible('ContractorRecipient') && (
						<Autocomplete
							disablePortal
							disableClearable
							disabled={!isEditable('ContractorRecipient')}
							clearOnEscape
							autoHighlight
							value={autocompleteContractor}
							onOpen={getContractorsData}
							onChange={handleContractorRecipientChange}
							options={contractorsList}
							filterOptions={createFilterOptions({
								stringify: option => option.SearchItems,
							})}
							loading={contractorsLoading}
							loadingText={t('general.LoadingText', { ns: 'translation' })}
							noOptionsText={t('general.NoData', { ns: 'translation' })}
							renderOption={(props, option) => (
								<li {...props} key={option.value}>
									{option.label}
								</li>
							)}
							renderInput={(params: any) => (
								<TextField
									{...params}
									label={t('SalesInvoice.Fields.ContractorRecipient')}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{contractorsLoading ? <CircularProgress size={20} sx={{ mr: '5px' }} /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					)}
				</Grid>
			</Grid>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel className="py-2.5 px-0" key={index} value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default SalesInvoiceForm
