import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import {
	DataGridPremium,
	GridCellParams,
	GridEventListener,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { projectExcecutor } from 'data/lookup-data-sources'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IContractorsRepresentative } from 'models'
import { IContractorsRepresentativesUser } from 'models/IContractorsRepresentativesUser'
import { useContractorsRepresentativesService } from 'services'
import { formatDateTimeMUIGrid } from 'utils/formatDateTime'

import ContractorsRepresentativesToolbar from './ContractorsRepresentativesToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	projectId: string
	readOnly: boolean
}

const ContractorsRepresentatives = ({ projectId, readOnly }: Props) => {
	const [representativesData, setRepresentativesData] = useState<IContractorsRepresentative[]>([])
	const [usersList, setUsersList] = useState<IContractorsRepresentativesUser[]>([])
	const [representativePermissions, setRepresentativePermissions] = useState<{ value: string; label: string }[]>([])
	const [dataLoading, setDataLoading] = useState(true)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)
	const [selectedEntryId, setSelectedEntryId] = useState('')

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['project', 'translation'])

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const {
		showSuccessInfo,
		addContractorsRepresentative,
		updateContractorsRepresentative,
		deleteContractorsRepresentative,
		getContractorsRepresentatives,
		getUsersList,
		GetRepresentativePermissions,
	} = useContractorsRepresentativesService()

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id

		if (readOnly) {
			return
		}

		setIsActionButtonDisabled(true)
		setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = representativesData.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setRepresentativesData(representativesData.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteContractorsRepresentative(id as string)
			setRepresentativesData(representativesData.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addContractorsRepresentative({
					...newRow,
					ProjectId: projectId,
				} as IContractorsRepresentative)
				createdRow = response
				setRepresentativesData(representativesData.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
				getRepresentativesData()
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateContractorsRepresentative(newRow.Id, newRow as IContractorsRepresentative)
				setRepresentativesData(representativesData.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getRepresentativesData()
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'Role',
			headerName: t('ContractorsRepresentatives.Role'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
			type: 'singleSelect',
			valueOptions: () =>
				projectExcecutor.map(el => ({
					value: el.value,
					label: t(`ContractorsRepresentatives.RoleType.${el.label}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = projectExcecutor.find((opt: any) => opt.value === value)
				if (option) {
					return t(`ContractorsRepresentatives.RoleType.${option.label}`)
				} else {
					return ''
				}
			},
		},
		{
			field: 'PersonId',
			headerName: t('ContractorsRepresentatives.PersonId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
			type: 'singleSelect',
			valueOptions: () =>
				usersList.map(el => ({
					value: el.Id,
					label: el.label,
				})),
			valueFormatter: ({ value }: any) => {
				const user = usersList.find((opt: any) => opt.Id === value)
				if (user) {
					return user.label
				} else {
					return ''
				}
			},
		},
		{
			field: 'PhoneNumber',
			headerName: t('ContractorsRepresentatives.PhoneNumber'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			valueGetter: (params: any) => {
				const value = usersList.find(el => el.Id === params.row.PersonId)
				if (value) {
					return value.Telephone
				}

				return ''
			},
		},
		{
			field: 'EmailAdress',
			headerName: t('ContractorsRepresentatives.EmailAdress'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			valueGetter: (params: any) => {
				const value = usersList.find(el => el.Id === params.row.PersonId)
				if (value) {
					return value.EMail
				}

				return ''
			},
		},
		{
			field: 'RoleHeaderId',
			headerName: t('ContractorsRepresentatives.RoleHeaderId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
			type: 'singleSelect',
			valueOptions: () => representativePermissions,
			valueFormatter: ({ value }: any) => {
				const option = representativePermissions.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
		},
		{
			field: 'ParticipationStartDate',
			headerName: t('ContractorsRepresentatives.ParticipationStartDate'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
			type: 'date',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}
				const valueFormatted = formatDateTimeMUIGrid(value)
				return valueFormatted
			},
		},
		{
			field: 'ParticipationEndDate',
			headerName: t('ContractorsRepresentatives.ParticipationEndDate'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
			type: 'date',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}
				const valueFormatted = formatDateTimeMUIGrid(value)
				return valueFormatted
			},
		},
		{
			field: 'ParticipationMonths',
			headerName: t('ContractorsRepresentatives.ParticipationMonths'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
		},
		{
			field: 'PrintOnDocs',
			headerName: t('ContractorsRepresentatives.PrintOnDocs'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: true,
			type: 'boolean',
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				} else {
					return [
						<TooltipButton
							title="general.Remove"
							onClick={handleDeleteClick(params.id)}
							IconComponent={DeleteIcon}
							disabled={isActionButtonDisabled || readOnly}
						/>,
						<TooltipButton
							title="ChangeHistory.ChangeHistory"
							onClick={handleShowHistory(params)}
							IconComponent={HistoryIcon}
							disabled={isActionButtonDisabled}
						/>,
					]
				}
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getRepresentativesData = useCallback(async () => {
		try {
			const response = await getContractorsRepresentatives(projectId)
			setRepresentativesData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [projectId])

	const getUsers = useCallback(async () => {
		try {
			const response = await getUsersList()
			setUsersList(
				response.map((el: any) => ({
					...el,
					label: `${el.FirstName || ''} ${el.LastName || ''}`,
				}))
			)
		} catch (err) {
			console.error(err)
		}
	}, [])

	const getPermissionsList = useCallback(async () => {
		try {
			const response = await GetRepresentativePermissions()
			setRepresentativePermissions(response.map((el: any) => ({ value: el.Id, label: el.Name })))
		} catch (err) {
			console.error(err)
		}
	}, [])

	useEffect(() => {
		getRepresentativesData()
	}, [projectId])

	useEffect(() => {
		getUsers()
		getPermissionsList()
		getDataGridState(CONTEXT_STATE_NAME)
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<>
			<DataGridPremium
				autoHeight
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={representativesData}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				onCellDoubleClick={handleCellDoubleClick}
				experimentalFeatures={{ newEditingApi: true }}
				components={{
					Toolbar: ContractorsRepresentativesToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						isActionButtonDisabled,
						setIsActionButtonDisabled,
						setRepresentativesData,
						setRowModesModel,
						readOnly,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={t('FormTitles.ContractorsRepresentatives')}
					translationFile="project"
					translationPath="ContractorsRepresentatives"
				/>
			)}
		</>
	)
}

export default ContractorsRepresentatives
