import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import LinkIcon from '@mui/icons-material/Link'
import { IconButton, Tooltip } from '@mui/material'

import { DocumentFormSaveButton, FormSkeletonLoading } from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import { useAppSelector, useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features'
import { useLinkDocuments } from 'hooks'
import { IGoodsReceivedNote } from 'models'
import { useGoodsReceivedNoteService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import GoodsReceivedNoteForm from './GoodsReceivedNoteForm'

interface Props {
	id: string
}
const GoodsReceivedNoteBasicInfo = ({ id }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)

	const { t } = useTranslation()

	const { setDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { handleCopyDocumentData } = useLinkDocuments()
	const { getGoodsReceivedNote, updateGoodsReceivedNote } = useGoodsReceivedNoteService()

	const handleCopyDataForLinking = () => {
		handleCopyDocumentData({
			id: id,
			name: `${t('GoodsReceivedNote', { ns: 'routes' })} ${documentData?.Number}`,
			documentType: 'GoodsReceivedNote',
			otherData: documentData,
		})
	}

	const refreshDocumentData = async () => {
		try {
			const response = await getGoodsReceivedNote(id)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [id])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={9} tabs={1} />
	}

	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
					/>
				</div>
				<div>
					<Tooltip title={<TooltipTextWrapper title={t('LinkDocumentClipboard.CopyId')} />} placement="top" arrow>
						<IconButton onClick={() => handleCopyDataForLinking()}>
							<LinkIcon color="primary" />
						</IconButton>
					</Tooltip>
					<ChangeHistory
						contextId={id}
						translationFile="goods-received-note"
						title={`${t('GoodsReceivedNote', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="Fields"
					/>
					<DocumentFormSaveButton
						documentId={id}
						data={documentData}
						updateDataFunction={updateGoodsReceivedNote}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<GoodsReceivedNoteForm formData={documentData as IGoodsReceivedNote} id={id} />
			</div>
		</>
	)
}

export default GoodsReceivedNoteBasicInfo
