import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput } from 'components/shared'
import { useAppSelector } from 'features'
import { useAutocompleteInputActions } from 'features/actions'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IInvestorSalesInvoice, IMasterCategory } from 'models'
import { useMasterCategoryService } from 'services'

interface Props {
	formData: IInvestorSalesInvoice
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const ErpData = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const [masterCategories, setMasterCategories] = useState<IMasterCategory[]>([])

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { handleAutocompleteInputChange } = useFormDataChange()
	const { getMasterCategoryList } = useMasterCategoryService()

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')

	const getMasterCategoriesData = async (instanceId: string) => {
		if (masterCategories.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterCategoryList()

				setMasterCategories(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	return (
		<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
			{/* MasterDocumentSeries */}
			<Grid xs={12} sm={6} md={3} lg={3}>
				{isVisible('MasterDocumentSeries') && (
					<TextField
						disabled={!isEditable('MasterDocumentSeries')}
						value={formData?.MasterDocumentSeries || ''}
						name="MasterDocumentSeries"
						label={t('InvestorSalesInvoice.Fields.MasterDocumentSeries')}
						error={fieldErrorsList.includes('MasterDocumentSeries')}
						onChange={onInputChange}
						fullWidth
					/>
				)}
			</Grid>
			{/* MasterCategory */}
			{isVisible('MasterCategory') && (
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCategory || '',
						label: formData?.MasterCategoryLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('InvestorSalesInvoice.Fields.MasterCategory')}
					onChange={handleAutocompleteInputChange}
					options={masterCategories.filter(cat => cat.ForSalesInvoice)}
					readOnly={!isEditable('MasterCategory')}
					instanceId={'InvestorSalesInvoice.Fields.MasterCategory'}
					inputLabel={t('InvestorSalesInvoice.Fields.MasterCategory')}
					name="MasterCategory"
					labelFieldName="MasterCategoryLabel"
				/>
			)}
		</Grid>
	)
}

export default ErpData
