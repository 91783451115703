import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput, AutocompleteInputOption } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions } from 'hooks'
import { ICurrency, IMaterialInvoice } from 'models'
import { useCurrencyService } from 'services'
import { FormatNumberInput } from 'utils/FormatNumberInput'

interface Props {
	formData: IMaterialInvoice
	onInputChange: (e: any) => void
	onAutocompleteChange: (newValue: AutocompleteInputOption, valueFieldName: string, labelFieldName: string) => void
	fieldErrorsList: string[]
}

const Values = ({ formData, onInputChange, onAutocompleteChange, fieldErrorsList }: Props) => {
	const [currencyList, setCurrencyList] = useState<ICurrency[]>([])

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { getCurrency } = useCurrencyService()

	const getCurrencyData = async (instanceId: string) => {
		if (currencyList.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getCurrency()
				setCurrencyList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	return (
		<div>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* Currency  */}
				{isVisible('Currency') && (
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						order={{ md: 2, lg: 2 }}
						initialValue={{
							value: formData.Currency || '',
							label: formData.CurrencyLabel || '',
						}}
						onOpen={() => getCurrencyData('MaterialInvoice.Fields.Currency')}
						onChange={onAutocompleteChange}
						options={currencyList}
						readOnly={!isEditable('Currency')}
						instanceId="MaterialInvoice.Fields.Currency"
						inputLabel={t('MaterialInvoice.Fields.Currency')}
						name="Currency"
						labelFieldName="CurrencyLabel"
					/>
				)}
				{/* ExchangeRate  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 3, lg: 3 }}>
					{isVisible('ExchangeRate') && (
						<TextField
							disabled={!isEditable('ExchangeRate')}
							value={formData.ExchangeRate || ''}
							name="ExchangeRate"
							label={t('MaterialInvoice.Fields.ExchangeRate')}
							error={fieldErrorsList.includes('ExchangeRate')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueNetto  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 0, lg: 0 }}>
					{isVisible('ValueNetto') && (
						<TextField
							disabled={true}
							value={formData.ValueNetto || ''}
							name="ValueNetto"
							label={t('MaterialInvoice.Fields.ValueNetto')}
							error={fieldErrorsList.includes('ValueNetto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueVAT  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 1, lg: 1 }}>
					{isVisible('ValueVAT') && (
						<TextField
							disabled={true}
							value={formData.ValueVAT || ''}
							name="ValueVAT"
							label={t('MaterialInvoice.Fields.ValueVAT')}
							error={fieldErrorsList.includes('ValueVAT')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueBrutto  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 4, lg: 4 }}>
					{isVisible('ValueBrutto') && (
						<TextField
							disabled={true}
							value={formData.ValueBrutto || ''}
							name="ValueBrutto"
							label={t('MaterialInvoice.Fields.ValueBrutto')}
							error={fieldErrorsList.includes('ValueBrutto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueBruttoPLN  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 5, lg: 5 }}>
					{isVisible('ValueBruttoPLN') && (
						<TextField
							disabled={true}
							value={formData.ValueBruttoPLN || ''}
							name="ValueBruttoPLN"
							label={t('MaterialInvoice.Fields.ValueBruttoPLN')}
							error={fieldErrorsList.includes('ValueBruttoPLN')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* SettlementValue  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 6, lg: 6 }}>
					{isVisible('SettlementValue') && (
						<TextField
							disabled={true}
							value={formData.SettlementValue || ''}
							name="SettlementValue"
							label={t('MaterialInvoice.Fields.SettlementValue')}
							error={fieldErrorsList.includes('SettlementValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueToSettlement  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 7, lg: 7 }}>
					{isVisible('ValueToSettlement') && (
						<TextField
							disabled={!isEditable('ValueToSettlement')}
							value={formData.ValueToSettlement || ''}
							name="ValueToSettlement"
							label={t('MaterialInvoice.Fields.ValueToSettlement')}
							error={fieldErrorsList.includes('ValueToSettlement')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* PaidValue  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 8, lg: 8 }}>
					{isVisible('PaidValue') && (
						<TextField
							disabled={!isEditable('PaidValue')}
							value={formData.PaidValue || ''}
							name="PaidValue"
							label={t('MaterialInvoice.Fields.PaidValue')}
							error={fieldErrorsList.includes('PaidValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* WaitingForCorrecion */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 9, lg: 9 }}>
					<FormControlLabel
						control={
							<Checkbox
								disabled={!isEditable('WaitingForCorrecion')}
								checked={formData?.WaitingForCorrecion || false}
								name="WaitingForCorrecion"
							/>
						}
						label={t('MaterialInvoice.Fields.WaitingForCorrecion')}
					/>
				</Grid>
				{/* CorrectionsValueExpected  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 10, lg: 10 }}>
					{isVisible('CorrectionsValueExpected') && (
						<TextField
							disabled={!isEditable('CorrectionsValueExpected')}
							value={formData.CorrectionsValueExpected || ''}
							name="CorrectionsValueExpected"
							label={t('MaterialInvoice.Fields.CorrectionsValueExpected')}
							error={fieldErrorsList.includes('CorrectionsValueExpected')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* CorrectionsValueCalculated  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 11, lg: 11 }}>
					{isVisible('CorrectionsValueCalculated') && (
						<TextField
							disabled={!isEditable('CorrectionsValueCalculated')}
							value={formData.CorrectionsValueCalculated || ''}
							name="CorrectionsValueCalculated"
							label={t('MaterialInvoice.Fields.CorrectionsValueCalculated')}
							error={fieldErrorsList.includes('CorrectionsValueCalculated')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValueAdvanceDeducted  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 12, lg: 12 }}>
					{isVisible('ValueAdvanceDeducted') && (
						<TextField
							disabled={!isEditable('ValueAdvanceDeducted')}
							value={formData.ValueAdvanceDeducted || ''}
							name="ValueAdvanceDeducted"
							label={t('MaterialInvoice.Fields.ValueAdvanceDeducted')}
							error={fieldErrorsList.includes('ValueAdvanceDeducted')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* Description */}
				<Grid xs={12} sm={12} md={12} lg={12} order={{ md: 13, lg: 13 }}>
					{isVisible('Description') && (
						<TextField
							disabled={!isEditable('Description')}
							value={formData.Description || ''}
							name="Description"
							label={t('MaterialInvoice.Fields.Description')}
							error={fieldErrorsList.includes('Description')}
							onChange={onInputChange}
							multiline
							minRows={4}
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Values
