import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'

import { enqueueSnackbar } from 'notistack'
import { useAttachmentsService } from 'services'

import styles from '../Styles.module.css'

interface Props {
	contextId: string
	directoryId?: string
	handleCloseDialog: () => void
	updateAttachments: React.DispatchWithoutAction
}

const AddFile = ({ contextId, directoryId, handleCloseDialog, updateAttachments }: Props) => {
	const [selectedFiles, setSelectedFiles] = useState<File[]>([])
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['attachments', 'translation'])
	const { showSuccessInfo, addFile } = useAttachmentsService()

	const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(e.target.files as FileList)
		const fileMaxSizeInBytes = 100 * 1024 * 1024 // 100 MB

		const validFiles = files.filter(file => file.size <= fileMaxSizeInBytes)
		if (validFiles.length !== files.length) {
			enqueueSnackbar(t('general.FileSizeError', { ns: 'translation' }), {
				variant: 'error',
				autoHideDuration: 8000,
			})
		}
		setSelectedFiles(validFiles)
	}

	const handleFileSubmit = useCallback(async () => {
		try {
			setButtonLoading(true)
			for (const file of selectedFiles) {
				const formData = new FormData()
				formData.append('File', file)
				if (directoryId) {
					formData.append('directoryId', directoryId)
				}
				const response = await addFile(contextId, formData)
				if (response) {
					updateAttachments()
					handleCloseDialog()
				}
			}
			showSuccessInfo('uploaded')
			setSelectedFiles([])
		} catch (err) {
			setSelectedFiles([])
			console.error(err)
		}
		setButtonLoading(false)
	}, [contextId, selectedFiles, directoryId])

	return (
		<div className={styles.uploadContainer}>
			<Button variant="contained" color="primary" component="label" endIcon={<CloudUploadIcon />} sx={{ mb: '8px' }}>
				{t('AddAttachmentDialog.ChooseFile')}
				<input type="file" name="file" hidden multiple onChange={handleFileSelect} />
			</Button>
			{selectedFiles.map((file, index) => <span key={index}>{file.name}</span>)}
			<div className={styles.addButtonContainer}>
				<LoadingButton
					variant="contained"
					onClick={handleFileSubmit}
					color="success"
					disabled={selectedFiles.length === 0}
					loading={buttonLoading}>
					{t('general.Add', { ns: 'translation' })}
				</LoadingButton>
			</div>
		</div>
	)
}

export default AddFile
