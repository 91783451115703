import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, MenuItem, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { ContactPersons, ContractorForm, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { goodsReceivedNoteOrderType } from 'data/lookup-data-sources'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IGoodsReceivedNote } from 'models'
import { GRN_ENDPOINT } from 'services'
import { FormatNumberInput } from 'utils/FormatNumberInput'
import { formatDateTimeMUI } from 'utils/formatDateTime'

interface Props {
	id: string
	formData: IGoodsReceivedNote
}

const GoodsReceivedNoteForm = ({ id, formData }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('goods-received-note')

	const { handleInputChange, fieldsError } = useFormDataChange()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('goodsReceivedNoteFormSelectedTab', newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons documentId={id} contractorId={formData.Contractor || ''} endpoint={GRN_ENDPOINT} />
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
		],
		[t, formData]
	)

	useEffect(() => {
		const savedTab = localStorage.getItem('goodsReceivedNoteFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('Fields.Number')}
								error={fieldsError.includes('Number')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={!isEditable('DocumentDate')}
								value={formatDateTimeMUI(formData.DocumentDate) || ''}
								name="DocumentDate"
								label={t('Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DocumentDate')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* DeliveryDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DeliveryDate') && (
							<TextField
								disabled={!isEditable('DeliveryDate')}
								value={formatDateTimeMUI(formData.DeliveryDate) || ''}
								name="DeliveryDate"
								label={t('Fields.DeliveryDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DeliveryDate')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* IncomingLetter */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('IncomingLetter') && (
							<TextField
								disabled={true}
								value={formData.IncomingLetterLabel || ''}
								name="IncomingLetter"
								label={t('Fields.IncomingLetter')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.incomingLetter}
												documentId={formData.IncomingLetter}
												documentLabel={formData.IncomingLetterLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Order */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Order') && (
							<TextField
								disabled={true}
								value={formData.OrderLabel || ''}
								name="Order"
								label={t('Fields.Order')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.order}
												documentId={formData.Order}
												documentLabel={formData.OrderLabel}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* OrderType */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('OrderType') && (
							<TextField
								select
								disabled={!isEditable('OrderType')}
								value={formData.OrderType || ''}
								name="OrderType"
								label={t('Fields.OrderType')}
								error={fieldsError.includes('OrderType')}
								onChange={handleInputChange}
								fullWidth>
								{goodsReceivedNoteOrderType.map((el: any, index: number) => (
									<MenuItem key={index} value={el.value}>
										{t(`Fields.OrderTypeEnums.${el.label}`)}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
					{/* ForeignNumber  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ForeignNumber') && (
							<TextField
								disabled={!isEditable('ForeignNumber')}
								value={formData.ForeignNumber || ''}
								name="ForeignNumber"
								label={t('Fields.ForeignNumber')}
								error={fieldsError.includes('ForeignNumber')}
								onChange={handleInputChange}
								fullWidth
							/>
						)}
					</Grid>
					{/* Value */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Value') && (
							<TextField
								disabled={true}
								value={formData.Value || ''}
								name="Value"
								label={t('Fields.Value')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
								}}
								fullWidth
							/>
						)}
					</Grid>
				</Grid>
			</div>
			<ContractorForm
				documentId={id}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
			/>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default GoodsReceivedNoteForm
