import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import ErrorIcon from '@mui/icons-material/Error'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import {
	GridInitialState,
	GridRowModes,
	GridRowModesModel,
	GridRowsProp,
	GridToolbarContainer,
} from '@mui/x-data-grid-premium'

import { BalancingDocumentIcon } from 'assets'
import DemandIcon from 'assets/icons/DemandIcon'
import { CreateBalancingDocumentDialog } from 'components/pages'
import useDataGridState from 'hooks/UseDataGridState'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'

import { TooltipButton } from '../buttons'
import { ColumnsButton } from '../data-grid'
import CreateDemandDialog from './create-demand-dialog/CreateDemandDialog'
import ImportErrorsDialog from './import-errors-dialog/ImportErrorsDialog'

interface Props {
	setBudgetData: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
	setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
	projectId: string
	disabledAddButton: boolean
	setDisabledAddButton: (value: boolean) => void
	setIsImportDialogVisible: Dispatch<SetStateAction<boolean>>
	api: any
	selectionModel: Array<string>
	budgetData: any
	importErrosList: any
	contextStateName: string
	initialState: GridInitialState
	apiRef: { current: any }
}

const EditToolbar = ({
	setBudgetData,
	setRowModesModel,
	projectId,
	disabledAddButton,
	setDisabledAddButton,
	setIsImportDialogVisible,
	api,
	selectionModel,
	budgetData,
	importErrosList,
	contextStateName,
	initialState,
	apiRef,
}: Props) => {
	const [isCreateDemandDialogOpen, setIsCreateDemandDialogOpen] = useState(false)
	const [isCreateBalancingDocumentDialogOpen, setIsCreateBalancingDocumentDialogOpen] = useState(false)
	const [isImportErrorsDialogOpen, setIsImportErrorsDialogOpen] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation(['project', 'translation'])

	const { handleSaveStateSnapshot } = useDataGridState()

	const handleClick = () => {
		const Id = uuidv4()
		setDisabledAddButton(true)

		setBudgetData((oldRows: any) => [
			...oldRows,
			{
				Id,
				isNew: true,
				Description: '',
				TreePath: [''],
				ProjectId: projectId,
			},
		])
		setRowModesModel(oldModel => ({
			...oldModel,
			[Id]: { mode: GridRowModes.Edit, fieldToFocus: 'Description' },
		}))
	}

	function handleCreateDocumentClick(documentType: 'demand' | 'balancing-document') {
		let isAnySelectedRowParent
		selectionModel.forEach(el => {
			const findElement = budgetData.find((item: any) => {
				return item.Id === el
			})
			if (findElement.HasChildren) {
				isAnySelectedRowParent = true
				return
			}
		})

		if (isAnySelectedRowParent) {
			enqueueSnackbar(t('BudgetTabGeneral.InfoMessages.SelectRowWithoutChildren'), {
				variant: 'info',
				autoHideDuration: 8000,
			})
		} else {
			documentType === 'demand' ? setIsCreateDemandDialogOpen(true) : setIsCreateBalancingDocumentDialogOpen(true)
		}
	}

	const exportExcelBudget = useCallback(async () => {
		try {
			setButtonLoading(true)
			await api.downloadFile(projectId)
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}, [projectId, api])

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, initialState, contextStateName)
	}

	return (
		<GridToolbarContainer className="flex justify-between m-1">
			<div className="flex">
				<ColumnsButton />
				<Button
					variant="text"
					color="primary"
					disabled={disabledAddButton}
					onClick={() => setIsImportDialogVisible(true)}
					endIcon={<FileUploadIcon />}>
					{t('BudgetTabGeneral.Toolbar.ImportExcel')}
				</Button>
				<LoadingButton
					variant="text"
					color="primary"
					disabled={disabledAddButton}
					onClick={exportExcelBudget}
					loading={buttonLoading}
					loadingPosition="end"
					endIcon={<FileDownloadIcon />}>
					{buttonLoading ? t('BudgetTabGeneral.Toolbar.FilePreparation') : t('BudgetTabGeneral.Toolbar.ExportExcel')}
				</LoadingButton>
				{importErrosList && (
					<Button
						variant="contained"
						color="error"
						size="small"
						disabled={disabledAddButton}
						onClick={() => setIsImportErrorsDialogOpen(true)}
						endIcon={<ErrorIcon />}
						sx={{ ml: '10px' }}>
						{t('general.ImportErrors', { ns: 'translation' })}
					</Button>
				)}
			</div>
			<div className="flex">
				{selectionModel.length !== 0 && (
					<>
						<TooltipButton
							className="mr-0.5"
							title="CreateBalancingDocumentDialog.Title"
							onClick={() => handleCreateDocumentClick('balancing-document')}
							IconComponent={BalancingDocumentIcon}
							translationFile="balancing-document"
						/>
						<TooltipButton
							className="p-1.5"
							title="BudgetTabGeneral.Toolbar.CreateDemand"
							onClick={() => handleCreateDocumentClick('demand')}
							IconComponent={DemandIcon}
							translationFile="project"
						/>
						<CreateDemandDialog
							isOpen={isCreateDemandDialogOpen}
							setIsOpen={setIsCreateDemandDialogOpen}
							projectId={projectId}
							selectedRowsIds={selectionModel}
						/>
						<CreateBalancingDocumentDialog
							isOpen={isCreateBalancingDocumentDialogOpen}
							setIsOpen={setIsCreateBalancingDocumentDialogOpen}
							projectId={projectId}
							budgetEntries={selectionModel}
						/>
					</>
				)}
				<TooltipButton
					title="general.ResetDataGridState"
					onClick={() => handleUpdateState(true)}
					IconComponent={RotateLeftIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.SaveDataGridState"
					onClick={() => handleUpdateState()}
					IconComponent={FactCheckOutlinedIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.Add"
					onClick={handleClick}
					IconComponent={AddIcon}
					disabled={disabledAddButton}
					fontSize="medium"
				/>
			</div>
			{isImportErrorsDialogOpen && (
				<ImportErrorsDialog
					isOpen={isImportErrorsDialogOpen}
					setIsOpen={setIsImportErrorsDialogOpen}
					errorsList={importErrosList}
				/>
			)}
		</GridToolbarContainer>
	)
}

export default EditToolbar
