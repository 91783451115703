import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSnackbar } from 'notistack'

export const useHttp = () => {
	const [error, setError] = useState(false)
	const [errorStatus, setErrorStatus] = useState<number>()
	const [fieldsError, setFieldsError] = useState<string[]>([])

	const { enqueueSnackbar } = useSnackbar()

	const { t } = useTranslation()

	const getErrors = useCallback(async (responseObject: any) => {
		const showErrors = (errors: any) => {
			const errorsFieldsList = Object.keys(errors)

			setError(true)
			setFieldsError(errorsFieldsList)

			errorsFieldsList.forEach(key => {
				errors[key].map((err: any) =>
					enqueueSnackbar(err, {
						variant: 'error',
						persist: true,
					})
				)
			})
		}

		await responseObject.json().then((res: any) => {
			res.ErrorMessages
				? showErrors(res.ErrorMessages)
				: enqueueSnackbar(t('http-hook-errors.default'), {
						variant: 'error',
						persist: true,
					})
		})
	}, [])

	const errorHandler = useCallback((error: any) => {
		switch (error.status) {
			case 400:
				getErrors(error)
				break
			case 401:
				enqueueSnackbar(t('http-hook-errors.401'), {
					variant: 'error',
					persist: true,
				})
				break
			case 403:
				enqueueSnackbar(t('http-hook-errors.403'), {
					variant: 'error',
					persist: true,
				})
				break
			case 404:
				enqueueSnackbar(t('http-hook-errors.404'), {
					variant: 'error',
					persist: true,
				})
				break
			case 408:
				enqueueSnackbar(t('http-hook-errors.408'), {
					variant: 'error',
					persist: true,
				})
				break
			case 409:
				enqueueSnackbar(t('http-hook-errors.409'), {
					variant: 'error',
					persist: true,
				})
				break
			case 497:
				getErrors(error)
				break

			default:
				enqueueSnackbar(t('http-hook-errors.default'), {
					variant: 'error',
					persist: true,
				})
		}
	}, [])

	const request = useCallback(
		async (
			url: string,
			method: string = 'GET',
			body: any = null,
			headers = { 'Content-Type': 'application/json' },
			hideErrorMessage: boolean = false
		) => {
			Date.prototype.toJSON = function () {
				const getYear = this.toLocaleString('default', { year: 'numeric' })
				const getMonth = this.toLocaleString('default', { month: '2-digit' })
				const getDay = this.toLocaleString('default', { day: '2-digit' })

				const dateResult = getYear + '-' + getMonth + '-' + getDay

				return dateResult
			}

			const response = await fetch(url, {
				method,
				body: body && JSON.stringify(body),
				headers,
			})

			if (!response.ok) {
				if (!hideErrorMessage && (method !== 'GET' || response.status !== 403)) {
					errorHandler(response)
				} else {
					setErrorStatus(response.status)
				}

				throw new Error(
					`'${method}' call on endpoint '${url}' returned status '${response.status}' - ${response.statusText}`,
					{ cause: response }
				)
			}

			if (response.status === 204) {
				return
			}

			const data = await response.json()

			return data
		},
		[]
	)

	const uploadFileRequest = useCallback(async (url: string, method: string = 'POST', body: any = null) => {
		const response = await fetch(url, {
			method,
			body: body || null,
		})

		if (!response.ok) {
			errorHandler(response)
			throw new Error(
				`'${method}' call on endpoint '${url}' returned status '${response.status}' - ${response.statusText}`
			)
		}

		if (response.status === 204) {
			return response
		}

		const data = await response.json()

		return data
	}, [])

	const downloadFileRequest = useCallback(
		async (
			url: string,
			method: string = 'GET',
			headers: any = {
				'Content-Type': 'application/octet-stream',
			},
			name?: string
		) => {
			let fileName = name || ''

			const response = await fetch(url, {
				method,
				headers,
			})
				.then(response => {
					if (!name) {
						fileName += response.headers.get('content-disposition')?.split('"')[1]
					}
					return response.blob()
				})
				.then(blob => {
					const url = window.URL.createObjectURL(new Blob([blob]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', fileName)
					document.body.appendChild(link)
					link.click()
					link.parentNode?.removeChild(link)
				})
			return response
		},
		[]
	)

	const getFileURL = useCallback(
		async (
			url: string,
			method: string = 'GET',
			headers: any = {
				'Content-Type': 'application/octet-stream',
			},
			name?: string
		) => {
			let fileName = name || ''

			const response = await fetch(url, {
				method,
				headers,
			})
				.then(response => {
					if (!name) {
						fileName += response.headers.get('content-disposition')?.split('"')[1]
					}
					return response.blob()
				})
				.then(blob => {
					const url = window.URL.createObjectURL(new Blob([blob]))
					return {
						blob: blob,
						fileName,
						url,
					}
				})
			return response
		},
		[]
	)

	const clearError = useCallback((fieldName?: string) => {
		if (fieldName) {
			setFieldsError(prev => prev.filter(el => el !== fieldName))
		} else {
			setError(false)
		}
	}, [])

	const showSuccessInfo = useCallback((type: string, customMessage?: string | null) => {
		const defaultMessage = customMessage || t('http-hook-success-alerts.default')

		switch (type) {
			case 'saved':
				enqueueSnackbar(t('http-hook-success-alerts.saved'), {
					variant: 'success',
					autoHideDuration: 8000,
				})
				break
			case 'action':
				enqueueSnackbar(t('http-hook-success-alerts.action'), {
					variant: 'success',
					autoHideDuration: 8000,
				})
				break
			case 'deleted':
				enqueueSnackbar(t('http-hook-success-alerts.deleted'), {
					variant: 'success',
					autoHideDuration: 8000,
				})
				break
			case 'uploaded':
				enqueueSnackbar(t('http-hook-success-alerts.uploaded'), {
					variant: 'success',
					autoHideDuration: 8000,
				})
				break
			case 'folderAdded':
				enqueueSnackbar(t('http-hook-success-alerts.folderAdded'), {
					variant: 'success',
					autoHideDuration: 8000,
				})
				break
			case 'userAdded':
				enqueueSnackbar(t('http-hook-success-alerts.userAdded'), {
					variant: 'success',
					autoHideDuration: 8000,
				})
				break
			case 'userDeleted':
				enqueueSnackbar(t('http-hook-success-alerts.userDeleted'), {
					variant: 'success',
					autoHideDuration: 8000,
				})
				break
			case 'assignedDocumentOwner':
				enqueueSnackbar(t('http-hook-success-alerts.assignedDocumentOwner'), {
					variant: 'success',
					autoHideDuration: 8000,
				})
				break
			case 'removedDocumentOwner':
				enqueueSnackbar(t('http-hook-success-alerts.removedDocumentOwner'), {
					variant: 'success',
					autoHideDuration: 8000,
				})
				break

			default:
				enqueueSnackbar(defaultMessage, {
					variant: 'success',
					autoHideDuration: 8000,
				})
				break
		}
	}, [])

	return {
		request,
		uploadFileRequest,
		downloadFileRequest,
		showSuccessInfo,
		error,
		errorStatus,
		fieldsError,
		clearError,
		getFileURL,
	}
}
